import React from 'react';
//import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
//import swal from 'sweetalert';
import Dropdown from 'react-bootstrap/Dropdown';



export default function Navbar() {
  const navigate = useNavigate();
  var userid = localStorage.getItem("userid");
  
  return (

    <>
      <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
        {/* Sidebar Toggle (Topbar) */}
        <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
          <i className="fa fa-bars" />
        </button>
        {/* Topbar Search */}
        <form className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
          <div className="input-group">
            <input type="text" className="form-control bg-light border-0 small" placeholder="Search for..." aria-label="Search" aria-describedby="basic-addon2" />
            <div className="input-group-append">
              <button className="btn btn-primary" type="button">
                <i className="fas fa-search fa-sm" />
              </button>
            </div>
          </div>
        </form>
        {/* Topbar Navbar */}
        <ul className="navbar-nav ml-auto">
          {/* Nav Item - Search Dropdown (Visible Only XS) */}
          <li className="nav-item dropdown no-arrow d-sm-none">
            <a className="nav-link dropdown-toggle" href="/" id="searchDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i className="fas fa-search fa-fw" />
            </a>
            {/* Dropdown - Messages */}

          </li>
          {/* Nav Item - Alerts */}
          <li className="nav-item dropdown no-arrow mx-1">
            <a className="nav-link dropdown-toggle" href="/" id="alertsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i className="fas fa-bell fa-fw" />
              {/* Counter - Alerts */}
              <span className="badge badge-danger badge-counter">3+</span>
            </a>
          </li>



          {/* Nav Item - User Information */}
          <li className="nav-item dropdown no-arrow bg-transparent">
            <a className="nav-link bg-transparent" >
              <span >
                <Dropdown>
                  <Dropdown.Toggle className='bg-transparent bordernone'>
                    <img className="img-profile rounded-circle" src="/Imgs/admin.png" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>

                    <Dropdown.Item >{userid}</Dropdown.Item>
                    <Dropdown.Item href="/">Logout</Dropdown.Item>

                  </Dropdown.Menu>
                </Dropdown></span>

            </a>

          </li>
        </ul>
      </nav>
    </>





  )
}
