import React from 'react';
import {Link} from "react-router-dom";

export default function Sidepanel() {
  return (
    <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
      {/* Sidebar - Brand */}
      <img className="sidebar-card-illustration mb-2" src="/Imgs/admin.png" alt="..." />
      <span className="sidebar-brand d-flex align-items-center justify-content-center" >
        <div className="sidebar-brand-icon rotate-n-15">
          <i className="fas fa-laugh-wink" />
        </div>
        <div className="sidebar-brand-text mx-3">SB Admin <sup>2</sup></div>
      </span>
      {/* Divider */}
      <hr className="sidebar-divider my-0" />
      {/* Nav Item - Dashboard */}
      <li className="nav-item active">
        <span className="nav-link" >
          <i className="fas fa-fw fa-tachometer-alt" />
          <span>Dashboard</span></span>
      </li>
      {/* Divider */}
      <hr className="sidebar-divider" />
      {/* Heading */}
      <div className="sidebar-heading">
        Interface
      </div>
      {/* Nav Item - Pages Collapse Menu */}
      <li className="nav-item">
        <span className="nav-link collapsed"  data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
          <i className="fas fa-fw fa-cog" />
          <span><Link className="text-white"  to="/blood-bank">Home</Link></span>
        </span>
        <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
          <div className="bg-white py-2 collapse-inner rounded">
          <h6><Link className="collapse-header" to="/">Custom Components:</Link></h6>
            <Link className=" collapse-item text-white"  to="/">Donar</Link>
            <Link className="collapse-item" to="/">Cards</Link>
          </div>
        </div>
      </li>
      {/* Nav Item - Utilities Collapse Menu */}
      <li className="nav-item">
        <span className="nav-link collapsed"  data-toggle="collapse" data-target="#collapseUtilities" aria-expanded="true" aria-controls="collapseUtilities">
          <i className="fas fa-fw fa-wrench" />
          <span><Link className="text-white"  to="/blood-bank/donerlist">Donor</Link></span>
        </span>
      </li>
      <li className="nav-item">
        <span className="nav-link collapsed" data-toggle="collapse" data-target="#collapseUtilities" aria-expanded="true" aria-controls="collapseUtilities">
          <i className="fas fa-user" />
          <span><Link className="text-white"  to="/blood-bank/receiver">Receiver</Link></span>
        </span>
      </li>
     
      <hr className="sidebar-divider" />
     
      
      <li className="nav-item">
        <a className="nav-link collapsed"  data-toggle="collapse" data-target="#collapsePages" aria-expanded="true" aria-controls="collapsePages">
          <i className="fas fa-fw fa-folder" />
          <span><Link className="text-white"  to="/blood-bank/stockrecord">Stock Record</Link></span>
        </a>
        <div id="collapsePages" className="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
          <div className="bg-white py-2 collapse-inner rounded">
            <h6 className="collapse-header">Login Screens:</h6>
            <span className="collapse-item" >Login</span>
            
          </div>
        </div>
      </li>
      {/* Nav Item - Charts */}
      <li className="nav-item">
        <span className="nav-link">
          <i className="fas fa-fw fa-chart-area" />
          <span>Charts</span></span>
      </li>
      {/* Nav Item - Tables */}
      <li className="nav-item">
        <span className="nav-link">
          <i className="fas fa-fw fa-table" />
          <span>Tables</span></span>
      </li>
      {/* Divider */}
      <hr className="sidebar-divider d-none d-md-block" />
      {/* Sidebar Toggler (Sidebar) */}
      <div className="text-center d-none d-md-inline">
        <button className="rounded-circle border-0" id="sidebarToggle" />
      </div>
      {/* Sidebar Message */}
      <div className="sidebar-card d-none d-lg-flex">
        <img className="sidebar-card-illustration mb-2" src="med.png" alt="..." />
        <p className="text-center mb-2"><strong> Admin</strong> </p>
        <span className="btn btn-success btn-sm" >Facilities</span>
      </div>
    </ul>
  

  )
}
