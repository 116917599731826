import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { customFormDataPost } from '../../Helper/api-helper';
import { customFetch } from '../../Helper/api-helper';
import swal from 'sweetalert';
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import { TimePicker } from 'react-ios-time-picker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { apiBaseUrl } from '../../../global/credentials';
import Modal from 'react-bootstrap/Modal'

function Opdform() {

    const handle = async () => {
        // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        // var res = await customFetch(`${apiBaseUrl}opd/patients/pending`);
        // setDetail(res);

    }

    const { handleSubmit } = useForm();
    useEffect(() => {
        createUhId();
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    const [filters, setFilters] = useState({
        patientname: "",
        uhId: "",
        phone: "",
    });

    const [showmodal, setShowModal] = useState(false);
    const [oldPatients, setOldPatients] = useState([]);

    const modalClose = () => setShowModal(false);

    const selectPatient = (patient) => {
        setFormData({
            patientname: patient.patientName,
            patiententry: formData.patiententry,
            age: patient.age,
            gender: patient.gender,
            invoice: patient.uhidInvoiceNo,
            consultantname: patient.consultantName,
            Adharcard: patient.adhaarCard,
            Ayushmancard: patient.ayushmanCard === 1,
            AyushmancardId: patient.ayushmanCardId,
            bloodgroup: patient.bloodGroup,
            contact: patient.patientPhone,
            timeofbilling: new Date(),
            fees: patient.fees,
            feeType: patient.feeType,
        });
        modalClose();
    }
    const filterPatients = async (e) => {
        var url = `${apiBaseUrl}opd/patientrevisit?uhid=${filters.uhId}&name=${filters.patientname}&phone=${filters.phone}`

        var res = await customFetch(url);

        setShowModal(true);
        setOldPatients(res);
    }
    const [formData, setFormData] = useState({
        patientname: "",
        patiententry: "new",
        age: "",
        gender: "",
        timeofbilling: new Date(),
        invoice: "",
        consultantname: "",
        slottime: "",
        Adharcard: "",
        Ayushmancard: false,
        AyushmancardId: "",
        bloodgroup: "",
        contact: "",
        fees: "",
        feeType: "Paid"
    });

    const createUhId = async () => {
        var res = await customFetch(`${apiBaseUrl}opd/create-uhid`);
        if (res)
            setFormData({ ...formData, invoice: res.data });
    }

    const formatDateTime = (date) => {
        return moment(date).format('DD-MM-yyyy hh:mm a')
    }

    const formatDate = (date) => {
        return moment(date).format('DD-MM-yyyy')
    }

    const formatTime = (time) => {
        debugger;
        return moment().format('DD-MM-yyyy ') + time;
    }

    const opdSubmit = async () => {
        var data = new FormData();
        data.append('PatientName', formData.patientname);
        data.append('PatientPhone', formData.contact);
        data.append('Age', formData.age);
        data.append('Gender', formData.gender);
        data.append('DateAndTimeOfBilling', formatDateTime(formData.timeofbilling));
        data.append('UhidInvoiceNo', formData.invoice);
        data.append('ConsultantName', formData.consultantname);
        data.append('SlotTime', formatTime(formData.slottime));
        data.append('AdhaarCard', formData.Adharcard);
        data.append('AyushmanCard', formData.Ayushmancard ? 1 : 0);
        data.append('AyushmanCardId', formData.AyushmancardId);
        data.append('BloodGroup', formData.bloodgroup);
        data.append('Fees', formData.fees);
        data.append('FeeType', formData.feeType);


        var res = await customFormDataPost(`${apiBaseUrl}opd/add-patient`, data);
        if (res.status) {
            swal("Saved!", "Your Receiver form data has been Saved!", "success");
            setFormData({
                patientname: "",
                patiententry: "new",
                contact: "",
                filetype: "",
                fees: "",
                contact: "",
                age: "",
                gender: "",
                timeofbilling: new Date(),
                invoice: "",
                consultantname: "",
                slottime: "",
                Adharcard: "",
                Ayushmancard: false,
                AyushmancardId: "",
                bloodgroup: "",
            });
            setTimeout(function () { createUhId(); }, 200)


        }
        else {
            alert(res.messsage)
        }


    }
    return (
        <div><div className="container-fluid">
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/opd/">Home</Link></li>
                    <li className="breadcrumb-item active" ><Link to="/opd/">OPD form</Link></li>
                </ol>
            </nav>
        </div>
            <div className="container">
                <div className="card shadow mb-4">

                    <div className="card-body p-4">

                        <div className="row">

                            <h2 className='text-center'>OPD Registration Form</h2>
                            <div className="row">


                                <label className="form-group col-md-6" >Patient Entry</label>
                                <select className="form-control form-group col-md-6" value={formData.patiententry}
                                    onChange={(event) =>
                                        setFormData({ ...formData, patiententry: event.target.value })
                                    }>
                                    <option value="new">New</option>
                                    <option value="old">Revisit</option>

                                </select>

                            </div>
                            {formData.patiententry === "old" ?
                                <div >

                                    <div className="row ms-5 me-5">
                                        <label className="form-group col-md-6" >Patient name</label>
                                        <input type="text"
                                            className="form-control form-group col-md-6" placeholder="Patient name"
                                            value={filters.patientname}
                                            onChange={(event) =>
                                                setFilters({ ...filters, patientname: event.target.value })
                                            } />
                                    </div>
                                    <div className="row ms-5 me-5">
                                        <label className="form-group col-md-6" >Contact</label>
                                        <input type="text"
                                            className="form-control form-group col-md-6" placeholder="Contact"
                                            value={filters.phone}
                                            onChange={(event) =>
                                                setFilters({ ...filters, phone: event.target.value })
                                            } />
                                    </div>
                                    <div className="row ms-5 me-5">
                                        <label className="form-group col-md-6" >Uhid</label>
                                        <input type="text"
                                            className="form-control form-group col-md-6" placeholder="Uhid"
                                            value={filters.uhId}
                                            onChange={(event) =>
                                                setFilters({ ...filters, uhId: event.target.value })
                                            } />
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <button type="button" onClick={filterPatients} className="btn btn-primary">Search</button>
                                    </div>

                                </div> : ""}
                        </div>
                    </div>
                </div>
                <div className="card shadow mb-4">

                    <div className="card-body p-4">

                        <div className="row">

                            <form id="receiverForm p-8"

                                onSubmit={handleSubmit(opdSubmit)}>

                                <div className="row ms-5 me-5">
                                    <label className="form-group col-md-6" >Date and Time of Billing</label>
                                    <div className="col-md-6 m-0 p-0" > <DatePicker
                                        className="form-control form-group col-md-12"
                                        selected={formData.timeofbilling}
                                        onChange={(value) => { setFormData({ ...formData, timeofbilling: value }) }}
                                        timeInputLabel="Time:"
                                        dateFormat="dd-MM-yyyy h:mm aa"
                                        showTimeInput
                                    />
                                    </div>
                                </div>



                                <div className="row ms-5 me-5">


                                    <label className="form-group col-md-6" >File Type</label>
                                    <select className="form-control form-group col-md-6" value={formData.filetype}
                                        onChange={(event) =>
                                            setFormData({ ...formData, filetype: event.target.value })
                                        }>
                                        <option value="Paid">Paid</option>
                                        <option value="Charity">Charity</option>

                                    </select>

                                </div>
                                <div className="row ms-5 me-5">
                                    <label className="form-group col-md-6" >Fees</label>
                                    <input type="text"
                                        className="form-control form-group col-md-6" placeholder="-/ Rs"
                                        value={formData.fees}
                                        onChange={(event) =>
                                            setFormData({ ...formData, fees: event.target.value })
                                        } />
                                </div>

                                <div className="row ms-5 me-5">
                                    <label className="form-group col-md-6" >Patient name</label>
                                    <input type="text"
                                        className="form-control form-group col-md-6" placeholder="Patient name"
                                        value={formData.patientname}
                                        onChange={(event) =>
                                            setFormData({ ...formData, patientname: event.target.value })
                                        } />
                                </div>

                                <div >
                                    <div className="row ms-5 me-5">
                                        <label className="form-group col-md-6" >Age</label>
                                        <input asp-for="Age" type="number"
                                            className="form-control form-group col-md-6" placeholder="Age "
                                            value={formData.age}
                                            onChange={(event) =>
                                                setFormData({ ...formData, age: event.target.value })
                                            } />
                                    </div>

                                    <div className="row ms-5 me-5">
                                        <label className="form-group col-md-6" >Contact</label>
                                        <input asp-for="contact" type="number"
                                            className="form-control form-group col-md-6" placeholder="contact"
                                            value={formData.contact}
                                            onChange={(event) =>
                                                setFormData({ ...formData, contact: event.target.value })
                                            } />
                                    </div>
                                    <div className="row ms-5 me-5">


                                        <label className="form-group col-md-6" >Gender</label>
                                        <select className="form-control form-group col-md-6" value={formData.gender}
                                            onChange={(event) =>
                                                setFormData({ ...formData, gender: event.target.value })
                                            }>
                                            <option value="">Select Gender</option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                            <option value="Other">Other</option>
                                        </select>

                                    </div>
                                    <div className="row ms-5 me-5">
                                        <label className="form-group col-md-6" >Adhar card Number</label>
                                        <input type="text" className="form-control form-group col-md-6"
                                            placeholder="XXXX-XXXX-XXXX"
                                            value={formData.Adharcard}
                                            onChange={(event) =>
                                                setFormData({ ...formData, Adharcard: event.target.value })
                                            } />
                                    </div>
                                    <div className="row ms-5 me-5">
                                        <label className="form-group col-md-6 " >Ayushman card</label>
                                        <input type="checkbox" className='largerCheckbox '
                                            checked={formData.Ayushmancard}
                                            onChange={(event) => setFormData({ ...formData, Ayushmancard: event.target.checked })
                                            } />
                                    </div>
                                    <div className='row ms-5 me-5' style={{ display: formData.Ayushmancard ? "" : "none" }}>

                                        <label className="form-group col-md-6" >Ayushman card Id</label>
                                        <input type="text" className="form-control form-group col-md-6"
                                            value={formData.AyushmancardId}
                                            onChange={(event) =>
                                                setFormData({ ...formData, AyushmancardId: event.target.value })
                                            } />

                                    </div>

                                    <div className="row ms-5 me-5" >
                                        <label className="form-group col-md-6" >UHID/ Invoice No </label>
                                        <input type="text" className="form-control form-group col-md-6"
                                            placeholder="UHID/ INVOICE NO "
                                            value={formData.invoice}
                                            onChange={(event) => { }
                                                //setFormData({ ...formData, invoice: event.target.value })
                                            } />
                                    </div>
                                </div>


                                <div className="row ms-5 me-5" >
                                    <label className="form-group col-md-6" >Consultant Name </label>
                                    <input type="text"
                                        className="form-control form-group col-md-6" placeholder="Consultant"
                                        value={formData.consultantname}
                                        onChange={(event) =>
                                            setFormData({ ...formData, consultantname: event.target.value })
                                        } />
                                </div>
                                <div className="row ms-5 me-5" >
                                    <label className="form-group col-md-6" >Blood Group </label>
                                    <select className="form-control form-group col-md-6" value={formData.bloodgroup}
                                        onChange={(event) =>
                                            setFormData({ ...formData, bloodgroup: event.target.value })
                                        }>
                                        <option value="-">-</option>
                                        <option value="O +">O +</option>
                                        <option value="O -">O -</option>
                                        <option value="A +">A +</option>
                                        <option value="A -">A -</option>
                                        <option value="B +">B +</option>
                                        <option value="B -">B -</option>
                                        <option value="AB +">AB +</option>
                                        <option value="AB -">AB -</option>
                                    </select>

                                </div>
                                <div className="row ms-5 me-5">
                                    <label className='form-group col-md-6'>Slot Time </label>

                                    <div className="col-md-6 m-0 p-0" > <TimePicker
                                        className="form-control form-group col-md-12"
                                        selected={formData.slottime}
                                        onChange={(value) => {
                                            setFormData({ ...formData, slottime: value })
                                        }}
                                        timeInputLabel="Time:"
                                        dateFormat="dd-MM-yyyy h:mm aa"
                                        showTimeInput
                                    />
                                    </div>
                                </div>





                                <div className="d-flex justify-content-center">
                                    <button type="submit" id="saveReceiver" className="btn btn-primary">Save</button>
                                </div>
                            </form>
                            <Modal size="xl" show={showmodal} onHide={modalClose}>
                                <Modal.Header closeButton>
                                    Matching results
                                </Modal.Header>
                                <Modal.Body>
                                    <table className="table table-bordered">
                                        <tr>
                                            <th>S. No.</th>
                                            <th>UhId</th>
                                            <th>Patient name</th>
                                            <th>Age</th>
                                            <th>Contact</th>
                                        </tr>
                                        {oldPatients.map((item, i) => (
                                            <tr style={{ cursor: 'poiner' }} key={i} onClick={() => selectPatient(item)}>
                                                <td>{item.sNo}</td>
                                                <td>{item.uhidInvoiceNo}</td>
                                                <td>{item.patientName}</td>
                                                <td>{item.age}</td>
                                                <td>{item.patientPhone}</td>
                                            </tr>
                                        ))}
                                    </table>
                                </Modal.Body>

                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Opdform
