import React, { useState, useEffect } from 'react';
import { customFetch } from '../../Helper/api-helper';
import { apiBaseUrl } from '../../../global/credentials'

function Donerpg3({ formData, setFormData }) {
  const [questionnaires, setQuestionnaries] = useState([]);
  useEffect(() => {
    handle()
  }, []);
  const handle = async () => {

    var res = await customFetch(`${apiBaseUrl}blood-bank/doners/questionnaires`);

    setQuestionnaries(res);

  }
  return (

    <div className="form-card">
      <h2 className="fs-title">Donor Questionnaire</h2>

      <div className="row">


        {questionnaires.map((ques, index) => {
          if (ques.type === 1) {
            return (
              <>

                <hr />
                <div id={ques.id} className="col-md-12 mx-0 donor-questionnaire">
                  <span className='title'>{ques.order} {ques.question}</span>

                </div>



                {ques.donorQuestionnaireIllnesses.map((quesIllnesses, index) => (
                  <div id={'a' + quesIllnesses.id} className="col-md-4 mx-0 donor-questionnaire1">


                    <span className='title'>{quesIllnesses.illness}</span>
                    <label className='switch'>
                      <input value={quesIllnesses.id} type="checkbox" />
                      <span className='slider round'></span>
                    </label>


                  </div>
                ))}
              </>);

          }
          else {
            return (

              <div id={ques.id} className="col-md-12 mx-0 donor-questionnaire">
                <span className='title'>{ques.order} {ques.question}</span>
                <label className='switch'>
                  <input value={ques.id} type="checkbox" />
                  <span className='slider round'></span>
                </label>

              </div>


            );

          }
        })
        }


      </div>

    </div>

  );
}

export default Donerpg3;