import React from "react";

function Donerpg2({ formData, setFormData }) {
  return (
    <div className="personal-info-container">
<fieldset>
  <div className="form-card mx-2 my-2">
    <div className="form-row">
      <div className="form-group col-md-3">
        <label htmlFor="inputEmail4">Hb(gms/di)</label>
        <input asp-for="Hb" type="number" className="form-control" 
        placeholder="Hb_______gms/di" 
        value={formData.hbgm}
        onChange={(e) => {
          setFormData({ ...formData, hbgm: e.target.value });
        }}/>
      </div>
      <div className="form-group col-md-3">
        <label htmlFor="inputPassword4">Bp(mm of Hg)</label>
        <input asp-for="Bp" type="number" className="form-control" 
        placeholder="Bp_______mm of Hg"
        value={formData.bpmm}
        onChange={(e) => {
          setFormData({ ...formData, bpmm: e.target.value });
         }} />
      </div>
      <div className="form-group col-md-3">
        <label htmlFor="inputEmail4">Weight(Kg) </label>
        <input asp-for="Weight" type="number" className="form-control"
         placeholder="Weight______Kg" 
         value={formData.weightkg}
        onChange={(e) => {
          setFormData({ ...formData, weightkg: e.target.value });
        }}/>
      </div>
      <div className="form-group col-md-3">
        <label htmlFor="inputPassword4">Pulse(/mt)</label>
        <input asp-for="Pulse" type="number" className="form-control"
         placeholder="Pulse ______/mt "
         value={formData.pulse}
        onChange={(e) => {
          setFormData({ ...formData, pulse: e.target.value });
        }} />
      </div>
    </div>
    <div className="form-row">
      <div className="form-group col-md-3">
        <label htmlFor="inputEmail4">Temp</label>
        <input asp-for="Temp" type="number" className="form-control" 
        placeholder="Temp" 
        value={formData.temp}
        onChange={(e) => {
          setFormData({ ...formData, temp: e.target.value });
        }}/>
      </div>
      <div className="form-group col-md-3">
        <label htmlFor="inputPassword4">Phlebotomy site</label>
        <input asp-for="PhlebotomySite" type="text" className="form-control" 
        placeholder="Phlebotomy site"
        value={formData.phlebotomy}
        onChange={(e) => {
          setFormData({ ...formData, phlebotomy: e.target.value });
        }} />
      </div>
      <div className="form-group col-md-6">
        <label htmlFor="inputEmail4">Patient/Donor Blood Group Rhfactor if known</label>
        <input asp-for="BloodGroupRhFactorIfKnown" type="number" className="form-control"
         placeholder="Blood Group Rhfactor if known" 
         value={formData.bloodgrouprhfactor}
        onChange={(e) => {
          setFormData({ ...formData, bloodgrouprhfactor: e.target.value });
        }} />
      </div>
    </div>
    <div className="form-row">
      <div className="form-group col-md-4">
        <label htmlFor="inputPassword4">Date of Last Donation</label>
        <input asp-for="DateOfLastDonation" type="text" className="form-control datepicker"
         placeholder="Date of Last Donation"
         value={formData.dateoflastdonation}
        onChange={(e) => {
          setFormData({ ...formData, dateoflastdonation: e.target.value });
        }}  />
      </div>
      <div className="form-group col-md-4">
        <label htmlFor="inputEmail4">Previous donor reaction,if any</label>
        <input asp-for="PreviousDonorReactionIfAny" type="text" className="form-control"
         placeholder="Previous donor reaction,if any"
         value={formData.previousdonorreaction}
        onChange={(e) => {
          setFormData({ ...formData,previousdonorreaction: e.target.value });
        }}  />
      </div>
      <div className="form-group col-md-4">
        <label htmlFor="inputPassword4">Emergency panel</label>
        <input asp-for="EmergencyPanel" type="text" className="form-control"
         placeholder="Emergency panel"
         value={formData.emergencypanel}
        onChange={(e) => {
          setFormData({ ...formData,emergencypanel: e.target.value });
        }} />
      </div>
      <div className="form-group col-md-12">
        <label htmlFor="inputEmail4">Previous rejection From donation(and its reasons)</label>
        <textarea asp-for="BloodGroupRhFactorIfKnown" type="number" className="form-control"
         placeholder="Previous rejection From donation(and its reasons)"
         value={formData.previousrejection}
         onChange={(e) => {
           setFormData({ ...formData,previousrejection: e.target.value });
         }} />
      </div>
    </div>
    
      <hr />
      <h5>Systemic Exam</h5>
      <div className="form-row">
      <div className="form-group col-md-3">
        <label htmlFor="inputPassword4">Abd</label>
        <input asp-for="Abd" type="text" className="form-control"
         placeholder="Abd"
         value={formData.abd}
         onChange={(e) => {
           setFormData({ ...formData,abd: e.target.value });
         }}  />
      </div>
      <div className="form-group col-md-3">
        <label htmlFor="inputEmail4">CVS</label>
        <input asp-for="CVS" type="text" className="form-control" 
        placeholder="CVS" 
        value={formData.cvs}
         onChange={(e) => {
           setFormData({ ...formData,cvs: e.target.value });
         }} />
      </div>
      <div className="form-group col-md-3">
        <label htmlFor="inputPassword4">Chest </label>
        <input asp-for="Chest" type="text" className="form-control" 
        placeholder="Chest"
        value={formData.chest}
         onChange={(e) => {
           setFormData({ ...formData,chest: e.target.value });
         }}  />
      </div>
      <div className="form-group col-md-3">
        <label htmlFor="inputEmail4">Skin</label>
        <input asp-for="Skin" type="text" className="form-control" 
        placeholder="Skin"
        value={formData.skin}
         onChange={(e) => {
           setFormData({ ...formData,skin: e.target.value });
         }}  />
      </div>
      <div className="form-group col-md-4">
        <label htmlFor="inputPassword4">Liver </label>
        <input asp-for="Liver" type="text" className="form-control" 
        placeholder="Liver"
        value={formData.liver}
         onChange={(e) => {
           setFormData({ ...formData,liver: e.target.value });
         }}  />
      </div>
      <div className="form-group col-md-4">
        <label htmlFor="inputEmail4">Signature of the donor </label>
        <input asp-for="SignatureOfTheDonor" type="file" 
        //value={formData.signature}
        onChange={(e) => {
          if(e.target.files.length>0) {
            setFormData({ ...formData,signature: e.target.files[0] });
          } else {
            setFormData({ ...formData,signature: "" });
          }
        }} />
      </div>
      <div className="form-group col-md-4">
        <label htmlFor="inputPassword4">Signature of MO</label>
        <input asp-for="SignatureOfMo" type="file"
        //value={formData.signatureofmo}
        onChange={(e) => {
          if(e.target.files.length>0) {
            setFormData({ ...formData,signatureofmo: e.target.files[0] });
          } else {
            setFormData({ ...formData,signatureofmo: "" });
          }
        }}  />
      </div>
      <div className="form-group col-md-12">
        <label htmlFor="inputEmail4">Reasons for rejection </label>
        <textarea asp-for="ReasonsForRejection" type="text" className="form-control"
         placeholder="Reasons for rejection" 
         value={formData.reasonforrejection}
         onChange={(e) => {
           setFormData({ ...formData,reasonforrejection: e.target.value });
         }}  />
      </div>
    </div>
  </div>
</fieldset>

    </div>
  );
}

export default Donerpg2;