import React, { useState, useEffect } from 'react';
import { customFetch } from '../../Helper/api-helper';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import { apiBaseUrl } from '../../../global/credentials';

function Surveydata() {
    const [detail, setDetail] = useState([]);
    useEffect(() => {
        handle();
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);
    const handle = async () => {

        var res = await customFetch(`${apiBaseUrl}admin/surveys`);

        setDetail(res.data);
        console.log(res)
        setTimeout(function () {
            $('#donertable').DataTable(
            );
        }, 100);

    }

    return (
        <div>
            <div className="container-fluid">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/director/">Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page"><Link to="/director/surveydata">Survey Data</Link></li>
                    </ol>
                </nav>
            </div>
            <div className="container-fluid">
               
                {/* DataTales Example */}
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 style={{ float: "left" }} className="m-0 font-weight-bold text-primary">Survey Data</h6>
                    </div>

                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-bordered" id="donertable" width="100%" cellSpacing={0}>
                                <thead>
                                    <tr>
                                        <td>S. No</td>
                                        <td>Head of Family</td>
                                        <td>Occupation</td>
                                        <td>Size of Family</td>
                                        <td>Type of Family</td>


                                        <td>Action</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {detail.map((survey, index) => (
                                        <tr key={survey.id}>
                                            <td>{index + 1}</td>
                                            <td>{survey.headOfFamily}</td>
                                            <td>{survey.occupation}</td>
                                            <td>{survey.sizeOfFamily}</td>
                                            <td>{survey.area}</td>


                                            <td>

                                                <Link className="btn btn-success btn-sm mx-1" to={"/director/survey/details/" + survey.id}><i className="fa fa-check-circle" aria-hidden="true"></i> </Link>

                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Surveydata
