import React, { useState } from 'react';
import { Link } from "react-router-dom";
import useStateWithCallback from 'use-state-with-callback';
import { useForm } from "react-hook-form";
import { customFormDataPost } from '../../Helper/api-helper';
import swal from 'sweetalert';
import $ from 'jquery';
import { apiBaseUrl } from '../../../global/credentials'


function Surveyform() {
  const { handleSubmit } = useForm();

  var familyMember = {
    memberName: "",
    relationToHead: "",
    occupation: "",
    maritalStatus: ""
  }

  const [familyMembers, setFamilyMembers] = useState([]);
  const [immunisationScheduleCount, setImmunisationScheduleCount] = useState([]);

  const [familyMemberdiebetes, setFamilyMemberdiebete] = useState([{}]);
  const [familyMemberbP, setFamilyMemberBP] = useState([{}]);
  const [familyMembermigrane, setFamilyMemberMigrane] = useState([{}]);
  const [familyMembercataract, setFamilyMemberCataract] = useState([{}]);
  const [familyMembercardio, setFamilyMemberCardio] = useState([{}]);
  const [familyMemberasthma, setFamilyMemberAsthma] = useState([{}]);
  const [familyMembertb, setFamilyMemberTb] = useState([{}]);
  const [familyMemberkidney, setFamilyMemberKidney] = useState([{}]);
  const [familyMemberepilepsy, setFamilyMemberEpilepsy] = useState([{}]);
  const [familyMembergastric, setFamilyMemberGastric] = useState([{}]);
  const [familyMembersurgery, setFamilyMemberSurgery] = useState([{}]);
  const [familyMemberanemia, setFamilyMemberAnemia] = useState([{}]);
  const [familyMemberother, setFamilyMemberOther] = useState([{}]);


  const [formData, setFormData] = useStateWithCallback({
    diebetes: false,
    Bp: false,
    Migration: false,
    Cataract: false,
    Cardiovasculardisease: false,
    Asthma: false,
    Tb: false,
    Kidneydisease: false,
    Epilepsy: false,
    Gastricdisease: false,
    prevsurgery: false,
    Anemia: false,
    others: false,


    headname: "",
    occupation: "",
    age: "",
    typeof_family: "",
    familySize: 0,
    area: "",
    address: "",

    neonate_qty: 0,
    neonate_vaccine: false,
    infant_qty: 0,
    infant_vaccine: false,
    upto5year_qty: 0,
    upto5year_vaccine: false,
    five_yearabove_qty: 0,
    five_yearabove_vaccine: false,




  }, function (params) {
    if (formData.familySize && familyMembers.length !== formData.familySize) {
      var members = [];
      for (let index = 0; index < formData.familySize; index++) {
        members.push(familyMember);
      }
      setFamilyMembers(members);
    }

    var newImmunisationScheduleCount = parseInt(formData.neonate_qty) + parseInt(formData.infant_qty) + parseInt(formData.upto5year_qty) + parseInt(formData.five_yearabove_qty);
    if (!isNaN(newImmunisationScheduleCount) && immunisationScheduleCount.length !== newImmunisationScheduleCount) {

      setImmunisationScheduleCount(Array.from(Array(newImmunisationScheduleCount).keys()))
    }
  });

  const addDiabetesPatientClick = (e) => {
    setFamilyMemberdiebete([...familyMemberdiebetes, [{}]])
  }
  const addBpPatientClick = (e) => {
    setFamilyMemberBP([...familyMemberbP, [{}]])
  }
  const addMigranePatientClick = (e) => {
    setFamilyMemberMigrane([...familyMembermigrane, [{}]])
  }
  const addCataractPatientClick = (e) => {
    setFamilyMemberCataract([...familyMembercataract, [{}]])
  }
  const addCardioPatientClick = (e) => {
    setFamilyMemberCardio([...familyMembercardio, [{}]])
  }
  const addAsthmaPatientClick = (e) => {
    setFamilyMemberAsthma([...familyMemberasthma, [{}]])
  }
  const addTbPatientClick = (e) => {
    setFamilyMemberTb([...familyMembertb, [{}]])
  }
  const addKidneyPatientClick = (e) => {
    setFamilyMemberKidney([...familyMemberkidney, [{}]])
  }
  const addEpilepsyPatientClick = (e) => {
    setFamilyMemberEpilepsy([...familyMemberepilepsy, [{}]])
  }
  const addGastricPatientClick = (e) => {
    setFamilyMemberGastric([...familyMembergastric, [{}]])
  }
  const addSurgeryPatientClick = (e) => {
    setFamilyMemberSurgery([...familyMembersurgery, [{}]])
  }
  const addAnemiaPatientClick = (e) => {
    setFamilyMemberAnemia([...familyMemberanemia, [{}]])
  }
  const addOtherPatientClick = (e) => {
    setFamilyMemberOther([...familyMemberother, [{}]])
  }

  const SurveySubmit = async () => {

    var data = new FormData();
    data.append('HeadOfFamily', formData.headname);
    data.append('Occupation', formData.occupation);
    data.append('TypeOfFamily', formData.typeof_family);
    data.append('SizeOfFamily', formData.familySize);
    data.append('Area', formData.area);
    data.append('Address', formData.address);
    data.append('NumOfNeonate', formData.neonate_qty);
    data.append('NeonateVaccine', formData.neonate_vaccine ? 1 : 0);
    data.append('NumOfInfant', formData.infant_qty);
    data.append('InfantVaccine', formData.infant_vaccine ? 1 : 0);
    data.append('NumUptoFiveYearChildren', formData.upto5year_qty);
    data.append('Upto5YearVaccine', formData.upto5year_vaccine ? 1 : 0);
    data.append('NumOfAbove5YearChildren', formData.five_yearabove_qty);
    data.append('Above5YearVaccine', formData.five_yearabove_vaccine ? 1 : 0);
    $('#PatientFamilyMembers tbody tr').each(function (i) {
      var fMember = $(this);
      data.append(`SurveyMembers[${i}].Member`, fMember.find("td input#MemberName").val());
      data.append(`SurveyMembers[${i}].RelationToHead`, fMember.find("td input#MemberRelationToHead").val());
      data.append(`SurveyMembers[${i}].Occupation`, fMember.find("td input#MemberOccupation").val());
      data.append(`SurveyMembers[${i}].MaritalStatus`, fMember.find("td input#MemberMaritalStatus").val());
    })

    $('.immunisationSchedules .immunisationSchedule').each(function (i) {
      var fMember = $(this);
      data.append(`SurveyImmunisationSchedules[${i}].Name`, fMember.find("#immunisationScheduleMemberName").val());
      data.append(`SurveyImmunisationSchedules[${i}].Age`, fMember.find("#immunisationScheduleMemberAge").val());
      data.append(`SurveyImmunisationSchedules[${i}].BcgSabin`, fMember.find("#AtBirthBCGSabin").is(":checked") ? 1 : 0);
      data.append(`SurveyImmunisationSchedules[${i}].Hbv`, fMember.find("#AtBirthHBV").is(":checked") ? 1 : 0);
      data.append(`SurveyImmunisationSchedules[${i}].Opv0`, fMember.find("#AtBirthOPV0").is(":checked") ? 1 : 0);
      data.append(`SurveyImmunisationSchedules[${i}].Penta1Atbirth`, fMember.find("#AtBirthPenta1").is(":checked") ? 1 : 0);
      data.append(`SurveyImmunisationSchedules[${i}].AtbirthRemark`, fMember.find("#AtBirthRemark").val());
      data.append(`SurveyImmunisationSchedules[${i}].Pcv1`, fMember.find("#SixWeekPCV1").is(":checked") ? 1 : 0);
      data.append(`SurveyImmunisationSchedules[${i}].Opv1`, fMember.find("#SixWeekOPV1").is(":checked") ? 1 : 0);
      data.append(`SurveyImmunisationSchedules[${i}].Penta1`, fMember.find("#SixWeekPenta1").is(":checked") ? 1 : 0);
      data.append(`SurveyImmunisationSchedules[${i}]._6weekRemark`, fMember.find("#SixWeekRemark").val());
      data.append(`SurveyImmunisationSchedules[${i}].Penta3`, fMember.find("#TenWeekPenta3").is(":checked") ? 1 : 0);
      data.append(`SurveyImmunisationSchedules[${i}].Pcv2`, fMember.find("#TenWeekPCV2").is(":checked") ? 1 : 0);
      data.append(`SurveyImmunisationSchedules[${i}].Opv2`, fMember.find("#TenWeekOPV2").is(":checked") ? 1 : 0);
      data.append(`SurveyImmunisationSchedules[${i}]._10weekRemark`, fMember.find("#TenWeekRemark").val());
      data.append(`SurveyImmunisationSchedules[${i}].Dpt3`, fMember.find("#FourteenWeekDTP3").is(":checked") ? 1 : 0);
      data.append(`SurveyImmunisationSchedules[${i}].Opv3`, fMember.find("#FourteenWeekOPV3").is(":checked") ? 1 : 0);
      data.append(`SurveyImmunisationSchedules[${i}]._14weekRemark`, fMember.find("#FourteenWeekRemark").val());
      data.append(`SurveyImmunisationSchedules[${i}].VitaminA`, fMember.find("#SixMonthVitaminA").is(":checked") ? 1 : 0);
      data.append(`SurveyImmunisationSchedules[${i}]._6monthRemark`, fMember.find("#SixMonthRemark").val());
      data.append(`SurveyImmunisationSchedules[${i}].Measles1`, fMember.find("#NineMonthMeasles1").is(":checked") ? 1 : 0);
      data.append(`SurveyImmunisationSchedules[${i}]._9monthRemark`, fMember.find("#NineMonthRemark").val());
      data.append(`SurveyImmunisationSchedules[${i}].YellowFever`, fMember.find("#NineMonthYellowFever").is(":checked") ? 1 : 0);
      data.append(`SurveyImmunisationSchedules[${i}]._9month2ndRemark`, fMember.find("#NineMonthSecondRemark").val());
      data.append(`SurveyImmunisationSchedules[${i}].VitaminA12month`, fMember.find("#TwelveMonthVitaminA").is(":checked") ? 1 : 0);
      data.append(`SurveyImmunisationSchedules[${i}]._12monthRemark`, fMember.find("#TwelveMonthRemark").val());
      data.append(`SurveyImmunisationSchedules[${i}].Pcv118Month`, fMember.find("#EighteenMonthPCV1").is(":checked") ? 1 : 0);
      data.append(`SurveyImmunisationSchedules[${i}]._18monthRemark`, fMember.find("#EighteenMonthRemark").val());
      data.append(`SurveyImmunisationSchedules[${i}].Measles2`, fMember.find("#EighteenMonthMeasles").is(":checked") ? 1 : 0);
      data.append(`SurveyImmunisationSchedules[${i}]._18month2ndRemark`, fMember.find("#EighteenMonthSecondRemark").val());


    })

    $('.patientDiseases:visible').each(function (i) {
      var patientDisease = $(this);
      data.append(`SurveyDiseases[${i}].TypeOfDiseases`, patientDisease.data("diseasestype"));
      data.append(`SurveyDiseases[${i}].Name`, patientDisease.find("#PatientName").val());
      data.append(`SurveyDiseases[${i}].Age`, patientDisease.find("#PatientAge").val());
      data.append(`SurveyDiseases[${i}].History`, patientDisease.find("#PatientHistory").val());
      data.append(`SurveyDiseases[${i}].Med1`, patientDisease.find("#PatientMed1").val());
      data.append(`SurveyDiseases[${i}].Med2`, patientDisease.find("#PatientMed2").val());
      data.append(`SurveyDiseases[${i}].Med3`, patientDisease.find("#PatientMed3").val());
      data.append(`SurveyDiseases[${i}].Med4`, patientDisease.find("#PatientMed4").val());
      data.append(`SurveyDiseases[${i}].Med5`, patientDisease.find("#PatientMed5").val());
      data.append(`SurveyDiseases[${i}].Gen1`, patientDisease.find("#PatientGen1").val());
      data.append(`SurveyDiseases[${i}].Gen2`, patientDisease.find("#PatientGen2").val());
      data.append(`SurveyDiseases[${i}].Gen3`, patientDisease.find("#PatientGen3").val());
      data.append(`SurveyDiseases[${i}].Gen4`, patientDisease.find("#PatientGen4").val());
      data.append(`SurveyDiseases[${i}].Gen5`, patientDisease.find("#PatientGen5").val());


    })

    var res = await customFormDataPost(`${apiBaseUrl}survey/add`, data);
    if (res.status) {
      swal("Saved!", "Your Receiver form data has been Saved!", "success");
      console.log(res)
    }
    else {
      alert(res.messsage)
    }


  }
  return (
    <>
      <div>
        <div className="container-fluid">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/survey/">Home</Link></li>
              <li className="breadcrumb-item active" aria-current="page"><Link to="/survey/">Survey Detail</Link></li>
            </ol>
          </nav>
        </div>
        <div className="container">
          <div className="card shadow mb-4">

            <div className="card-body p-3 mx-5">

              <div className="row">

                <form onSubmit={handleSubmit(SurveySubmit)}>
                  <h2 className='text-center p-2'>SURVEY FORM</h2>

                  <h4>1.Family Identification Data</h4>
                  <div className="row my-4">
                    <div className="form-group col-md-4">
                      <label >Head of Family</label>
                      <input asp-for="PatientName" type="text"
                        className="form-control" placeholder="Head"
                        value={formData.headname}
                        onChange={(event) =>
                          setFormData({ ...formData, headname: event.target.value })
                        } />
                      <span className="text-danger field-validation-valid" asp-validation-for="PatientName" data-valmsg-replace="true" />
                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="inputPassword4">Occupation Of Head</label>
                      <input asp-for="FathersName" type="text"
                        className="form-control" placeholder="Occupation"
                        value={formData.occupation}
                        onChange={(event) =>
                          setFormData({ ...formData, occupation: event.target.value })
                        } />
                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="inputEmail4">Type of Family</label>
                      <input type="text"
                        className="form-control" placeholder="Joint,Small,Big,etc"
                        value={formData.typeof_family}
                        onChange={(event) =>
                          setFormData({ ...formData, typeof_family: event.target.value })
                        } />
                      <span className="text-danger field-validation-valid" asp-validation-for="Age" data-valmsg-replace="true" />
                    </div>
                  </div>
                  <div className="row">

                    <div className="form-group col-md-2">
                      <label htmlFor="inputPassword4">Size Of Family</label>
                      <input type="Number" min="0" className="form-control"
                        placeholder="1"
                        value={formData.familySize}
                        onChange={(event) => {
                          setFormData({ ...formData, familySize: event.target.value })
                        }
                        } />
                    </div> <div className="form-group col-md-2">
                      <label htmlFor="inputPassword4">Area</label>
                      <input asp-for="Gender" type="text" className="form-control"
                        placeholder="Urban/Rural"
                        value={formData.area}
                        onChange={(event) =>
                          setFormData({ ...formData, area: event.target.value })
                        } />
                    </div>
                    <div className="form-group col-md-8">
                      <label htmlFor="inputPassword4">Address</label>
                      <input asp-for="WardAndBedNo" type="text" className="form-control"
                        placeholder="House No."
                        value={formData.address}
                        onChange={(event) =>
                          setFormData({ ...formData, address: event.target.value })
                        } />
                    </div>
                  </div>


                  <div className="row">
                    <div className="col-md-12 my-4">
                      <table id="PatientFamilyMembers" className="table table-bordered">
                        <thead>
                          <tr >
                            <th >Member</th>
                            <th >Relation to Head</th>
                            <th >Occupation</th>
                            <th >Marital Status</th>
                          </tr>
                        </thead>
                        <tbody>

                          {familyMembers.map((member, index) => (
                            <tr key={index} className="p-0">
                              <td className="p-0"><input id="MemberName" type="text" style={{ border: "none" }} className="form-control" /></td>
                              <td className="p-0"><input id="MemberRelationToHead" style={{ border: "none" }} type="text" className="form-control" /></td>
                              <td className="p-0"><input id="MemberOccupation" style={{ border: "none" }} type="text" className="form-control" /></td>
                              <td className="p-0"><input id="MemberMaritalStatus" style={{ border: "none" }} type="text" className="form-control" /></td>
                            </tr>))}
                        </tbody>
                      </table>
                    </div>
                  </div>


                  <div className="row my-4">
                    <h4>2.Pediatric Data Of Family</h4>

                    <div className="col-md-12">
                      <table className="table table-bordered">
                        <tbody><tr>
                          <th>Children</th>
                          <th>Number</th>
                          <th>Fully Vaccinated</th>
                        </tr>
                          <tr className="p-0">
                            <td className='text-left'> 1. Neonate</td>
                            <td className="p-0" ><input type="number" min="0" style={{ border: "none" }} className="form-control bd"
                              value={formData.neonate_qty}
                              onChange={(event) =>
                                setFormData({ ...formData, neonate_qty: event.target.value })
                              } /></td>
                            <td className="text-center"><input type="checkbox" className='largerCheckbox'
                              value={formData.neonate_vaccine}
                              onChange={(event) =>
                                setFormData({ ...formData, neonate_vaccine: event.target.value })
                              } /></td>
                          </tr>

                          <tr className="p-0">
                            <td className='text-left'> 2. Infant</td>
                            <td className="p-0"><input type="number" min="0" className="form-control" style={{ border: "none" }}
                              value={formData.infant_qty}
                              onChange={(event) =>
                                setFormData({ ...formData, infant_qty: event.target.value })
                              } /></td>
                            <td className="text-center"><input type="checkbox" className='largerCheckbox'
                              value={formData.infant_vaccine}
                              onChange={(event) =>
                                setFormData({ ...formData, infant_vaccine: event.target.value })
                              } /></td>
                          </tr>

                          <tr className="p-0">
                            <td className='text-left'>3. Upto 5 year</td>
                            <td className="p-0"><input type="number" min="0" className="form-control" style={{ border: "none" }}
                              value={formData.upto5year_qty}
                              onChange={(event) =>
                                setFormData({ ...formData, upto5year_qty: event.target.value })
                              } /></td>
                            <td className="text-center"><input type="checkbox" className='largerCheckbox'
                              value={formData.upto5year_vaccine}
                              onChange={(event) =>
                                setFormData({ ...formData, upto5year_vaccine: event.target.value })
                              } /></td>
                          </tr>
                          <tr className="p-0">
                            <td className='text-left'>4. 5 year and above </td>
                            <td className="p-0"><input type="number" min="0" className="form-control" style={{ border: "none" }}
                              value={formData.five_yearabove_qty}
                              onChange={(event) =>
                                setFormData({ ...formData, five_yearabove_qty: event.target.value })
                              } /></td>
                            <td className="text-center"><input type="checkbox" className='largerCheckbox'
                              value={formData.five_yearabove_vaccine}
                              onChange={(event) =>
                                setFormData({ ...formData, five_yearabove_vaccine: event.target.value })
                              } /></td>
                          </tr>

                        </tbody></table>
                    </div>
                  </div>

                  {immunisationScheduleCount.length > 0 ? <h4>3.Immunisation Schedule</h4> : ""}
                  <div className="immunisationSchedules">
                    {immunisationScheduleCount.map((count, index) => (
                      <div className='border p-4 immunisationSchedule' key={index}>
                        <div className='row '>
                          <h3>{index + 1}. Data</h3>
                          <div className="form-group col-md-6">
                            <label>Name</label>
                            <input id="immunisationScheduleMemberName" type="text" className="form-control" placeholder="Name" />
                          </div>
                          <div className="form-group col-md-6">
                            <label>Age</label>
                            <input id="immunisationScheduleMemberAge" type="text" className="form-control" placeholder="Age" />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <table id="patientBloodGroup" className="table table-bordered">
                              <tbody><tr>
                                <th >Targeted Age Group</th>
                                <th >Name of Vaccine</th>
                                <th >Dosage</th>
                                <th >Remark</th>
                              </tr>
                                <tr className="p-0">
                                  <td className='pl-4'>At Birth</td>
                                  <td className='pl-4'>
                                    <tr>BCG Sabin</tr>
                                    <tr>HBV</tr>
                                    <tr>OPV 0</tr>
                                    <tr>Penta 1</tr>
                                  </td>
                                  <td className='pl-4'>
                                    <input className="text-center" id="AtBirthBCGSabin" type="checkbox" /><br />
                                    <input className="text-center" id="AtBirthHBV" type="checkbox" /><br />
                                    <input className="text-center" id="AtBirthOPV0" type="checkbox" /><br />
                                    <input className="text-center" id="AtBirthPenta1" type="checkbox" />
                                  </td>
                                  <td className="p-0"><input id="AtBirthRemark" type="text" className="form-control" /></td>

                                </tr>
                                <tr className="p-0">
                                  <td className='pl-4'>6 Weeks</td>
                                  <td className='pl-4'>
                                    <tr>PCV1</tr>
                                    <tr>OPV 1</tr>
                                    <tr>Penta 1</tr>
                                  </td>
                                  <td className='pl-4'>
                                    <input className="text-center" id="SixWeekPCV1" type="checkbox" /><br />
                                    <input className="text-center" id="SixWeekOPV1" type="checkbox" /><br />
                                    <input className="text-center" id="SixWeekPenta1" type="checkbox" /><br />

                                  </td>
                                  <td className="p-0"><input id="SixWeekRemark" type="text" className="form-control" /></td>

                                </tr>

                                <tr className="p-0">
                                  <td className='pl-4' >10 Weeks</td>
                                  <td className='pl-4'>
                                    <tr>Penta 3</tr>
                                    <tr>PC V 2</tr>
                                    <tr>OPV 2</tr>
                                  </td>
                                  <td className='pl-4'>
                                    <input className="text-center" id="TenWeekPenta3" type="checkbox" /><br />
                                    <input className="text-center" id="TenWeekPCV2" type="checkbox" /><br />
                                    <input className="text-center" id="TenWeekOPV2" type="checkbox" /><br />
                                  </td>
                                  <td className="p-0"><input id="TenWeekRemark" type="text" style={{ border: "none" }} className="form-control" /></td>

                                </tr>

                                <tr className="p-0">
                                  <td className='pl-4'>14 Weeks</td>
                                  <td className='pl-4'>
                                    <tr>DPT 3</tr>
                                    <tr>OPV 3</tr>
                                  </td>
                                  <td className='pl-4'>
                                    <input className="text-center" id="FourteenWeekDTP3" type="checkbox" /><br />
                                    <input className="text-center" id="FourteenWeekOPV3" type="checkbox" /><br />
                                  </td>
                                  <td className="p-0"><input id="FourteenWeekRemark" type="text" style={{ border: "none" }} className="form-control" /></td>

                                </tr>

                                <tr className="p-0">
                                  <td className='pl-4' >6 Months</td>
                                  <td className='pl-4'>
                                    <tr>Vitamin A</tr>
                                  </td>
                                  <td className='pl-4'>
                                    <input className="text-center" id="SixMonthVitaminA" type="checkbox" /><br />
                                  </td>
                                  <td className="p-0"><input id="SixMonthRemark" style={{ border: "none" }} type="text" className="form-control"
                                  /></td>

                                </tr>

                                <tr className="p-0">
                                  <td className='pl-4' >9 Months</td>
                                  <td className='pl-4'>
                                    <tr>Measles 1</tr>
                                  </td>
                                  <td className='pl-4'>
                                    <input className="text-center" id="NineMonthMeasles1" type="checkbox" /><br />
                                  </td>
                                  <td className="p-0"><input id="NineMonthRemark" type="text" style={{ border: "none" }} className="form-control" /></td>

                                </tr>

                                <tr className="p-0">
                                  <td className='pl-4' >9 Months</td>
                                  <td className='pl-4'>
                                    <tr >Yellow Fever</tr>
                                  </td>
                                  <td className='pl-4'>
                                    <input className="text-center" id="NineMonthYellowFever" type="checkbox" /><br />
                                  </td>
                                  <td className="p-0"><input id="NineMonthSecondRemark" type="text" style={{ border: "none" }} className="form-control" /></td>

                                </tr>
                                <tr className="p-0">
                                  <td className='pl-4' >12 Months</td>
                                  <td className='pl-4'>
                                    <tr>Vitamin A</tr>
                                  </td>
                                  <td className='pl-4'>
                                    <input className="text-center" id="TwelveMonthVitaminA" type="checkbox" /><br />
                                  </td>
                                  <td className="p-0"><input id="TwelveMonthRemark" type="text" style={{ border: "none" }} className="form-control" /></td>

                                </tr>
                                <tr className="p-0">
                                  <td className='pl-4'>18 Months</td>
                                  <td className='pl-4'>
                                    <tr>PCV1</tr>
                                  </td>
                                  <td className='pl-4'>
                                    <input className="text-center" id="EighteenMonthPCV1" type="checkbox" /><br />
                                  </td>
                                  <td className="p-0"><input id="EighteenMonthRemark" type="text" className="form-control" /></td>

                                </tr>

                                <tr className="p-0">
                                  <td className='pl-4'>18 Months</td>
                                  <td className='pl-4'>
                                    <tr>Measles 2</tr>
                                  </td>
                                  <td className='pl-4'>
                                    <input className="text-center" id="EighteenMonthMeasles" type="checkbox" /><br />
                                  </td>
                                  <td className="p-0"><input id="EighteenMonthSecondRemark" type="text" className="form-control" /></td>

                                </tr>

                              </tbody></table>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>


                  <div className="row my-4 ">
                    <h4>4.Diseases Prevalant In Community</h4>

                    <div className="row p-2 ">
                      <div className='row'>
                        <div className='col-md-3 '>
                          <label>1. Diabetes</label>
                        </div>
                        <div className='col-md-2 '>
                          <input type="checkbox" className='largerCheckbox'
                            //value={formData.diebetes}
                            onChange={(event) => setFormData({ ...formData, diebetes: event.target.checked })
                            } />
                        </div>
                      </div>
                      <div className='row table-bordered' style={{ display: formData.diebetes ? "" : "none" }}>
                        {familyMemberdiebetes.map((member, index) => (
                          <div key={index} className='row borderbt p-2 patientDiseases ' data-diseasestype="diabetes">
                            <div className='col-4 mt-2'>
                              <div className="form-group col-md-12">
                                <label >Name</label>
                                <input id="PatientName" type="text" className="form-control" placeholder="Name" />
                              </div>
                              <div className="form-group col-md-12">
                                <label >Age</label>
                                <input id="PatientAge" type="text" className="form-control" placeholder="Age" />
                              </div>
                              <div className="form-group col-md-12">
                                <label htmlFor="inputEmail4">History</label>
                                <input id="PatientHistory" type="text" className="form-control" placeholder="History" />
                              </div>
                            </div>
                            <div className='col-8 mt-3'>
                              <table className="table table-bordered">
                                <tbody><tr className="p-0">
                                  <th>S.no</th>
                                  <th>Medicine</th>
                                  <th>Generic</th>
                                </tr>
                                  <tr className="p-0">
                                    <td className='text-center p-0'> 1.</td>
                                    <td className="p-0"><input id="PatientMed1" style={{ border: "none" }} type="text" className="form-control" /></td>
                                    <td className="p-0"><input id="PatientGen1" style={{ border: "none" }} type="text" className="form-control" /></td>
                                  </tr>

                                  <tr className="p-0">
                                    <td className='text-center p-0'> 2.</td>
                                    <td className="p-0"><input id="PatientMed2" style={{ border: "none" }} type="text" className="form-control" /></td>
                                    <td className="p-0"><input id="PatientGen2" style={{ border: "none" }} type="text" className="form-control" /></td>

                                  </tr>

                                  <tr className="p-0">
                                    <td className='text-center p-0'>3.</td>
                                    <td className="p-0"><input id="PatientMed3" style={{ border: "none" }} type="text" className="form-control" /></td>
                                    <td className="p-0"><input id="PatientGen3" style={{ border: "none" }} type="text" className="form-control" /></td>
                                  </tr>
                                  <tr className="p-0">
                                    <td className='text-center p-0'>4.</td>
                                    <td className="p-0"><input id="PatientMed4" style={{ border: "none" }} type="text" className="form-control" /></td>
                                    <td className="p-0"><input id="PatientGen4" style={{ border: "none" }} type="text" className="form-control" /></td>
                                  </tr>
                                  <tr className="p-0">
                                    <td className='text-center p-0'>5.</td>
                                    <td className="p-0"><input id="PatientMed5" style={{ border: "none" }} type="text" className="form-control" /></td>
                                    <td className="p-0"><input id="PatientGen5" style={{ border: "none" }} type="text" className="form-control" /></td>
                                  </tr>

                                </tbody></table>
                            </div>
                          </div>))}
                        <div className='d-flex justify-content-center m-2' >
                          <button type="button" onClick={addDiabetesPatientClick} className="btn btn-primary ">Add</button>
                        </div>
                      </div>
                    </div>

                    <div className="row p-2">
                      <div className='row'>
                        <div className='col-md-3 '>
                          <label>2. BP</label>
                        </div>
                        <div className='col-md-2 '>
                          <input type="checkbox" className='largerCheckbox'
                            //value={formData.compatible1}
                            onChange={(event) =>
                              setFormData({ ...formData, Bp: event.target.checked })
                            } />
                        </div>
                      </div>
                      <div className='row table-bordered' style={{ display: formData.Bp ? "" : "none" }}>
                        {familyMemberbP.map((member, index) => (<div key={index} className='row borderbt p-2 patientDiseases ' data-diseasestype="BP">
                          <div className='col-4 mt-2'>
                            <div className="form-group col-md-12">
                              <label>Name</label>
                              <input id="PatientNmae" type="text" className="form-control" placeholder="Name" />
                            </div>
                            <div className="form-group col-md-12">
                              <label >Age</label>
                              <input id="PatientAge" type="text" className="form-control" placeholder="Age" />
                            </div>
                            <div className="form-group col-md-12">
                              <label htmlFor="inputEmail4">History</label>
                              <input id="PatientHistory" type="text" className="form-control" placeholder="History" />
                            </div>
                          </div>
                          <div className='col-8 mt-3'>
                            <table className="table table-bordered">
                              <tbody><tr>
                                <th>S.no</th>
                                <th>Medicine</th>
                                <th>Generic</th>
                              </tr>
                                <tr className="p-0">
                                  <td className='text-center p-0'> 1.</td>
                                  <td className="p-0" ><input id="PatientMed1" style={{ border: "none" }} type="text" className="form-control" /></td>
                                  <td className="p-0"><input id="PatientGen1" style={{ border: "none" }} type="text" className="form-control" /></td>
                                </tr>

                                <tr className="p-0">
                                  <td className='text-center p-0'> 2.</td>
                                  <td className="p-0"><input id="PatientMed2" style={{ border: "none" }} type="text" className="form-control" /></td>
                                  <td className="p-0"><input id="PatientGen2" style={{ border: "none" }} type="text" className="form-control" /></td>

                                </tr>

                                <tr className="p-0">
                                  <td className='text-center p-0'>3.</td>
                                  <td className="p-0"><input id="PatientMed3" style={{ border: "none" }} type="text" className="form-control" /></td>
                                  <td className="p-0"><input id="PatientGen3" style={{ border: "none" }} type="text" className="form-control" /></td>
                                </tr>
                                <tr className="p-0">
                                  <td className='text-center p-0'>4.</td>
                                  <td className="p-0"><input id="PatientMed4" style={{ border: "none" }} type="text" className="form-control" /></td>
                                  <td className="p-0"><input id="PatientGen4" style={{ border: "none" }} type="text" className="form-control" /></td>
                                </tr>
                                <tr className="p-0">
                                  <td className='text-center p-0'>5.</td>
                                  <td className="p-0"><input id="PatientMed5" style={{ border: "none" }} type="text" className="form-control" /></td>
                                  <td className="p-0"><input id="PatientGen5" style={{ border: "none" }} type="text" className="form-control" /></td>
                                </tr>

                              </tbody></table>
                          </div>
                        </div>))}
                        <div className='d-flex justify-content-center m-2' >
                          <button type="button" onClick={addBpPatientClick} className="btn btn-primary ">Add</button>
                        </div>
                      </div>
                    </div>

                    <div className='row p-2'>
                      <div className='row'>
                        <div className='col-md-3 '>
                          <label>3. Migraine</label>
                        </div>
                        <div className='col-md-2 '>
                          <input type="checkbox" className='largerCheckbox'
                            onChange={(event) =>
                              setFormData({ ...formData, Migration: event.target.checked })
                            } />
                        </div>
                      </div>
                      <div className='row table-bordered' style={{ display: formData.Migration ? "" : "none" }}>
                        {familyMembermigrane.map((member, index) => (<div key={index} className='row borderbt p-2 patientDiseases ' data-diseasestype="Migraine">
                          <div className='col-4 mt-2'>
                            <div className="form-group col-md-12">
                              <label >Name</label>
                              <input id="PatientName" type="text" className="form-control" placeholder="Name" />
                            </div>
                            <div className="form-group col-md-12">
                              <label >Age</label>
                              <input id="PatientAge" type="text" className="form-control" placeholder="Age" />
                            </div>
                            <div className="form-group col-md-12">
                              <label >History</label>
                              <input id="PatientHistory" type="text" className="form-control" placeholder="History" />
                            </div>
                          </div>
                          <div className='col-8 mt-3'>
                            <table className="table table-bordered">
                              <tbody><tr>
                                <th>S.no</th>
                                <th>Medicine</th>
                                <th>Generic</th>
                              </tr>
                                <tr className="p-0">
                                  <td className='text-center p-0'> 1.</td>
                                  <td className="p-0"><input id="PatientMed1" type="text" style={{ border: "none" }} className="form-control" /></td>
                                  <td className="p-0" ><input id="PatientGen1" type="text" style={{ border: "none" }} className="form-control" /></td>

                                </tr>

                                <tr className="p-0">
                                  <td className='text-center p-0'> 2.</td>
                                  <td className="p-0"><input id="PatientMed2" type="text" style={{ border: "none" }} className="form-control" /></td>
                                  <td className="p-0"><input id="PatientGen2" type="text" style={{ border: "none" }} className="form-control" /></td>

                                </tr>

                                <tr className="p-0">
                                  <td className='text-center p-0'>3.</td>
                                  <td className="p-0" c><input id="PatientMed3" type="text" style={{ border: "none" }} className="form-control" /></td>
                                  <td className="p-0"><input id="PatientGen3" type="text" style={{ border: "none" }} className="form-control" /></td>
                                </tr>
                                <tr className="p-0">
                                  <td className='text-center p-0'>4.</td>
                                  <td className="p-0"><input id="PatientMed4" type="text" style={{ border: "none" }} className="form-control" /></td>
                                  <td className="p-0"><input id="PatientGen4" type="text" style={{ border: "none" }} className="form-control" /></td>
                                </tr>
                                <tr className="p-0">
                                  <td className='text-center p-0'>5.</td>
                                  <td className="p-0"><input id="PatientMed5" type="text" style={{ border: "none" }} className="form-control" /></td>
                                  <td className="p-0"><input id="PatientGen5" type="text" style={{ border: "none" }} className="form-control" /></td>
                                </tr>

                              </tbody></table>
                          </div>
                        </div>))}
                        <div className='d-flex justify-content-center m-2' >
                          <button type="button" onClick={addMigranePatientClick} className="btn btn-primary ">Add</button>
                        </div>
                      </div>
                    </div>

                    <div className='row p-2'>
                      <div className='row'>
                        <div className='col-md-3 '>
                          <label>4. Cataract</label>
                        </div>
                        <div className='col-md-2 '>
                          <input type="checkbox" className='largerCheckbox'
                            //value={formData.compatible1}
                            onChange={(event) =>
                              setFormData({ ...formData, Cataract: event.target.checked })
                            } />
                        </div>
                      </div>
                      <div className='row table-bordered' style={{ display: formData.Cataract ? "" : "none" }}>
                        {familyMembercataract.map((member, index) => (<div key={index} className='row borderbt p-2 patientDiseases ' data-diseasestype="Cataract">
                          <div className='col-4 mt-2'>
                            <div className="form-group col-md-12">
                              <label >Name</label>
                              <input id="PatientName" type="text" className="form-control" placeholder="Name" />
                            </div>
                            <div className="form-group col-md-12">
                              <label>Age</label>
                              <input id="PatientAge" type="text" className="form-control" placeholder="Age" />
                            </div>
                            <div className="form-group col-md-12">
                              <label >History</label>
                              <input id="PatientHistory" type="text" className="form-control" placeholder="History" />
                            </div>
                          </div>
                          <div className='col-8 mt-3'>
                            <table className="table table-bordered">
                              <tbody><tr>
                                <th>S.no</th>
                                <th>Medicine</th>
                                <th>Generic</th>
                              </tr>
                                <tr className="p-0">
                                  <td className='text-center p-0'> 1.</td>
                                  <td className="p-0"><input id="PatientMed1" type="text" style={{ border: "none" }} className="form-control" /></td>
                                  <td className="p-0"><input id="PatientGen1" type="text" style={{ border: "none" }} className="form-control" /></td>
                                </tr>

                                <tr className="p-0">
                                  <td className='text-center p-0'> 2.</td>
                                  <td className="p-0"><input id="PatientMed2" type="text" style={{ border: "none" }} className="form-control" /></td>
                                  <td className="p-0"><input id="PatientGen2" type="text" style={{ border: "none" }} className="form-control" /></td>
                                </tr>

                                <tr className="p-0">
                                  <td className='text-center p-0'>3.</td>
                                  <td className="p-0"><input id="PatientMed3" type="text" style={{ border: "none" }} className="form-control" /></td>
                                  <td className="p-0"><input id="PatientGen3" type="text" style={{ border: "none" }} className="form-control" /></td>
                                </tr>
                                <tr className="p-0">
                                  <td className='text-center p-0'>4.</td>
                                  <td className="p-0"><input id="PatientMed4" type="text" style={{ border: "none" }} className="form-control" /></td>
                                  <td className="p-0"><input id="PatientGen4" type="text" style={{ border: "none" }} className="form-control" /></td>
                                </tr>
                                <tr className="p-0">
                                  <td className='text-center p-0'>5.</td>
                                  <td className="p-0"><input id="CPatientMed5" type="text" style={{ border: "none" }} className="form-control" /></td>
                                  <td className="p-0"><input id="PatientGen5" type="text" style={{ border: "none" }} className="form-control" /></td>
                                </tr>

                              </tbody></table>
                          </div>
                        </div>))}
                        <div className='d-flex justify-content-center m-2' >
                          <button type="button" onClick={addCataractPatientClick} className="btn btn-primary ">Add</button>
                        </div>
                      </div>
                    </div>

                    <div className='row p-2'>
                      <div className='row'>
                        <div className='col-md-3 '>
                          <label>5. Cardiovascular disease</label>
                        </div>
                        <div className='col-md-2 '>
                          <input type="checkbox" className='largerCheckbox'
                            //value={formData.compatible1}
                            onChange={(event) =>
                              setFormData({ ...formData, Cardiovasculardisease: event.target.checked })
                            } />
                        </div>
                      </div>
                      <div className='row table-bordered' style={{ display: formData.Cardiovasculardisease ? "" : "none" }}>
                        {familyMembercardio.map((member, index) => (<div key={index} className='row borderbt p-2 patientDiseases ' data-diseasestype="Cardiovascular">
                          <div className='col-4 mt-2'>
                            <div className="form-group col-md-12">
                              <label>Name</label>
                              <input id="PatientName" type="text" className="form-control" placeholder="Name" />
                            </div>
                            <div className="form-group col-md-12">
                              <label>Age</label>
                              <input id="PatientAge" type="text" className="form-control" placeholder="Age" />
                            </div>
                            <div className="form-group col-md-12">
                              <label>History</label>
                              <input id="PatientHistory" type="text" className="form-control" placeholder="History" />
                            </div>
                          </div>
                          <div className='col-8 mt-3'>
                            <table className="table table-bordered">
                              <tbody><tr>
                                <th>S.no</th>
                                <th>Medicine</th>
                                <th>Generic</th>
                              </tr>
                                <tr className="p-0">
                                  <td className='text-center p-0'> 1.</td>
                                  <td className="p-0"><input id="PatientMed1" type="text" style={{ border: "none" }} className="form-control" /></td>
                                  <td className="p-0"><input id="PatientGen1" type="text" style={{ border: "none" }} className="form-control" /></td>
                                </tr>

                                <tr className="p-0">
                                  <td className='text-center p-0'> 2.</td>
                                  <td className="p-0"><input id="PatientMed2" type="text" style={{ border: "none" }} className="form-control" /></td>
                                  <td className="p-0"><input id="PatientGen2" type="text" style={{ border: "none" }} className="form-control" /></td>
                                </tr>

                                <tr className="p-0">
                                  <td className='text-center p-0'>3.</td>
                                  <td className="p-0"><input id="PatientMed3" type="text" style={{ border: "none" }} className="form-control" /></td>
                                  <td className="p-0"><input id="PatientGen3" type="text" style={{ border: "none" }} className="form-control" /></td>
                                </tr>
                                <tr className="p-0">
                                  <td className='text-center p-0'>4.</td>
                                  <td className="p-0"><input id="PatientMed4" type="text" style={{ border: "none" }} className="form-control" /></td>
                                  <td className="p-0"><input id="PatientGen4" type="text" style={{ border: "none" }} className="form-control" /></td>
                                </tr>
                                <tr className="p-0">
                                  <td className='text-center p-0'>5.</td>
                                  <td className="p-0"><input id="PatientMed5" type="text" style={{ border: "none" }} className="form-control" /></td>
                                  <td className="p-0"><input id="PatientGen5" type="text" style={{ border: "none" }} className="form-control" /></td>
                                </tr>

                              </tbody></table>
                          </div>
                        </div>))}
                        <div className='d-flex justify-content-center m-2' >
                          <button type="button" onClick={addCardioPatientClick} className="btn btn-primary ">Add</button>
                        </div>
                      </div>
                    </div>

                    <div className='row p-2'>
                      <div className='row'>
                        <div className='col-md-3 '>
                          <label>6. Asthma</label>
                        </div>
                        <div className='col-md-2 '>
                          <input type="checkbox" className='largerCheckbox'
                            // value={formData.compatible1}
                            onChange={(event) =>
                              setFormData({ ...formData, Asthma: event.target.checked })
                            } />
                        </div>
                      </div>
                      <div className='row table-bordered' style={{ display: formData.Asthma ? "" : "none" }}>
                        {familyMemberasthma.map((member, index) => (<div key={index} className='row borderbt p-2 patientDiseases ' data-diseasestype="Asthma">
                          <div className='col-4 mt-2'>
                            <div className="form-group col-md-12">
                              <label >Name</label>
                              <input id="PatientName" type="text" className="form-control" placeholder="Name" />
                            </div>
                            <div className="form-group col-md-12">
                              <label>Age</label>
                              <input id="PatientAge" type="text" className="form-control" placeholder="Age" />
                            </div>
                            <div className="form-group col-md-12">
                              <label>History</label>
                              <input id="PatientHistory" type="text" className="form-control" placeholder="History" />
                            </div>
                          </div>
                          <div className='col-8 mt-3'>
                            <table className="table table-bordered">
                              <tbody><tr>
                                <th>S.no</th>
                                <th>Medicine</th>
                                <th>Generic</th>
                              </tr>
                                <tr className="p-0">
                                  <td className='text-center p-0'> 1.</td>
                                  <td className="p-0"><input id="PatientMed1" type="text" style={{ border: "none" }} className="form-control" /></td>
                                  <td className="p-0"><input id="PatientGen1" type="text" style={{ border: "none" }} className="form-control" /></td>
                                </tr>

                                <tr className="p-0">
                                  <td className='text-center p-0'> 2.</td>
                                  <td className="p-0"><input id="PatientMed2" type="text" style={{ border: "none" }} className="form-control" /></td>
                                  <td className="p-0"><input id="PatientGen2" type="text" style={{ border: "none" }} className="form-control" /></td>

                                </tr>

                                <tr className="p-0">
                                  <td className='text-center p-0'>3.</td>
                                  <td className="p-0"><input id="PatientMed3" type="text" style={{ border: "none" }} className="form-control" /></td>
                                  <td className="p-0"><input id="PatientGen3" type="text" style={{ border: "none" }} className="form-control" /></td>
                                </tr>
                                <tr className="p-0">
                                  <td className='text-center p-0'>4.</td>
                                  <td className="p-0"><input id="PatientMed4" type="text" style={{ border: "none" }} className="form-control" /></td>
                                  <td className="p-0"><input id="PatientGen4" type="text" style={{ border: "none" }} className="form-control" /></td>
                                </tr>
                                <tr className="p-0">
                                  <td className='text-center p-0'>5.</td>
                                  <td className="p-0"><input id="PatientMed5" type="text" style={{ border: "none" }} className="form-control" /></td>
                                  <td className="p-0"><input id="PatientGen5" type="text" style={{ border: "none" }} className="form-control" /></td>
                                </tr>

                              </tbody></table>
                          </div>
                        </div>))}
                        <div className='d-flex justify-content-center m-2' >
                          <button type="button" onClick={addAsthmaPatientClick} className="btn btn-primary ">Add</button>
                        </div>
                      </div>
                    </div>

                    <div className='row p-2'>
                      <div className='row'>
                        <div className='col-md-3 '>
                          <label>7. TB</label>
                        </div>
                        <div className='col-md-2 '>
                          <input type="checkbox" className='largerCheckbox'
                            //value={formData.compatible1}
                            onChange={(event) =>
                              setFormData({ ...formData, Tb: event.target.checked })
                            } />
                        </div>
                      </div>
                      <div className='row table-bordered' style={{ display: formData.Tb ? "" : "none" }}>
                        {familyMembertb.map((member, index) => (<div key={index} className='row borderbt p-2 patientDiseases ' data-diseasestype="TB">
                          <div className='col-4 mt-2'>
                            <div className="form-group col-md-12">
                              <label >Name</label>
                              <input id="PatientName" type="text" className="form-control" placeholder="Name" />
                            </div>
                            <div className="form-group col-md-12">
                              <label >Age</label>
                              <input id="PatientAge" type="text" className="form-control" placeholder="Age" />
                            </div>
                            <div className="form-group col-md-12">
                              <label >History</label>
                              <input id="PatientHistory" type="text" className="form-control" placeholder="History" />
                            </div>
                          </div>
                          <div className='col-8 mt-3'>
                            <table className="table table-bordered">
                              <tbody><tr>
                                <th>S.no</th>
                                <th>Medicine</th>
                                <th>Generic</th>
                              </tr>
                                <tr className="p-0">
                                  <td className='text-center p-0'> 1.</td>
                                  <td className="p-0"><input id="PatientMed1" type="text" style={{ border: "none" }} className="form-control" /></td>
                                  <td className="p-0"><input id="PatientGen1" type="text" style={{ border: "none" }} className="form-control" /></td>
                                </tr>

                                <tr className="p-0">
                                  <td className='text-center p-0'> 2.</td>
                                  <td className="p-0"><input id="PatientMed2" type="text" style={{ border: "none" }} className="form-control" /></td>
                                  <td className="p-0"><input id="PatientGen2" type="text" style={{ border: "none" }} className="form-control" /></td>

                                </tr>

                                <tr className="p-0">
                                  <td className='text-center p-0'>3.</td>
                                  <td className="p-0"><input id="PatientMed3" type="text" style={{ border: "none" }} className="form-control" /></td>
                                  <td className="p-0"><input id="PatientGen3" type="text" style={{ border: "none" }} className="form-control" /></td>
                                </tr>
                                <tr className="p-0">
                                  <td className='text-center p-0'>4.</td>
                                  <td className="p-0"><input id="PatientMed4" type="text" style={{ border: "none" }} className="form-control" /></td>
                                  <td className="p-0"><input id="PatientGen4" type="text" style={{ border: "none" }} className="form-control" /></td>
                                </tr>
                                <tr className="p-0">
                                  <td className='text-center p-0'>5.</td>
                                  <td className="p-0"><input id="PatientMed5" type="text" style={{ border: "none" }} className="form-control" /></td>
                                  <td className="p-0"><input id="PatientGen5" type="text" style={{ border: "none" }} className="form-control" /></td>
                                </tr>

                              </tbody></table>
                          </div>
                        </div>))}
                        <div className='d-flex justify-content-center m-2' >
                          <button type="button" onClick={addTbPatientClick} className="btn btn-primary ">Add</button>
                        </div>
                      </div>
                    </div>

                    <div className='row p-2'>
                      <div className='row'>
                        <div className='col-md-3 '>
                          <label>8. Kidney disease</label>
                        </div>
                        <div className='col-md-2 '>
                          <input type="checkbox" className='largerCheckbox'
                            // value={formData.compatible1}
                            onChange={(event) =>
                              setFormData({ ...formData, Kidneydisease: event.target.checked })
                            } />
                        </div>
                      </div>
                      <div className='row table-bordered' style={{ display: formData.Kidneydisease ? "" : "none" }}>
                        {familyMemberkidney.map((member, index) => (<div key={index} className='row borderbt p-2 patientDiseases ' data-diseasestype="Kidney">
                          <div className='col-4 mt-2'>
                            <div className="form-group col-md-12">
                              <label >Name</label>
                              <input id="PatientName" type="text" className="form-control" placeholder="Name" />
                            </div>
                            <div className="form-group col-md-12">
                              <label>Age</label>
                              <input id="PatientAge" type="text" className="form-control" placeholder="Age" />
                            </div>
                            <div className="form-group col-md-12">
                              <label >History</label>
                              <input id="PatientHistory" type="text" className="form-control" placeholder="History" />

                            </div>
                          </div>
                          <div className='col-8 mt-3'>
                            <table id="patientCrossMatchReport" className="table table-bordered">
                              <tbody><tr>
                                <th>S.no</th>
                                <th>Medicine</th>
                                <th>Generic</th>
                              </tr>
                                <tr className="p-0">
                                  <td className='text-center p-0'> 1.</td>
                                  <td className="p-0"><input id="PatientMed1" type="text" style={{ border: "none" }} className="form-control" /></td>
                                  <td className="p-0"><input id="PatientGen1" type="text" style={{ border: "none" }} className="form-control" /></td>
                                </tr>

                                <tr className="p-0">
                                  <td className='text-center p-0'> 2.</td>
                                  <td className="p-0"><input id="PatientMed2" type="text" style={{ border: "none" }} className="form-control" /></td>
                                  <td className="p-0"><input id="PatientGen2" type="text" style={{ border: "none" }} className="form-control" /></td>
                                </tr>

                                <tr className="p-0">
                                  <td className='text-center p-0'>3.</td>
                                  <td className="p-0"><input id="PatientMed3" type="text" style={{ border: "none" }} className="form-control" /></td>
                                  <td className="p-0"><input id="PatientGen3" type="text" style={{ border: "none" }} className="form-control" /></td>
                                </tr>
                                <tr className="p-0">
                                  <td className='text-center p-0'>4.</td>
                                  <td className="p-0"><input id="PatientMed4" type="text" style={{ border: "none" }} className="form-control" /></td>
                                  <td className="p-0"><input id="PatientGen4" type="text" style={{ border: "none" }} className="form-control" /></td>
                                </tr>
                                <tr className="p-0">
                                  <td className='text-center p-0'>5.</td>
                                  <td className="p-0"><input id="PatientMed5" type="text" style={{ border: "none" }} className="form-control" /></td>
                                  <td className="p-0"><input id="PatientGen5" type="text" style={{ border: "none" }} className="form-control" /></td>
                                </tr>

                              </tbody></table>
                          </div>
                        </div>))}
                        <div className='d-flex justify-content-center m-2' >
                          <button type="button" onClick={addKidneyPatientClick} className="btn btn-primary ">Add</button>
                        </div>
                      </div>
                    </div>

                    <div className='row p-2'>
                      <div className='row'>
                        <div className='col-md-3 '>
                          <label>9. Epilepsy</label>
                        </div>
                        <div className='col-md-2 '>
                          <input type="checkbox" className='largerCheckbox'
                            //value={formData.compatible1}
                            onChange={(event) =>
                              setFormData({ ...formData, Epilepsy: event.target.checked })
                            } />
                        </div>
                      </div>
                      <div className='row table-bordered' style={{ display: formData.Epilepsy ? "" : "none" }}>
                        {familyMemberepilepsy.map((member, index) => (<div key={index} className='row borderbt p-2 patientDiseases ' data-diseasestype="Epilepsy">
                          <div className='col-4 mt-2'>
                            <div className="form-group col-md-12">
                              <label >Name</label>
                              <input id="PatientName" type="text" className="form-control" placeholder="Name" />
                            </div>
                            <div className="form-group col-md-12">
                              <label >Age</label>
                              <input id="PatientAge" type="text" className="form-control" placeholder="Age" />
                            </div>
                            <div className="form-group col-md-12">
                              <label >History</label>
                              <input id="PatientHistory" type="text" className="form-control" placeholder="History" />
                            </div>
                          </div>
                          <div className='col-8 mt-3'>
                            <table className="table table-bordered">
                              <tbody><tr>
                                <th>S.no</th>
                                <th>Medicine</th>
                                <th>Generic</th>
                              </tr>
                                <tr className="p-0">
                                  <td className='text-center p-0'> 1.</td>
                                  <td className="p-0"><input id="PatientMed1" type="text" style={{ border: "none" }} className="form-control" /></td>
                                  <td className="p-0"><input id="PatientGen1" type="text" style={{ border: "none" }} className="form-control" /></td>
                                </tr>

                                <tr className="p-0">
                                  <td className='text-center p-0'> 2.</td>
                                  <td className="p-0"><input id="PatientMed2" type="text" style={{ border: "none" }} className="form-control" /></td>
                                  <td className="p-0"><input id="PatientGen2" type="text" style={{ border: "none" }} className="form-control" /></td>

                                </tr>

                                <tr className="p-0">
                                  <td className='text-center p-0'>3.</td>
                                  <td className="p-0"><input id="PatientMed3" type="text" style={{ border: "none" }} className="form-control" /></td>
                                  <td className="p-0"><input id="PatientGen3" type="text" style={{ border: "none" }} className="form-control" /></td>
                                </tr>
                                <tr className="p-0">
                                  <td className='text-center p-0'>4.</td>
                                  <td className="p-0"><input id="PatientMed4" type="text" style={{ border: "none" }} className="form-control" /></td>
                                  <td className="p-0"><input id="PatientGen4" type="text" style={{ border: "none" }} className="form-control" /></td>
                                </tr>
                                <tr className="p-0">
                                  <td className='text-center p-0'>5.</td>
                                  <td className="p-0"><input id="PatientMed5" type="text" style={{ border: "none" }} className="form-control" /></td>
                                  <td className="p-0"><input id="PatientGen5" type="text" style={{ border: "none" }} className="form-control" /></td>
                                </tr>

                              </tbody></table>
                          </div>
                        </div>))}
                        <div className='d-flex justify-content-center m-2' >
                          <button type="button" onClick={addEpilepsyPatientClick} className="btn btn-primary ">Add</button>
                        </div>
                      </div>
                    </div>

                    <div className='row p-2'>
                      <div className='row'>
                        <div className='col-md-3 '>
                          <label>10. Gastric Disease</label>
                        </div>
                        <div className='col-md-2 '>
                          <input type="checkbox" className='largerCheckbox'
                            //value={formData.compatible1}
                            onChange={(event) =>
                              setFormData({ ...formData, Gastricdisease: event.target.checked })
                            } />
                        </div>
                      </div>
                      <div className='row table-bordered' style={{ display: formData.Gastricdisease ? "" : "none" }}>
                        {familyMembergastric.map((member, index) => (<div key={index} className='row borderbt p-2 patientDiseases ' data-diseasestype="Gastric">
                          <div className='col-4 mt-2'>
                            <div className="form-group col-md-12">
                              <label >Name</label>
                              <input id="PatientName" type="text" className="form-control" placeholder="Name" />
                            </div>
                            <div className="form-group col-md-12">
                              <label >Age</label>
                              <input id="PatientAge" type="text" className="form-control" placeholder="Age" />
                            </div>
                            <div className="form-group col-md-12">
                              <label >History</label>
                              <input id="PatientHistory" type="text" className="form-control" placeholder="History" />
                            </div>
                          </div>
                          <div className='col-8 mt-3'>
                            <table className="table table-bordered">
                              <tbody><tr>
                                <th>S.no</th>
                                <th>Medicine</th>
                                <th>Generic</th>
                              </tr>
                                <tr className="p-0">
                                  <td className='text-center p-0'> 1.</td>
                                  <td className="p-0"><input id="PatientMed1" type="text" style={{ border: "none" }} className="form-control" /></td>
                                  <td className="p-0"><input id="PatientGen1" type="text" style={{ border: "none" }} className="form-control" /></td>

                                </tr>

                                <tr className="p-0">
                                  <td className='text-center p-0'> 2.</td>
                                  <td className="p-0"><input id="PatientMed2" type="text" style={{ border: "none" }} className="form-control" /></td>
                                  <td className="p-0"><input id="PatientGen2" type="text" style={{ border: "none" }} className="form-control" /></td>

                                </tr>

                                <tr className="p-0">
                                  <td className='text-center p-0'>3.</td>
                                  <td className="p-0"><input id="PatientMed3" type="text" style={{ border: "none" }} className="form-control" /></td>
                                  <td className="p-0"><input id="PatientGen3" type="text" style={{ border: "none" }} className="form-control" /></td>
                                </tr>
                                <tr className="p-0">
                                  <td className='text-center p-0'>4.</td>
                                  <td className="p-0"><input id="PatientMed4" type="text" style={{ border: "none" }} className="form-control" /></td>
                                  <td className="p-0"><input id="PatientGen4" type="text" style={{ border: "none" }} className="form-control" /></td>
                                </tr>
                                <tr className="p-0">
                                  <td className='text-center p-0'>5.</td>
                                  <td className="p-0"><input id="PatientMed5" type="text" style={{ border: "none" }} className="form-control" /></td>
                                  <td className="p-0"><input id="PatientGen5" type="text" style={{ border: "none" }} className="form-control" /></td>
                                </tr>

                              </tbody></table>
                          </div>
                        </div>))}
                        <div className='d-flex justify-content-center m-2' >
                          <button type="button" onClick={addGastricPatientClick} className="btn btn-primary ">Add</button>
                        </div>
                      </div>
                    </div>

                    <div className='row p-2'>
                      <div className='row'>
                        <div className='col-md-3 '>
                          <label>11. Previous Surgery</label>
                        </div>
                        <div className='col-md-2 '>
                          <input type="checkbox" className='largerCheckbox'
                            //value={formData.compatible1}
                            onChange={(event) =>
                              setFormData({ ...formData, prevsurgery: event.target.checked })
                            } />
                        </div>
                      </div>
                      <div className='row table-bordered' style={{ display: formData.prevsurgery ? "" : "none" }}>
                        {familyMembersurgery.map((member, index) => (<div key={index} className='row borderbt p-2 patientDiseases ' data-diseasestype="PreviousSurgery">
                          <div className='col-4 mt-2'>
                            <div className="form-group col-md-12">
                              <label >Name</label>
                              <input id="PatientName" type="text" className="form-control" placeholder="Name" />
                            </div>
                            <div className="form-group col-md-12">
                              <label >Age</label>
                              <input id="PatientAge" type="text" className="form-control" placeholder="Age" />
                            </div>
                            <div className="form-group col-md-12">
                              <label >History</label>
                              <input id="PatientHistory" type="text" className="form-control" placeholder="History" />
                            </div>
                          </div>
                          <div className='col-8 mt-3'>
                            <table className="table table-bordered">
                              <tbody><tr>
                                <th>S.no</th>
                                <th>Medicine</th>
                                <th>Generic</th>
                              </tr>
                                <tr className="p-0">
                                  <td className='text-center p-0'> 1.</td>
                                  <td className="p-0"><input id="PatientMed1" type="text" style={{ border: "none" }} className="form-control" /></td>
                                  <td className="p-0"><input id="PatientGen1" type="text" style={{ border: "none" }} className="form-control" /></td>
                                </tr>

                                <tr className="p-0">
                                  <td className='text-center p-0'> 2.</td>
                                  <td className="p-0"><input id="PatientMed2" type="text" style={{ border: "none" }} className="form-control" /></td>
                                  <td className="p-0"><input id="PatientGen2" type="text" style={{ border: "none" }} className="form-control" /></td>

                                </tr>

                                <tr className="p-0">
                                  <td className='text-center p-0'>3.</td>
                                  <td className="p-0"><input id="PatientMed3" type="text" style={{ border: "none" }} className="form-control" /></td>
                                  <td className="p-0"><input id="PatientGen3" type="text" style={{ border: "none" }} className="form-control" /></td>
                                </tr>
                                <tr className="p-0">
                                  <td className='text-center p-0'>4.</td>
                                  <td className="p-0"><input id="PatientMed4" type="text" style={{ border: "none" }} className="form-control" /></td>
                                  <td className="p-0"><input id="PatientGen4" type="text" style={{ border: "none" }} className="form-control" /></td>
                                </tr>
                                <tr className="p-0">
                                  <td className='text-center p-0'>5.</td>
                                  <td className="p-0"><input id="PatientMed5" type="text" style={{ border: "none" }} className="form-control" /></td>
                                  <td className="p-0"><input id="PatientGen5" type="text" style={{ border: "none" }} className="form-control" /></td>
                                </tr>

                              </tbody></table>
                          </div>
                        </div>))}
                        <div className='d-flex justify-content-center m-2' >
                          <button type="button" onClick={addSurgeryPatientClick} className="btn btn-primary ">Add</button>
                        </div>
                      </div>
                    </div>

                    <div className='row p-2'>
                      <div className='row'>
                        <div className='col-md-3 '>
                          <label>12. Anemia</label>
                        </div>
                        <div className='col-md-2 '>
                          <input type="checkbox" className='largerCheckbox'
                            //value={formData.compatible1}
                            onChange={(event) =>
                              setFormData({ ...formData, Anemia: event.target.checked })
                            } />
                        </div>
                      </div>
                      <div className='row table-bordered' style={{ display: formData.Anemia ? "" : "none" }}>
                        {familyMemberanemia.map((member, index) => (<div key={index} className='row borderbt p-2 patientDiseases ' data-diseasestype="Anemias">
                          <div className='col-4 mt-2'>
                            <div className="form-group col-md-12">
                              <label htmlFor="inputEmail4">Name</label>
                              <input id="PatientName" type="text" className="form-control" placeholder="Name" />

                            </div>
                            <div className="form-group col-md-12">
                              <label htmlFor="inputEmail4">Age</label>
                              <input id="PatientAge" type="text" className="form-control" placeholder="Age" />
                            </div>
                            <div className="form-group col-md-12">
                              <label >History</label>
                              <input id="PatientHistory" type="text" className="form-control" placeholder="History" />
                            </div>
                          </div>
                          <div className='col-8 mt-3'>
                            <table className="table table-bordered">
                              <tbody><tr>
                                <th>S.no</th>
                                <th>Medicine</th>
                                <th>Generic</th>
                              </tr>
                                <tr className="p-0">
                                  <td className='text-center p-0'> 1.</td>
                                  <td className="p-0"><input id="PatientMed1" type="text" style={{ border: "none" }} className="form-control" /></td>
                                  <td className="p-0"><input id="PatientGen1" type="text" style={{ border: "none" }} className="form-control" /></td>
                                </tr>

                                <tr className="p-0">
                                  <td className='text-center p-0'> 2.</td>
                                  <td className="p-0"><input id="PatientMed2" type="text" style={{ border: "none" }} className="form-control" /></td>
                                  <td className="p-0"><input id="PatientGen2" type="text" style={{ border: "none" }} className="form-control" /></td>

                                </tr>

                                <tr className="p-0">
                                  <td className='text-center p-0'>3.</td>
                                  <td className="p-0"><input id="PatientMed3" type="text" style={{ border: "none" }} className="form-control" /></td>
                                  <td className="p-0"><input id="PatientGer3" type="text" style={{ border: "none" }} className="form-control" /></td>
                                </tr>
                                <tr className="p-0">
                                  <td className='text-center p-0'>4.</td>
                                  <td className="p-0"><input id="PatientMed4" type="text" style={{ border: "none" }} className="form-control" /></td>
                                  <td className="p-0"><input id="PatientGen4" type="text" style={{ border: "none" }} className="form-control" /></td>
                                </tr>
                                <tr className="p-0">
                                  <td className='text-center p-0'>5.</td>
                                  <td className="p-0"><input id="PatientMed5" type="text" style={{ border: "none" }} className="form-control" /></td>
                                  <td className="p-0"><input id="PatientGen5" type="text" style={{ border: "none" }} className="form-control" /></td>
                                </tr>

                              </tbody></table>
                          </div>
                        </div>))}
                        <div className='d-flex justify-content-center m-2' >
                          <button type="button" onClick={addAnemiaPatientClick} className="btn btn-primary ">Add</button>
                        </div>
                      </div>
                    </div>

                    <div className='row '>
                      <div className='row'>
                        <div className='col-md-3 '>
                          <label>13. Others</label>
                        </div>
                        <div className='col-md-2 '>
                          <input type="checkbox" className='largerCheckbox'
                            //value={formData.compatible1}
                            onChange={(event) =>
                              setFormData({ ...formData, others: event.target.checked })
                            } />
                        </div>
                      </div>
                      <div className='row table-bordered' style={{ display: formData.others ? "" : "none" }}>
                        {familyMemberother.map((member, index) => (<div key={index} className='row borderbt p-2 patientDiseases ' data-diseasestype="Others">
                          <div className='col-4 mt-2'>
                            <div className="form-group col-md-12">
                              <label >Name</label>
                              <input id="PatientName" type="text" className="form-control" placeholder="Name" />
                            </div>
                            <div className="form-group col-md-12">
                              <label>Age</label>
                              <input id="PatientAge" type="text" className="form-control" placeholder="Age" />
                            </div>
                            <div className="form-group col-md-12">
                              <label >History</label>
                              <input id="PatientHistory" type="text" className="form-control" placeholder="History" />
                            </div>
                          </div>
                          <div className='col-8 mt-3'>
                            <table className="table table-bordered">
                              <tbody><tr>
                                <th>S.no</th>
                                <th>Medicine</th>
                                <th>Generic</th>
                              </tr>
                                <tr className="p-0">
                                  <td className='text-center p-0'> 1.</td>
                                  <td className="p-0" ><input id="PatientMed1" type="text" style={{ border: "none" }} className="form-control" /></td>
                                  <td className="p-0"><input id="PatientGen1" type="text" style={{ border: "none" }} className="form-control" /></td>
                                </tr>

                                <tr className="p-0">
                                  <td className='text-center p-0'> 2.</td>
                                  <td className="p-0"><input id="PatientMed2" type="text" style={{ border: "none" }} className="form-control" /></td>
                                  <td className="p-0"><input id="PatientGen2" type="text" style={{ border: "none" }} className="form-control" /></td>

                                </tr>

                                <tr className="p-0">
                                  <td className='text-center p-0'>3.</td>
                                  <td className="p-0"><input id="PatientMed3" type="text" style={{ border: "none" }} className="form-control" /></td>
                                  <td className="p-0"><input id="PatientGen3" type="text" style={{ border: "none" }} className="form-control" /></td>
                                </tr>
                                <tr className="p-0">
                                  <td className='text-center p-0'>4.</td>
                                  <td className="p-0"><input id="PatientMed4" type="text" style={{ border: "none" }} className="form-control" /></td>
                                  <td className="p-0"><input id="PatientGen4" type="text" style={{ border: "none" }} className="form-control" /></td>
                                </tr>
                                <tr className="p-0">
                                  <td className='text-center p-0'>5.</td>
                                  <td className="p-0"><input id="PatientMed5" type="text" style={{ border: "none" }} className="form-control" /></td>
                                  <td className="p-0"><input id="PatientGen5" type="text" style={{ border: "none" }} className="form-control" /></td>
                                </tr>

                              </tbody></table>
                          </div>
                        </div>))}
                        <div className='d-flex justify-content-center m-2' >
                          <button type="button" onClick={addOtherPatientClick} className="btn btn-primary ">Add</button>
                        </div>
                      </div>
                    </div>



                  </div>
                  <button type="submit" className="btn btn-primary">Save</button>
                </form>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Surveyform
