import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { customFormDataPost } from '../../Helper/api-helper';
import swal from 'sweetalert';
import { apiBaseUrl } from "../../../global/credentials";


function IpdAdmission() {
    const { handleSubmit } = useForm();
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    const [formData, setFormData] = useState({
        DateOfAdmission: "",
        DateOfDischarge: "",
        NameOfAttendingPhysician: "",
        Service: "",
        WardRoom: "",
        FinalDiagnosisDetail1: "",
        FinalDiagnosisDetail2: "",
        FinalDiagnosisInternationalCode1: "",
        FinalDiagnosisInternationalCode2: "",
        PrinicipalDiagnosisFirstDetail1: "",
        PrinicipalDiagnosisFirstDetail2: "",
        PrinicipalDiagnosisFirstInternationalCode1: "",
        PrinicipalDiagnosisFirstInternationalCode2: "",
        OperativeProcedureDetail1: "",
        OperativeProcedureDetail2: "",
        OperativeprocedureInternationalCode1: "",
        OperativeProcedureInternationalCode2: "",
        AdmissionDiagnosisResult: "",
        AdmissionDiagnosisCured: "",
        AdmissionDiagnosisImproved: "",
        AdmissionDiagnosisUncharged: "",
        AdmissionDiagnosisLaMa: "",
        AdmissionDiagnosisDischarged: "",
        AdmissionDiagnosisExpired: "",
        SignatureOfDoctor: "",
        CauseOfDeath: "",
        DiagnosisAndTreatmentOf: "",
        RelationshipWithPatient: "",
        SignatureOfMedicalRecordTechnician: "",
        SignatureOfPatientRelative: "",
        TemperatureChart:"",

    });
    
    const [patientHistory, setPatientHistory] = useState([{}]);
    const addHistory = (e) => {
        setPatientHistory([...patientHistory, [{}]])
    }
    const [patientDrugTreatment, setPatientDrugTreatment] = useState([{}]);
    const addPatientDrugTreatment = (e) => {
        setPatientDrugTreatment([...patientDrugTreatment, [{}]])
    }
    const [patientProgressAndOrder, setPatientProgressAndOrder] = useState([{}]);
    const addPatientProgressAndOrder = (e) => {
        setPatientProgressAndOrder([...patientProgressAndOrder, [{}]])
    }
    const [patientDrugTreatmentorder, setPatientDrugTreatmentorder] = useState([{}]);
    const addPatientDrugTreatmentorder = (e) => {
        setPatientDrugTreatmentorder([...patientDrugTreatmentorder, [{}]])
    }
    const [nurseNotes, setNurseNotes] = useState([{}]);
    const addnurseNotes = (e) => {
        setNurseNotes([...nurseNotes, [{}]])
    }
    const [inputoutputrecord, setInputOutputRecord] = useState([{}]);
    const addInputOutputRecord = (e) => {
        setInputOutputRecord([...inputoutputrecord, [{}]])
    }
    
    const IpdSubmit = async () => {

        var data = new FormData();
        data.append('DateOfAdmission', formData.DateOfAdmission);
        data.append('DateOfDischarge', formData.DateOfDischarge);
        data.append('NameOfAttendingPhysician', formData.NameOfAttendingPhysician);
        data.append('Service', formData.Service);
        data.append('WardRoom', formData.WardRoom);
        data.append('FinalDiagnosisDetail1', formData.FinalDiagnosisDetail1);
        data.append('FinalDiagnosisDetail2', formData.FinalDiagnosisDetail2);
        data.append('FinalDiagnosisInternationalCode1', formData.FinalDiagnosisInternationalCode1);
        data.append('FinalDiagnosisInternationalCode2', formData.FinalDiagnosisInternationalCode2);
        data.append('PrinicipalDiagnosisFirstDetail1', formData.PrinicipalDiagnosisFirstDetail1);
        data.append('PrinicipalDiagnosisFirstDetail2', formData.PrinicipalDiagnosisFirstDetail2);
        data.append('PrinicipalDiagnosisFirstInternationalCode1', formData.PrinicipalDiagnosisFirstInternationalCode1);
        data.append('PrinicipalDiagnosisFirstInternationalCode2', formData.PrinicipalDiagnosisFirstInternationalCode2);
        data.append('OperativeProcedureDetail1', formData.OperativeProcedureDetail1);
        data.append('OperativeProcedureDetail2', formData.OperativeProcedureDetail2);
        data.append('OperativeprocedureInternationalCode1', formData.OperativeprocedureInternationalCode1);
        data.append('OperativeProcedureInternationalCode2', formData.OperativeProcedureInternationalCode2);
        data.append('AdmissionDiagnosisResult', formData.AdmissionDiagnosisResult);
        data.append('AdmissionDiagnosisCured', formData.AdmissionDiagnosisCured);
        data.append('AdmissionDiagnosisImproved', formData.AdmissionDiagnosisImproved);
        data.append('AdmissionDiagnosisUncharged', formData.AdmissionDiagnosisUncharged);
        data.append('AdmissionDiagnosisLaMa', formData.AdmissionDiagnosisLaMa);
        data.append('AdmissionDiagnosisDischarged', formData.AdmissionDiagnosisDischarged);
        data.append('AdmissionDiagnosisExpired', formData.AdmissionDiagnosisExpired);
        data.append('SignatureOfDoctor', formData.SignatureOfDoctor);
        data.append('CauseOfDeath', formData.CauseOfDeath);
        data.append('DiagnosisAndTreatmentOf', formData.DiagnosisAndTreatmentOf);
        data.append('RelationshipWithPatient', formData.RelationshipWithPatient);
        data.append('SignatureOfMedicalRecordTechnician', formData.SignatureOfMedicalRecordTechnician);
        data.append('SignatureOfPatientRelative', formData.SignatureOfPatientRelative);
        data.append('TemperatureChart', formData.TemperatureChart);
   

        var res = await customFormDataPost(`${apiBaseUrl}blood-bank/receivers/create/0`, data);
        if (res.status) {
            swal("Saved!", "Your Receiver form data has been Saved!", "success");

        }
        else {
            alert(res.messsage)
        }


    }
    return (
        <div>
            <div className="container">
                <div className="card shadow mb-4">
                    <div className="card-body p-4">
                        <div className="row mx-4 my-4">

                            <form id="receiverForm p-8" onSubmit={handleSubmit(IpdSubmit)}>
                                <h2 className='text-center'>ADMISSION AND DISCHARGE RECORD</h2>
                                <div className="form-row">
                                    <div className="form-group col-md-3">
                                        <label >Date of Admission</label>
                                        <input type="text" className="form-control"
                                            placeholder="mm/dd/yy"
                                            value={formData.DateOfAdmission}
                                            onChange={(event) =>
                                                setFormData({ ...formData, DateOfAdmission: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label >Time</label>
                                        <input  type="text" className="form-control"
                                            placeholder="hh:ss"
                                            value={formData.phone}
                                            onChange={(event) =>
                                                setFormData({ ...formData, phone: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label >Date of Discharge</label>
                                        <input  type="text" className="form-control"
                                            placeholder="Discharge"
                                            value={formData.DateOfDischarge}
                                            onChange={(event) =>
                                                setFormData({ ...formData, DateOfDischarge: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label >Time</label>
                                        <input type="text" className="form-control"
                                            placeholder="Time"
                                            value={formData.phone}
                                            onChange={(event) =>
                                                setFormData({ ...formData, phone: event.target.value })
                                            } />
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="form-group col-md-4">
                                        <label >Name of Attending Physician</label>
                                        <input  type="text" className="form-control"
                                            placeholder="Physician"
                                            value={formData.NameOfAttendingPhysician}
                                            onChange={(event) =>
                                                setFormData({ ...formData, NameOfAttendingPhysician: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label >Service </label>
                                        <input type="text" className="form-control"
                                            placeholder="service"
                                            value={formData.Service}
                                            onChange={(event) =>
                                                setFormData({ ...formData, Service: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label >Ward/Room</label>
                                        <input  type="text" className="form-control"
                                            placeholder="ward"
                                            value={formData.WardRoom}
                                            onChange={(event) =>
                                                setFormData({ ...formData, WardRoom: event.target.value })
                                            } />
                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col-md-12 my-4">
                                        <table id="PatientFamilyMembers" className="table table-bordered">
                                            <thead>
                                                <tr >
                                                    <th >Admission Diagnosis</th>
                                                    <th >Details.............</th>
                                                    <th >International Code</th>
                                                    
                                                </tr>
                                            </thead>
                                            <tbody>
                                               
                                                <tr className="p-0">
                                                    <td rowSpan="2" className="p-2">Final Diagnosis</td>
                                                    <td className="p-0"><input style={{ border: "none" }} type="text" className="form-control" /></td>
                                                    <td className="p-0"><input style={{ border: "none" }} type="text" className="form-control" /></td>
                                                </tr>
                                                <tr className="p-0">
                                                    <td className="p-0"><input style={{ border: "none" }} type="text" className="form-control" /></td>
                                                    <td className="p-0"><input style={{ border: "none" }} type="text" className="form-control" /></td>
                                                </tr>

                                                <tr className="p-0">
                                                    <td className="p-2" rowSpan="2">Write Prinicipal Diagnosis First</td>
                                                    <td className="p-0"><input style={{ border: "none" }} type="text" className="form-control" /></td>
                                                    <td className="p-0"><input style={{ border: "none" }} type="text" className="form-control" /></td>
                                                </tr>
                                                <tr className="p-0">
                                                    <td className="p-0"><input style={{ border: "none" }} type="text" className="form-control" /></td>
                                                    <td className="p-0"><input style={{ border: "none" }} type="text" className="form-control" /></td>
                                                </tr>
                                                <tr className="p-0">
                                                    <td className="p-2" rowSpan="2">Operative Procedure</td>
                                                    <td className="p-0"> <input style={{ border: "none" }} type="text" className="form-control" /></td>
                                                    <td className="p-0"><input style={{ border: "none" }} type="text" className="form-control" /></td>
                                                </tr>
                                                <tr className="p-0">
                                                    <td className="p-0"><input style={{ border: "none" }} type="text" className="form-control" /></td>
                                                    <td className="p-0"><input style={{ border: "none" }} type="text" className="form-control" /></td>
                                                </tr>   
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="form-group col-md-2">
                                        <label >Result</label>
                                        <input  type="text" className="form-control"
                                            placeholder="result"
                                            value={formData.AdmissionDiagnosisResult}
                                            onChange={(event) =>
                                                setFormData({ ...formData, AdmissionDiagnosisResult: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-2">
                                        <label >Cured</label>
                                        <input  type="text" className="form-control"
                                            placeholder="cured"
                                            value={formData.AdmissionDiagnosisCured}
                                            onChange={(event) =>
                                                setFormData({ ...formData, AdmissionDiagnosisCured: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-2">
                                        <label >Improved</label>
                                        <input  type="text" className="form-control"
                                            placeholder="improved"
                                            value={formData.AdmissionDiagnosisImproved}
                                            onChange={(event) =>
                                                setFormData({ ...formData, AdmissionDiagnosisImproved: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-2">
                                        <label >Uncharged</label>
                                        <input  type="text" className="form-control"
                                            placeholder="uncharged"
                                            value={formData.AdmissionDiagnosisUncharged}
                                            onChange={(event) =>
                                                setFormData({ ...formData, AdmissionDiagnosisUncharged: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-2">
                                        <label >L.A / M.A</label>
                                        <input  type="text" className="form-control"
                                            placeholder="L.A/M.A"
                                            value={formData.AdmissionDiagnosisLaMa}
                                            onChange={(event) =>
                                                setFormData({ ...formData, AdmissionDiagnosisLaMa: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-2">
                                        <label >Discharge</label>
                                        <input  type="text" className="form-control"
                                            placeholder="discharge"
                                            value={formData.AdmissionDiagnosisDischarged}
                                            onChange={(event) =>
                                                setFormData({ ...formData, AdmissionDiagnosisDischarged: event.target.value })
                                            } />
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="form-group col-md-4">
                                        <label >Expired</label>
                                        <input  type="text" className="form-control"
                                            placeholder="expired"
                                            value={formData.AdmissionDiagnosisExpired}
                                            onChange={(event) =>
                                                setFormData({ ...formData, AdmissionDiagnosisExpired: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label >Signature of Doctor</label>
                                        <input  type="file" className="form-control"
                                            placeholder="Sign"
                                            value={formData.SignatureOfDoctor}
                                            onChange={(event) =>
                                                setFormData({ ...formData, SignatureOfDoctor: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label >Cause of Death</label>
                                        <input  type="text" className="form-control"
                                            placeholder="cause"
                                            value={formData.CauseOfDeath}
                                            onChange={(event) =>
                                                setFormData({ ...formData, CauseOfDeath: event.target.value })
                                            } />
                                    </div>
                                   
                                </div>
                                
                                <h4 className="text-center mx-2 my-4">Authorisation For Operation & Treatment</h4>
                                <p>I hereby authorise the Medical,Nursing and Para-Medical, Staff of the 
                                    Hospital to examine, investigate,treat administer such drugs as may be
                                    necessary and to perform such operations under any enesthesia or othewise as 
                                    my be deemed necessary and/or advisable in the Diagnosis and treatment of 
                                    <input  type="text" className="form-control"
                                        placeholder="Patient Name"
                                        value={formData.DiagnosisAndTreatmentOf}
                                        onChange={(event) =>
                                            setFormData({ ...formData, DiagnosisAndTreatmentOf: event.target.value })
                                        } />
                                        who is my
                                        <input  type="text" className="form-control"
                                        placeholder="relationship"
                                        value={formData.RelationshipWithPatient}
                                        onChange={(event) =>
                                            setFormData({ ...formData, RelationshipWithPatient: event.target.value })
                                        } />
                                        ...:relationship:
                                        I undertake all the risk and absolve the Doctor,the medical and other staff 
                                        for all legal actions and consequences.All cash, Jewellery and valuable belongings 
                                        to the patient have been removed to place of saftey .I absolve the hospital to any
                                        responsible with regard to any loss.       
                                </p> 
                                <p>I am responsible  for payment of all dues with regards to the hospital.</p>
                                <p>I have read out and the explained the content of the above to the signatory 
                                    in the varnnculars.
                                </p>

                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label >Signature of Medical Record Technician</label>
                                        <input type="file" className="form-control"
                                            placeholder="Signature"
                                            value={formData.SignatureOfMedicalRecordTechnician}
                                            onChange={(event) =>
                                                setFormData({ ...formData, SignatureOfMedicalRecordTechnician: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label >Signature of PATIENT/RELATIVE</label>
                                        <input type="file" className="form-control"
                                            placeholder="Signature"
                                            value={formData.SignatureOfPatientRelative}
                                            onChange={(event) =>
                                                setFormData({ ...formData, SignatureOfPatientRelative: event.target.value })
                                            } />
                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col-md-12 my-4">
                                        <table id="PatientFamilyMembers" className="table table-bordered">
                                            <thead>
                                                <tr >
                                                    <th >History</th>
                                                    <th >Physical Examination</th>
                                               </tr>
                                            </thead>
                                            <tbody>
                                                      {patientHistory.map((member, index) => (
                                                    <tr key={index} className="p-0">
                                                        <td className="p-0"><input id="MemberName" type="text" style={{ border: "none" }} className="form-control" /></td>
                                                        <td className="p-0"><input id="MemberRelationToHead" style={{ border: "none" }} type="text" className="form-control" /></td>
                                                    </tr>))}
                                            </tbody>
                                           
                                        </table>
                                        <div className='d-flex justify-content-end m-2' >
                                            <button type="button" onClick={addHistory} className="btn btn-primary ">Add</button>
                                        </div>
                                    </div>
                                </div>
                                <h3 className='text-center'>DRUG TREATMENT AND OTHER ORDERS BY DOCTORS</h3>

                                <div className="row">
                                    <div className="col-md-12 my-4">
                                        <table id="PatientFamilyMembers" className="table table-bordered">
                                            <thead>
                                                <tr >
                                                    <th>Date</th>
                                                    <th>Form</th>
                                                    <th>Drug</th>
                                                    <th>Dose</th>
                                                    <th>Adms Time</th>
                                                    <th>Route</th>
                                                    <th>Spl. Inst</th>
                                                    <th>Dr. Sig</th>
                                                    <th>Date Discount</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {patientDrugTreatment.map((member, index) => (
                                                    <tr key={index} className="p-0">
                                                        <td className="p-0"><input id="MemberName" type="text" style={{ border: "none" }} className="form-control" /></td>
                                                        <td className="p-0"><input id="MemberName" type="text" style={{ border: "none" }} className="form-control" /></td>
                                                        <td className="p-0"><input id="MemberName" type="text" style={{ border: "none" }} className="form-control" /></td>
                                                        <td className="p-0"><input id="MemberName" type="text" style={{ border: "none" }} className="form-control" /></td>
                                                        <td className="p-0"><input id="MemberName" type="text" style={{ border: "none" }} className="form-control" /></td>
                                                        <td className="p-0"><input id="MemberName" type="text" style={{ border: "none" }} className="form-control" /></td>
                                                        <td className="p-0"><input id="MemberName" type="text" style={{ border: "none" }} className="form-control" /></td>
                                                        <td className="p-0"><input id="MemberName" type="text" style={{ border: "none" }} className="form-control" /></td>
                                                        <td className="p-0"><input id="MemberRelationToHead" style={{ border: "none" }} type="text" className="form-control" /></td>
                                                    </tr>))}
                                            </tbody>

                                        </table>
                                        <div className='d-flex justify-content-end m-2' >
                                            <button type="button" onClick={addPatientDrugTreatment} className="btn btn-primary ">Add</button>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12 my-4">
                                        <table id="PatientFamilyMembers" className="table table-bordered">
                                            <thead>
                                                <tr >
                                                    <th>Date</th>
                                                    <th>Treatment and other order</th>
                                                    <th>Dr. Sig</th>
                                                    <th>Date</th>
                                                    <th>Lab Investing order</th>
                                                    <th>Sent</th>
                                                    <th>Dr. Sig</th>


                                                </tr>
                                            </thead>
                                            <tbody>

                                                {patientDrugTreatmentorder.map((member, index) => (
                                                    <tr key={index} className="p-0">
                                                        <td className="p-0"><input id="MemberName" type="text" style={{ border: "none" }} className="form-control" /></td>
                                                        <td className="p-0"><input id="MemberName" type="text" style={{ border: "none" }} className="form-control" /></td>
                                                        <td className="p-0"><input id="MemberName" type="text" style={{ border: "none" }} className="form-control" /></td>
                                                        <td className="p-0"><input id="MemberName" type="text" style={{ border: "none" }} className="form-control" /></td>
                                                        <td className="p-0"><input id="MemberName" type="text" style={{ border: "none" }} className="form-control" /></td>
                                                        <td className="p-0"><input id="MemberName" type="text" style={{ border: "none" }} className="form-control" /></td>
                                                        <td className="p-0"><input id="MemberName" type="text" style={{ border: "none" }} className="form-control" /></td>
                                                    </tr>))}
                                            </tbody>

                                        </table>
                                        <div className='d-flex justify-content-end m-2' >
                                            <button type="button" onClick={addPatientDrugTreatmentorder} className="btn btn-primary ">Add</button>
                                        </div>
                                    </div>
                                </div>
                                <h3 className='text-center'>Physician Progress Note And Order Sheet</h3>
                                <div className="row">
                                    <div className="col-md-12 my-4">
                                        <table id="PatientFamilyMembers" className="table table-bordered">
                                            <thead>
                                                <tr >
                                                    <th>Date and time</th>
                                                    <th>Progress Note</th>
                                                    <th>Date and Time</th>
                                                    <th>Order</th>
                                             
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {patientProgressAndOrder.map((member, index) => (
                                                    <tr key={index} className="p-0">
                                                        <td className="p-0"><input id="MemberName" type="text" style={{ border: "none" }} className="form-control" /></td>
                                                        <td className="p-0"><input id="MemberName" type="text" style={{ border: "none" }} className="form-control" /></td>
                                                        <td className="p-0"><input id="MemberName" type="text" style={{ border: "none" }} className="form-control" /></td>
                                                        <td className="p-0"><input id="MemberName" type="text" style={{ border: "none" }} className="form-control" /></td>
                                                    </tr>))}
                                            </tbody>

                                        </table>
                                        <div className='d-flex justify-content-end m-2' >
                                            <button type="button" onClick={addPatientProgressAndOrder} className="btn btn-primary ">Add</button>
                                        </div>
                                    </div>
                                </div>

                                <h3 className='text-center'>NURSES NOTES</h3>
                                <div className="row">
                                    <div className="col-md-12 my-4">
                                        <table id="PatientFamilyMembers" className="table table-bordered">
                                            <thead>
                                                <tr >
                                                    <th>Date and time</th>
                                                    <th>Nurses Note</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {nurseNotes.map((member, index) => (
                                                    <tr key={index} className="p-0">
                                                        <td className="p-0"><input id="MemberName" type="text" style={{ border: "none" }} className="form-control" /></td>
                                                        <td className="p-0"><input id="MemberName" type="text" style={{ border: "none" }} className="form-control" /></td>
                                                        
                                                    </tr>))}
                                            </tbody>

                                        </table>
                                        <div className='d-flex justify-content-end m-2' >
                                            <button type="button" onClick={addnurseNotes} className="btn btn-primary ">Add</button>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <h3>Intake output record</h3>
                                    <div className="col-md-12 my-4" >
                                        <table id="Intakeouttake" className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th  colSpan={3} rowSpan={2}>Degree of consciousness</th>
                                                  <th colSpan={2} rowSpan={2}>Pupil R L</th>
                                                    <th className="rotate-90" rowSpan={3}>B.H.S</th>
                                                    <th className="rotate-90" rowSpan={3}>B.P</th>
                                                    <th className="rotate-90" rowSpan={3}>Respiration</th>
                                                    <th className="rotate-90" rowSpan={3}>Pulse</th>
                                                    <th className="rotate-90" rowSpan={3}>Temp</th>
                                                    <th className="rotate-90" rowSpan={3}>Time</th>
                                                    <th colSpan={8}>Fluid Balance Record</th>
                                                    <th colSpan={6}>Nursing </th>
                                                    <th className="rotate-90" rowSpan={3}>Remark</th>
                                                    <th className="rotate-90" rowSpan={3}>Signature</th>
                                                </tr>
                                                <tr>
                                                    <th colSpan={8}>Intake outtake</th>
                                                    <th colSpan={6}>Initial</th>
                                                </tr>
                                                <tr >
                                                    <th className="rotate-90">Normal&nbsp;Alert</th>
                                                    <th className="rotate-90">Serious</th>
                                                    <th className="rotate-90">Unconsciousness</th>
                                                    <th className="rotate-90">R R reading</th>
                                                    <th className="rotate-90">N R non radiation</th>
                                                    <th className="rotate-90"> I.v Fluid</th>
                                                    <th className="rotate-90">Oral</th>
                                                    <th className="rotate-90">Utnorma</th>
                                                    <th className="rotate-90">&nbsp;</th>
                                                    <th className="rotate-90">&nbsp;</th>
                                                    <th className="rotate-90">Urine</th>
                                                    <th className="rotate-90">Stool</th>
                                                    <th className="rotate-90">Vomit</th>
                                                    <th className="rotate-90">Drainage</th>
                                                    <th className="rotate-90">Mouth Care</th>
                                                    <th className="rotate-90">Back care</th>
                                                    <th className="rotate-90">Bath soak</th>
                                                    <th className="rotate-90">Parneal care</th>
                                                    <th className="rotate-90">&nbsp;</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {inputoutputrecord.map((member, index) => (
                                                    <tr key={index} className="p-0">
                                                        <td className="p-0"><input id="MemberName" type="text" style={{ border: "none" }} className="form-control" /></td>
                                                        <td className="p-0"><input id="MemberName" type="text" style={{ border: "none" }} className="form-control" /></td>
                                                        <td className="p-0"><input id="MemberName" type="text" style={{ border: "none" }} className="form-control" /></td>
                                                        <td className="p-0"><input id="MemberName" type="text" style={{ border: "none" }} className="form-control" /></td>
                                                        <td className="p-0"><input id="MemberName" type="text" style={{ border: "none" }} className="form-control" /></td>
                                                        <td className="p-0"><input id="MemberName" type="text" style={{ border: "none" }} className="form-control" /></td>
                                                        <td className="p-0"><input id="MemberName" type="text" style={{ border: "none" }} className="form-control" /></td>
                                                        <td className="p-0"><input id="MemberName" type="text" style={{ border: "none" }} className="form-control" /></td>
                                                        <td className="p-0"><input id="MemberName" type="text" style={{ border: "none" }} className="form-control" /></td>
                                                        <td className="p-0"><input id="MemberName" type="text" style={{ border: "none" }} className="form-control" /></td>
                                                        <td className="p-0"><input id="MemberName" type="text" style={{ border: "none" }} className="form-control" /></td>
                                                        <td className="p-0"><input id="MemberName" type="text" style={{ border: "none" }} className="form-control" /></td>
                                                        <td className="p-0"><input id="MemberName" type="text" style={{ border: "none" }} className="form-control" /></td>
                                                        <td className="p-0"><input id="MemberName" type="text" style={{ border: "none" }} className="form-control" /></td>
                                                        <td className="p-0"><input id="MemberName" type="text" style={{ border: "none" }} className="form-control" /></td>
                                                        <td className="p-0"><input id="MemberName" type="text" style={{ border: "none" }} className="form-control" /></td>
                                                        <td className="p-0"><input id="MemberName" type="text" style={{ border: "none" }} className="form-control" /></td>
                                                        <td className="p-0"><input id="MemberName" type="text" style={{ border: "none" }} className="form-control" /></td>
                                                        <td className="p-0"><input id="MemberName" type="text" style={{ border: "none" }} className="form-control" /></td>
                                                        <td className="p-0"><input id="MemberName" type="text" style={{ border: "none" }} className="form-control" /></td>
                                                        <td className="p-0"><input id="MemberName" type="text" style={{ border: "none" }} className="form-control" /></td>
                                                        <td className="p-0"><input id="MemberName" type="text" style={{ border: "none" }} className="form-control" /></td>
                                                        <td className="p-0"><input id="MemberName" type="text" style={{ border: "none" }} className="form-control" /></td>
                                                        <td className="p-0"><input id="MemberName" type="text" style={{ border: "none" }} className="form-control" /></td>
                                                        <td className="p-0"><input id="MemberName" type="text" style={{ border: "none" }} className="form-control" /></td>
                                                        <td className="p-0"><input id="MemberName" type="text" style={{ border: "none" }} className="form-control" /></td>
                                                        <td className="p-0"><input id="MemberName" type="text" style={{ border: "none" }} className="form-control" /></td>

                                                        

                                                    </tr>))}
                                            </tbody>

                                        </table>
                                        <div className='d-flex justify-content-end m-2' >
                                            <button type="button" onClick={addInputOutputRecord} className="btn btn-primary ">Add</button>
                                        </div>
                                    </div>
                                </div>


                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="inputPassword4">Temperature Chart</label>
                                        <input  type="file" className="form-control"
                                            placeholder="choose"
                                            value={formData.TemperatureChart}
                                            onChange={(event) =>
                                                setFormData({ ...formData, TemperatureChart: event.target.value })
                                            } />
                                    </div>
                                 
                                </div>

                                <div className="d-flex justify-content-center">
                                    <button type="button" id="saveReceiver" className="btn btn-primary">Save</button>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IpdAdmission

