import React, { useState, useEffect } from 'react';
import { customFetch } from '../../Helper/api-helper';
import { Link } from "react-router-dom";
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import { apiBaseUrl } from '../../../global/credentials'

function DonerList() {
  const [detail, setDetail] = useState([]);
  useEffect(() => {
    handle()
  }, []);
  const handle = async () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    var res = await customFetch(`${apiBaseUrl}blood-bank/doners`);

    setDetail(res);
    setTimeout(function () {
      $('#donertable').DataTable(
      );
    }, 100);

  }

  return (
    <div>
      <div className="container-fluid">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to="/blood-bank/">Home</Link></li>
            <li className="breadcrumb-item active" aria-current="page"><Link to="/blood-bank/donerlist">Doner</Link></li>
          </ol>
        </nav>
      </div>
      <div className="container-fluid">
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 style={{ float: "left" }} className="m-0 font-weight-bold text-primary">Donor Table</h6>
            <div style={{ float: "right" }} className='mb-4'>
              <Link className="btn btn-primary btn-sm" to="/blood-bank/donerform">Add New</Link>
            </div>
          </div>

          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-bordered" id="donertable" width="100%" cellSpacing={0}>
                <thead>
                  <tr>
                    <td>S. No</td>
                    <td>Unit No</td>
                    <td>Donor Name</td>
                    <td>Age</td>
                    <td>Gender</td>
                    <td>Donation Date</td>
                    <td>Status</td>
                    <td>Action</td>
                  </tr>
                </thead>
                <tbody>
                  {detail.map((donar, index) => (
                    <tr key={donar.id}>
                      <td>{index + 1}</td>
                      <td>{donar.unitNo}</td>
                      <td>{donar.name}</td>
                      <td>{donar.age}</td>
                      <td>{donar.gender}</td>
                      <td>{donar.donationDate}</td>
                      <td>{donar.status}</td>
                      <td>
                        <button className="btn btn-primary btn-sm mx-1" data-toggle="tooltip" title="Test passed"><i className="fa fa-flask" aria-hidden="true"></i></button>
                        <button className="btn btn-success btn-sm mx-1" data-toggle="tooltip" title="Blood collected"><i className="fa fa-check-circle" aria-hidden="true"></i> </button>
                        <span asp-route-id={donar.id} className="btn btn-default btn-sm mx-1" data-toggle="tooltip" title="Doner timeline"><i className="fa fa-clock" aria-hidden="true"></i> </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default DonerList
