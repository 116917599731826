import React from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";



function Donerpg1({register,errors, formData, setFormData }) {
 
  return (
    <>
    <div className="container">
  <fieldset>
 
  <input type="hidden" asp-for="Id" />
  <div className="form-card">

    <div className="form-row">
      
      <div className="form-group col-md-6">
        <label htmlFor="inputEmail4">Blood Unit No</label>
        <input asp-for="UnitNo" type="text" className="form-control" 
        placeholder="Blood Unit No"
        value={formData.unitno}
        onChange={(event) =>
          setFormData({ ...formData, unitno: event.target.value })
        } />
      
        </div>
      <div className="form-group col-md-6">
        <label htmlFor="inputPassword4">Date</label>
        <DatePicker  asp-for="DonationDate" type="text" className="form-control datepicker"
         placeholder="Date" 
         selected={formData.date}  onChange={(date) =>
          setFormData({ ...formData, date:date  })
        }
         />
         
      </div>
    </div>
    <div className="form-row">
      <div className="form-group col-md-6">
        <label htmlFor="inputEmail4">Doner name</label>
        <input  type="text" className="form-control"
         placeholder="Doner name"
         {...register("name", { required: true })}
         value={formData.donername}
         onChange={(event) =>
           setFormData({ ...formData, donername: event.target.value })
         } />
           {errors.name && errors.name.type === 'required' && "Name is required"}  
      </div>
      <div className="form-group col-md-6">
        <label htmlFor="inputPassword4">Fathers name</label>
        <input asp-for="FathersName" type="text" className="form-control"
        value={formData.fathersname}
        onChange={(event) =>
          setFormData({ ...formData, fathersname: event.target.value })
        } placeholder="Fathers name" />
      </div>
    </div>
    <div className="form-row">
      <div className="form-group col-md-6">
        <label htmlFor="inputEmail4">Age</label>
        <input asp-for="Age" type="number" className="form-control"
         placeholder="Age"
         {...register("Age", { required: true })}
         value={formData.age}
         onChange={(event) =>
           setFormData({ ...formData, age: event.target.value })
         } />
           {errors.Age && errors.Age.type === 'required' && "Age is required"}  
       
      </div>
      <div className="form-group col-md-6">
        <label htmlFor="inputPassword4">Gender</label>
        <input asp-for="Gender" type="text" className="form-control" 
        placeholder="Gender"
        value={formData.gender}
         onChange={(event) =>
           setFormData({ ...formData, gender: event.target.value })
         } />
      </div>
    </div>
    <div className="form-row">
      <div className="form-group col-md-6">
        <label htmlFor="inputPassword4">Well being</label>
        <input asp-for="WellBeing" type="text" className="form-control"
         placeholder="Well being"
         value={formData.wellbeing}
         onChange={(event) =>
           setFormData({ ...formData, wellbeing: event.target.value })
         } />
      </div>
      <div className="form-group col-md-6">
        <label htmlFor="inputEmail4">Phone</label>
        <input asp-for="Phone" type="text" className="form-control"
         placeholder="Phone"
         value={formData.phone}
         onChange={(event) =>
           setFormData({ ...formData, phone: event.target.value })
         } />
      </div>
    </div>
    <div className="form-row">
      <div className="form-group col-md-12">
        <label htmlFor="inputEmail4">Occupation and address</label>
        <textarea asp-for="OccupationAndAddress" type="number" className="form-control" 
        placeholder="Occupation and address" 
        value={formData.occandaddress}
         onChange={(event) =>
           setFormData({ ...formData, occandaddress: event.target.value })
         } />
      </div>
    </div>
  </div>
 
</fieldset>

    </div>
    </>
  )
}

export default Donerpg1;