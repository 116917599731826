import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { customFormDataPost } from '../../Helper/api-helper';
import swal from 'sweetalert';
import { apiBaseUrl } from "../../../global/credentials";

function Ipdform() {
    const { handleSubmit } = useForm();
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    const [formData, setFormData] = useState({
        Nationality:"",
        MartialStatus :"",
        Religion:"",
        Occupation :"",
        Address :"",
        HouseNo :"",
        Street :"",
        Mohalla :"",
        CityVillage :"",
        PostOffice :"",
        Tehsil :"",
        District :"",
        State :"",
        TelephoneNumber :"",
        MobileNumber :"",
        RelativeName :"",
        Relationship :"",
        RelativeOccupation : "",
        RelativeAddress :"",
        RelativeTelephone :"",
        RelativeMobileNumber:"",
        SignatureOfRelativeOrPatient :"",
        SignatureOfRegistrationClerk :"",
        FathersFatherName :"",
        FathersFatherRelationship:"",
        });


        const opdSubmit = async () => {

        var data = new FormData();
        data.append('Nationality', formData.Nationality);
        data.append('MartialStatus', formData.MartialStatus);
        data.append('DonationDate', formData.date);
        data.append('Religion', formData.Religion);
        data.append('Occupation', formData.Occupation);
        data.append('Address', formData.Address);
        data.append('HouseNo', formData.HouseNo);
        data.append('Street', formData.Street);
        data.append('Mohalla', formData.Mohalla);
        data.append('CityVillage', formData.CityVillage);
        data.append('PostOffice', formData.PostOffice);
        data.append('Tehsil', formData.Tehsil);
        data.append('District', formData.District);
        data.append('State ', formData.State);
        data.append('TelephoneNumber', formData.TelephoneNumber);
        data.append('MobileNumber', formData.MobileNumber); 
        data.append('RelativeName', formData.RelativeName);
        data.append('Relationship', formData.Relationship);
        data.append('RelativeOccupation', formData.RelativeOccupation);
        data.append('RelativeAddress', formData.RelativeAddress);
        data.append('RelativeTelephone', formData.RelativeTelephone);
        data.append('RelativeMobileNumber', formData.RelativeMobileNumber);
        data.append('SignatureOfRelativeOrPatient', formData.SignatureOfRelativeOrPatient);
        data.append('SignatureOfRegistrationClerk', formData.SignatureOfRegistrationClerk);
        data.append('FathersFatherName', formData.FathersFatherName);
        data.append('FathersFatherRelationship', formData.FathersFatherRelationship);
        

        var res = await customFormDataPost(`${apiBaseUrl}opd/update-ipd-patient`, data);
        if (res.status) {
            swal("Saved!", "Your Receiver form data has been Saved!", "success");

        }
        else {
            alert(res.messsage)
        }


    }
    return (
        <div>
            <div className="container">
                <div className="card shadow mb-4">

                    <div className="card-body p-4">

                        <div className="row mx-4 my-4">

                            <form id="receiverForm p-8"

                                onSubmit={handleSubmit(opdSubmit)}>
                                <h2 className='text-center'>IPD FORM</h2>


                                <div className="form-row">
                                    <div className="form-group col-md-4">
                                        <label >Nationality</label>
                                        <input type="text" className="form-control"
                                            placeholder="Nationality"
                                            value={formData.Nationality}
                                            onChange={(event) =>
                                                setFormData({ ...formData, Nationality: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label>Martial Status</label>
                                        <input  type="text" className="form-control"
                                            placeholder="Married/Single/Widow"
                                            value={formData.MartialStatus}
                                            onChange={(event) =>
                                                setFormData({ ...formData, MartialStatus: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label >Religion</label>
                                        <input type="text" className="form-control"
                                            placeholder="religion"
                                            value={formData.Religion}
                                            onChange={(event) =>
                                                setFormData({ ...formData, pReligion: event.target.value })
                                            } />
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="form-group col-md-4">
                                        <label >Occupation</label>
                                        <input type="text" className="form-control"
                                            placeholder="occupation"
                                            value={formData.Occupation}
                                            onChange={(event) =>
                                                setFormData({ ...formData, Occupation: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-8">
                                        <label >Address</label>
                                        <input  type="text" className="form-control"
                                            placeholder="address"
                                            value={formData.Address}
                                            onChange={(event) =>
                                                setFormData({ ...formData, Address: event.target.value })
                                            } />
                                    </div>
                                    
                                </div>
   
                                <div className="form-row">
                                    <div className="form-group col-md-3">
                                        <label >House No.</label>
                                        <input type="text" className="form-control"
                                            placeholder="H.No."
                                            value={formData.HouseNo}
                                            onChange={(event) =>
                                                setFormData({ ...formData, HouseNo: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label >Street</label>
                                        <input  type="text" className="form-control"
                                            placeholder="street"
                                            value={formData.Street}
                                            onChange={(event) =>
                                                setFormData({ ...formData, Street: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label >Mohalla</label>
                                        <input  type="text" className="form-control"
                                            placeholder="mohalla"
                                            value={formData.Mohalla}
                                            onChange={(event) =>
                                                setFormData({ ...formData, Mohalla: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label >City/Village</label>
                                        <input  type="text" className="form-control"
                                            placeholder="city/village"
                                            value={formData.CityVillage}
                                            onChange={(event) =>
                                                setFormData({ ...formData, CityVillage: event.target.value })
                                            } />
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="form-group col-md-3">
                                        <label >Post Office</label>
                                        <input  type="text" className="form-control"
                                            placeholder="post office"
                                            value={formData.PostOffice}
                                            onChange={(event) =>
                                                setFormData({ ...formData, PostOffice: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label >Tehsil</label>
                                        <input type="text" className="form-control"
                                            placeholder="tehsil"
                                            value={formData.Tehsil}
                                            onChange={(event) =>
                                                setFormData({ ...formData, Tehsil: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label>District</label>
                                        <input  type="text" className="form-control"
                                            placeholder="district"
                                            value={formData.District}
                                            onChange={(event) =>
                                                setFormData({ ...formData, District: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label >State</label>
                                        <input type="text" className="form-control"
                                            placeholder="state"
                                            value={formData.State}
                                            onChange={(event) =>
                                                setFormData({ ...formData, State: event.target.value })
                                            } />
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label >Telephone Number</label>
                                        <input  type="text" className="form-control"
                                            placeholder="01871-"
                                            value={formData.TelephoneNumber}
                                            onChange={(event) =>
                                                setFormData({ ...formData, TelephoneNumber: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label >Mobile Number</label>
                                        <input  type="text" className="form-control"
                                            placeholder="+91-XXXXXXXXXXX"
                                            value={formData.MobileNumber}
                                            onChange={(event) =>
                                                setFormData({ ...formData, MobileNumber: event.target.value })
                                            } />
                                    </div>
                                    
                                </div>

                                <h3 className="text-center mx-2 my-4">NEAREST RELATIVES/GUARDIAN OR INFORMANT</h3>

                                <div className="form-row">
                                    <div className="form-group col-md-4">
                                        <label >Name</label>
                                        <input  type="text" className="form-control"
                                            placeholder="Patient Name"
                                            value={formData.RelativeName}
                                            onChange={(event) =>
                                                setFormData({ ...formData, RelativeName: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label >Relationship</label>
                                        <input  type="text" className="form-control"
                                            placeholder="relation"
                                            value={formData.Relationship}
                                            onChange={(event) =>
                                                setFormData({ ...formData, phone: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label>Occupation</label>
                                        <input type="text" className="form-control"
                                            placeholder="occupation"
                                            value={formData.RelativeOccupation}
                                            onChange={(event) =>
                                                setFormData({ ...formData, RelativeOccupation: event.target.value })
                                            } />
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="form-group col-md-4">
                                        <label >Address</label>
                                        <input type="text" className="form-control"
                                            placeholder="Address"
                                            value={formData.RelativeAddress}
                                            onChange={(event) =>
                                                setFormData({ ...formData, RelativeAddress: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label >Telephone</label>
                                        <input type="text" className="form-control"
                                            placeholder="Contact"
                                            value={formData.RelativeTelephone}
                                            onChange={(event) =>
                                                setFormData({ ...formData, RelativeTelephone: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label >Mobile Number</label>
                                        <input type="text" className="form-control"
                                            placeholder="+91-XXXXXXXXXX"
                                            value={formData.RelativeMobileNumber}
                                            onChange={(event) =>
                                                setFormData({ ...formData, RelativeMobileNumber: event.target.value })
                                            } />
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label >Signature of Relative or Patient </label>
                                        <input  type="file" className="form-control"
                                            placeholder="Sign"
                                            value={formData.SignatureOfRelativeOrPatient}
                                            onChange={(event) =>
                                                setFormData({ ...formData, SignatureOfRelativeOrPatient: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>Signature of Registration Clerk</label>
                                        <input  type="file" className="form-control"
                                            placeholder="Sign"
                                            value={formData.SignatureOfRegistrationClerk}
                                            onChange={(event) =>
                                                setFormData({ ...formData, SignatureOfRegistrationClerk: event.target.value })
                                            } />
                                    </div>
                                </div>

                                <h3 className="text-center mx-2 my-4">APPLICABLE IN OBSTETRICS GYNECOLOGY</h3>

                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label >Father's Father Name </label>
                                        <input  type="text" className="form-control"
                                            placeholder="Grandfather"
                                            value={formData.FathersFatherName}
                                            onChange={(event) =>
                                                setFormData({ ...formData, FathersFatherName: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label >Relationship</label>
                                        <input  type="text" className="form-control"
                                            placeholder="relation"
                                            value={formData.FathersFatherRelationship}
                                            onChange={(event) =>
                                                setFormData({ ...formData, FathersFatherRelationship: event.target.value })
                                            } />
                                    </div>
                                </div>

                               



                                <div className="d-flex justify-content-center">
                                    <button type="button" id="saveReceiver" className="btn btn-primary">Save</button>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Ipdform

