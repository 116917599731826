import React, {useState, useEffect} from "react";
import {useForm} from "react-hook-form";
import {customFormDataPost} from '../../Helper/api-helper';
import swal from 'sweetalert';
import {apiBaseUrl} from "../../../global/credentials";


function IpdAnaesthesia() {
    const {handleSubmit} = useForm();
    useEffect(() => {
              window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

          const [formData, setFormData] = useState({
              
              WardNo: "",
              Diagnosis: "",
              PhysicalState: "",
              OperationPerformed: "",
              Surgeon: "",
              DateOfOperation: "",
              SignOfAnaesthetist: "",
              Premedication: "",
              TooDepressed: "",
              WormOff: "",
              Hypertensive: "",
              Excitement: "",
              Vomiting: "",
              Larynogospasm: "",
              Cynosis: "",
              GeneraAnaesthesial: "",
              SpinaAnaesthesial: "",
              LocalAnaesthesial: "",
              SedativeAnaesthesial: "",
              BlockAnaesthesial: "",
              OtherAnaesthesial: "",
              Drugs: "",
              RemarkAnaesthesial: "",
              RefexesBpPulse: "",
              Weight: "",
              Hb: "",
              Urine: "",
              Bp: "",
              Pulse: "",
              Stetoids: "",
              Insulin: "",
              HypotensiveDrugs: "",
              Opium: "",
              Dentures: "",
              PreviousAnthExp: "",
              DifficultiesAnth: "",
              DetailsAnth: "",
              RemarkAnth: "",
              DateAnth: "",
              PreOperativeNote: "",
              PreOperativeFollow: "",
              

    });

    const IpdSubmit = async () => {

        var data = new FormData();
        data.append('WardNo', formData.WardNo);
        data.append('Diagnosis', formData.Diagnosis);
        data.append('PhysicalState', formData.PhysicalState);
        data.append('OperationPerformed', formData.OperationPerformed);
        data.append('Surgeon', formData.Surgeon);
        data.append('DateOfOperation', formData.DateOfOperation);
        data.append('SignOfAnaesthetist', formData.SignOfAnaesthetist);
        data.append('Premedication', formData.Premedication);
        data.append('TooDepressed', formData.TooDepressed);
        data.append('WormOff', formData.WormOff);
        data.append('Hypertensive', formData.Hypertensive);
        data.append('Excitement', formData.Excitement);
        data.append('Vomiting', formData.Vomiting);
        data.append('Larynogospasm', formData.Larynogospasm);
        data.append('Cynosis', formData.Cynosis);
        data.append('GeneraAnaesthesial', formData.GeneraAnaesthesial);
        data.append('SpinaAnaesthesial', formData.SpinaAnaesthesial);
        data.append('LocalAnaesthesial', formData.LocalAnaesthesial);
        data.append('SedativeAnaesthesial', formData.SedativeAnaesthesial);
        data.append('BlockAnaesthesial', formData.BlockAnaesthesial);
        data.append('OtherAnaesthesial', formData.OtherAnaesthesial);
        data.append('Drugs', formData.Drugs);
        data.append('RemarkAnaesthesial', formData.RemarkAnaesthesial);
        data.append('RefexesBpPulse', formData.RefexesBpPulse);
        data.append('Weight', formData.Weight);
        data.append('Hb', formData.Hb);
        data.append('Urine', formData.Urine);
        data.append('Bp', formData.Bp);
        data.append('Pulse', formData.Pulse);
        data.append('Stetoids', formData.Stetoids);
        data.append('Insulin', formData.Insulin);
        data.append('HypotensiveDrugs', formData.HypotensiveDrugs);
        data.append('Opium', formData.Opium);
        data.append('Dentures', formData.Dentures);
        data.append('PreviousAnthExp', formData.PreviousAnthExp);
        data.append('DifficultiesAnth', formData.DifficultiesAnth);
        data.append('DetailsAnth', formData.DetailsAnth);
        data.append('RemarkAnth', formData.RemarkAnth);
        data.append('DateAnth', formData.DateAnth);
        data.append('PreOperativeNote', formData.PreOperativeNote);
        data.append('PreOperativeFollow', formData.PreOperativeFollow);
    
          var res = await customFormDataPost(`${apiBaseUrl}blood-bank/receivers/create/0`, data);
          if (res.status) {
              swal("Saved!", "Your Receiver form data has been Saved!", "success");

        }
          else {
              alert(res.messsage)
          }


    }
          return (
          <div>
              <div className="container">
                  <div className="card shadow mb-4">
                  <div className="card-body p-4">
                 

         <h2 className='text-center'>Anethesia Record</h2>
         <div className="row mx-4 my-4">
        <form id="receiverForm p-4" onSubmit={handleSubmit(IpdSubmit)}>
                        <div className="form-row">
                            <div className="form-group col-md-4">
                                     <label >Unit NO.</label>
                                     <input type="text" className="form-control"
                                     placeholder="unit no."
                                     value={formData.wellbeing}
                                     onChange={(event) =>
                                     setFormData({ ...formData, wellbeing: event.target.value })
                                     } />
                          </div>
                                      <div className="form-group col-md-4">
                                          <label >Ward NO.</label>
                                          <input  type="text" className="form-control"
                                              placeholder="ward no."
                                              value={formData.WardNo}
                                              onChange={(event) =>
                                                  setFormData({ ...formData, phone: event.target.value })
                                              } />
                                      </div>
                                      <div className="form-group col-md-4">
                                          <label >Charges</label>
                                          <input type="text" className="form-control"
                                              placeholder="Rs"
                                              value={formData.charges}
                                              onChange={(event) =>
                                                  setFormData({ ...formData, phone: event.target.value })
                                              } />
                                      </div>
                                  </div>

                                  <div className="form-row">
                                      <div className="form-group col-md-4">
                                          <label >Diagnosis</label>
                                          <input  type="text" className="form-control"
                                              placeholder="diagnosis"
                                              value={formData.Diagnosis}
                                              onChange={(event) =>
                                                  setFormData({ ...formData, Diagnosis: event.target.value })
                                              } />
                                      </div>
                                      <div className="form-group col-md-4">
                                          <label >Physical State</label>
                                          <input  type="text" className="form-control"
                                              placeholder="Good/Fair/Poor/Emergency/Prev Operation"
                                              value={formData.PhysicalState}
                                              onChange={(event) =>
                                                  setFormData({ ...formData, PhysicalState: event.target.value })
                                              } />
                                      </div>
                                          <div className="form-group col-md-4">
                                              <label >Operation Performed</label>
                                              <input type="text" className="form-control"
                                                  placeholder="operation performed"
                                                  value={formData.OperationPerformed}
                                                  onChange={(event) =>
                                                      setFormData({ ...formData, OperationPerformed: event.target.value })
                                                  } />
                                          </div>

                                  </div>

                                  <div className="form-row">
                                      <div className="form-group col-md-4">
                                          <label >Surgeon</label>
                                          <input type="text" className="form-control"
                                              placeholder="Phone"
                                              value={formData.Surgeon}
                                              onChange={(event) =>
                                                  setFormData({ ...formData, Surgeon: event.target.value })
                                              } />
                                      </div>
                                      <div className="form-group col-md-4">
                                          <label >Date of Operation</label>
                                          <input type="text" className="form-control"
                                              placeholder="Date"
                                              value={formData.DateOfOperation}
                                              onChange={(event) =>
                                                  setFormData({ ...formData, DateOfOperation: event.target.value })
                                              } />
                                      </div>
                                      <div className="form-group col-md-4">
                                          <label>Sign of Anaesthetist</label>
                                          <input type="text" className="form-control"
                                              placeholder="Phone"
                                              value={formData.SignOfAnaesthetist}
                                              onChange={(event) =>
                                                  setFormData({ ...formData, SignOfAnaesthetist: event.target.value })
                                              } />
                                      </div>
                                  </div>

                                  <div className="form-row">
                                      <div className="form-group col-md-6">
                                          <label>Premedication</label>
                                          <input type="text" className="form-control"
                                              placeholder="Pre-medication"
                                              value={formData.Premedication}
                                              onChange={(event) =>
                                                  setFormData({ ...formData, Premedication: event.target.value })
                                              } />
                                      </div>
                                      <div className="form-group col-md-2">
                                          <label>Too Depressed</label>
                                          <input type="text" className="form-control"
                                              placeholder="Level"
                                              value={formData.TooDepressed}
                                              onChange={(event) =>
                                                  setFormData({ ...formData, TooDepressed: event.target.value })
                                              } />
                                      </div>
                                      <div className="form-group col-md-2">
                                          <label >Worm Off</label>
                                          <input type="text" className="form-control"
                                              placeholder="Phone"
                                              value={formData.WormOff}
                                              onChange={(event) =>
                                                  setFormData({ ...formData, WormOff: event.target.value })
                                              } />
                                      </div>
                                      <div className="form-group col-md-2">
                                          <label >Hypertensive</label>
                                          <input  type="text" className="form-control"
                                              placeholder="level"
                                              value={formData.Hypertensive}
                                              onChange={(event) =>
                                                  setFormData({ ...formData, Hypertensive: event.target.value })
                                              } />
                                      </div>
                                  </div>

                                  <div className="form-row">
                                      
                                          <h4 className="col-md-2">INDUPTION</h4>
                                      <div className="form-group col-md-3">
                                          <label >Excitement</label>
                                          <input  type="text" className="form-control"
                                              placeholder="level"
                                              value={formData.Excitement}
                                              onChange={(event) =>
                                                  setFormData({ ...formData, Excitement: event.target.value })
                                              } />
                                      </div>
                                          <div className="form-group col-md-3">
                                              <label >Vomiting</label>
                                              <input  type="text" className="form-control"
                                                  placeholder="vomit"
                                                  value={formData.Vomiting}
                                                  onChange={(event) =>
                                                      setFormData({ ...formData, Vomiting: event.target.value })
                                                  } />
                                          </div>
                                          <div className="form-group col-md-2">
                                              <label >Larynogospasm</label>
                                              <input  type="text" className="form-control"
                                                  placeholder="larynogospasm"
                                                  value={formData.Larynogospasm}
                                                  onChange={(event) =>
                                                      setFormData({ ...formData, Larynogospasm: event.target.value })
                                                  } />
                                          </div>
                                          <div className="form-group col-md-2">
                                              <label >Cynosis</label>
                                              <input  type="text" className="form-control"
                                                  placeholder="cynosis"
                                                  value={formData.Cynosis}
                                                  onChange={(event) =>
                                                      setFormData({ ...formData, Cynosis: event.target.value })
                                                  } />
                                          </div>

                                  </div>

                                  <h4 className="text-center mx-2 my-4">Type Of Anaesthesia</h4>

                                  <div className="form-row">
                                          <div  className="col-md-2 mx-0 ">
                                           <span className='title mx-2'>General</span>
                                              <label className='switch'>
                                                  <input value="General" type="checkbox" />
                                                  <span className='slider round'></span>
                                              </label>
                                          </div>
                                          <div className="col-md-2 mx-0 ">
                                              <span className='title mx-2'>Spinal</span>
                                              <label className='switch'>
                                                  <input value="General" type="checkbox" />
                                                  <span className='slider round'></span>
                                              </label>
                                          </div>
                                          <div className="col-md-2 mx-0 ">
                                              <span className='title mx-2'>Local</span>
                                              <label className='switch'>
                                                  <input value="General" type="checkbox" />
                                                  <span className='slider round'></span>
                                              </label>
                                          </div>
                                          <div className="col-md-2 mx-0 ">
                                              <span className='title mx-2'>Sedative</span>
                                              <label className='switch'>
                                                  <input value="General" type="checkbox" />
                                                  <span className='slider round'></span>
                                              </label>
                                          </div>
                                          <div className="col-md-2 mx-0 ">
                                              <span className='title mx-2'>Block</span>
                                              <label className='switch'>
                                                  <input value="General" type="checkbox" />
                                                  <span className='slider round'></span>
                                              </label>
                                          </div>
                                          <div className="col-md-2 mx-0 ">
                                              <span className='title mx-2'>Other</span>
                                              <label className='switch'>
                                                  <input value="General" type="checkbox" />
                                                  <span className='slider round'></span>
                                              </label>
                                          </div>
                                  </div>

                                  <div className="form-row">
                                      <div className="form-group col-md-6">
                                          <label >Drugs</label>
                                          <input  type="text" className="form-control"
                                              placeholder="drugs"
                                              value={formData.Drugs}
                                              onChange={(event) =>
                                                  setFormData({ ...formData, Drugs: event.target.value })
                                              } />
                                      </div>
                                      <div className="form-group col-md-3">
                                          <label >Remark</label>
                                          <input type="text" className="form-control"
                                              placeholder="remark"
                                              value={formData.RemarkAnth}
                                              onChange={(event) =>
                                                  setFormData({ ...formData, RemarkAnth: event.target.value })
                                              } />
                                      </div>
                                          <div className="form-group col-md-3">
                                              <label>Refexes/B.P/Pulse</label>
                                              <input type="text" className="form-control"
                                                  placeholder="Reflexive/B.P/Pulse"
                                                  value={formData.RefexesBpPulse}
                                                  onChange={(event) =>
                                                      setFormData({ ...formData, RefexesBpPulse: event.target.value })
                                                  } />
                                          </div>
                                      
                                  </div>

                                  

                                  <h3 className="text-center mx-2 my-4">Preoperative Condition</h3>

                                  <div className="form-row">
                                      <div className="form-group col-md-2">
                                          <label > Weight                                          </label>
                                          <input  type="text" className="form-control"
                                              placeholder="Kg"
                                              value={formData.Weight}
                                              onChange={(event) =>
                                                  setFormData({ ...formData, Weight: event.target.value })
                                              } />
                                      </div>
                                      <div className="form-group col-md-2">
                                          <label >HB</label>
                                          <input  type="text" className="form-control"
                                                  placeholder="g/dl"
                                              value={formData.Hb}
                                              onChange={(event) =>
                                                  setFormData({ ...formData, Hb: event.target.value })
                                              } />
                                      </div>
                                          <div className="form-group col-md-2">
                                              <label>Urine</label>
                                              <input type="text" className="form-control"
                                                  placeholder="(mL)"
                                                  value={formData.Urine}
                                                  onChange={(event) =>
                                                      setFormData({ ...formData, Urine: event.target.value })
                                                  } />
                                          </div>
                                          <div className="form-group col-md-2">
                                              <label >BP</label>
                                              <input  type="text" className="form-control"
                                                  placeholder="(mmHg)"
                                                  value={formData.Bp}
                                                  onChange={(event) =>
                                                      setFormData({ ...formData, Bp: event.target.value })
                                                  } />
                                          </div>
                                          <div className="form-group col-md-2">
                                              <label >Pulse</label>
                                              <input  type="text" className="form-control"
                                                  placeholder="(bpm)"
                                                  value={formData.Pulse}
                                                  onChange={(event) =>
                                                      setFormData({ ...formData, Pulse: event.target.value })
                                                  } />
                                          </div>
                                          <div className="form-group col-md-2">
                                              <label >Steroids</label>
                                              <input type="text" className="form-control"
                                                  placeholder=" FTU "
                                                  value={formData.Stetoids}
                                                  onChange={(event) =>
                                                      setFormData({ ...formData, Stetoids: event.target.value })
                                                  } />
                                          </div>
                                  </div>
                                      <div className="form-row">
                                          <div className="form-group col-md-2">
                                              <label >Insulin</label>
                                              <input  type="text" className="form-control"
                                                  placeholder="(IU/mL)"
                                                  value={formData.Insulin}
                                                  onChange={(event) =>
                                                      setFormData({ ...formData, Insulin: event.target.value })
                                                  } />
                                          </div>
                                          <div className="form-group col-md-2">
                                              <label >Hypotensive Drugs</label>
                                              <input  type="text" className="form-control"
                                                  placeholder="Drug"
                                                  value={formData.HypotensiveDrugs}
                                                  onChange={(event) =>
                                                      setFormData({ ...formData, HypotensiveDrugs: event.target.value })
                                                  } />
                                          </div>
                                          <div className="form-group col-md-2">
                                              <label >Opium</label>
                                              <input type="text" className="form-control"
                                                  placeholder="Opium"
                                                  value={formData.Opium}
                                                  onChange={(event) =>
                                                      setFormData({ ...formData, Opium: event.target.value })
                                                  } />
                                          </div>
                                          <div className="form-group col-md-2">
                                              <label>Dentures</label>
                                              <input  type="text" className="form-control"
                                                  placeholder="dentures"
                                                  value={formData.Dentures}
                                                  onChange={(event) =>
                                                      setFormData({ ...formData, Dentures: event.target.value })
                                                  } />
                                          </div>
                                          <div className="form-group col-md-2">
                                              <label >Prev. Anth Exp</label>
                                              <input  type="text" className="form-control"
                                                  placeholder="Previous Aneasthesia"
                                                  value={formData.PreviousAnthExp}
                                                  onChange={(event) =>
                                                      setFormData({ ...formData, PreviousAnthExp: event.target.value })
                                                  } />
                                          </div>
                                          <div className="form-group col-md-2">
                                              <label > Difficulties</label>
                                              <input type="text" className="form-control"
                                                  placeholder="difficulties"
                                                  value={formData.DifficultiesAnth}
                                                  onChange={(event) =>
                                                      setFormData({ ...formData, DifficultiesAnth: event.target.value })
                                                  } />
                                          </div>
                                      </div>

                                      <div className="form-row">
                                          <div className="form-group col-md-6">
                                              <label>Details</label>
                                              <input  type="text" className="form-control"
                                                  placeholder="detail"
                                                  value={formData.DetailsAnth}
                                                  onChange={(event) =>
                                                      setFormData({ ...formData, DetailsAnth: event.target.value })
                                                  } />
                                          </div>
                                          <div className="form-group col-md-6">
                                              <label >Remark</label>
                                              <input type="text" className="form-control"
                                                  placeholder="remark"
                                                  value={formData.RemarkAnth}
                                                  onChange={(event) =>
                                                      setFormData({ ...formData, RemarkAnth: event.target.value })
                                                  } />
                                          </div>
                                      </div>

                                      <div className="form-row">
                                          <div className="form-group col-md-2">
                                              <label >Date</label>
                                              <input  type="text" className="form-control"
                                                  placeholder="mm/dd/yy"
                                                  value={formData.DateAnth}
                                                  onChange={(event) =>
                                                      setFormData({ ...formData, DateAnth: event.target.value })
                                                  } />
                                          </div>
                                          <div className="form-group col-md-10">
                                              <label>PRE-OPERATIVE NOTE</label>
                                              <input  type="text" className="form-control"
                                                  placeholder="Note"
                                                  value={formData.PreOperativeNote}
                                                  onChange={(event) =>
                                                      setFormData({ ...formData, PreOperativeNote: event.target.value })
                                                  } />
                                          </div>
                                      </div>
                                      <div className="form-row">
                                          <div className="form-group col-md-2">
                                              <label >Date</label>
                                              <input  type="text" className="form-control"
                                                  placeholder="mm/dd/yy"
                                                  value={formData.DateAnth}
                                                  onChange={(event) =>
                                                      setFormData({ ...formData, wellbeing: event.target.value })
                                                  } />
                                          </div>
                                          <div className="form-group col-md-10">
                                              <label>POST-OPERATIVRE FOLLOW</label>
                                              <input  type="text" className="form-control"
                                                  placeholder="follow"
                                                  value={formData.PreOperativeFollow}
                                                  onChange={(event) =>
                                                      setFormData({ ...formData, PreOperativeFollow: event.target.value })
                                                  } />
                                          </div>
                                      </div>
                                     





                                  <div className="d-flex justify-content-center">
                                      <button type="button" id="saveReceiver" className="btn btn-primary">Save</button>
                                  </div>
                              </form>

                          </div>
                      </div>
                  </div>
              </div>
          </div>
          )
}

export default IpdAnaesthesia

