import React from 'react';
import Navbar from './Doctorpages/Navbar';
import Sidepanel from './Doctorpages/Sidepanel';
import { Route, Routes } from "react-router-dom";
import Footer from './Doctorpages/Footer';
import Panel from './Doctorpages/Panel';
import Opdregister from './Doctorpages/Opdregister';
import Opddetails from './Doctorpages/Opddetails';


function Doctor() {
  return (
      <div id="wrapper"><Sidepanel />
          <div id="content-wrapper" className="d-flex flex-column">
              <div id="content">
                  <Navbar />

                  <Routes>
                      <Route path="/" element={<Panel />} />
                      <Route path="/opdregistered" element={<Opdregister />} />
                      <Route path="/opddetails/:id" element={<Opddetails />} />

                  </Routes>
                  <Footer />
              </div>
          </div>
      </div>
  )
}

export default Doctor
