import React from 'react';
import Navbar from './Directorpages/Navbar';
import Sidepanel from './Directorpages/Sidepanel';
import Panel from './Directorpages/Panel';
import Footer from './Directorpages/Footer';

import { Route, Routes } from "react-router-dom";
import Surveydata from './Directorpages/Surveydata';
import Surveydetails from './Directorpages/Surveydetails';

function Director() {
    return (
        <div>
            <div id="wrapper"><Sidepanel />
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <Navbar />

                        <Routes>
                            <Route path="/" element={<Panel />} />
                            <Route path="/surveydata" element={<Surveydata />} />
                            <Route path="/survey/details/:id" element={<Surveydetails />} />



                        </Routes>
                        <Footer />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Director
