import React from 'react';
import Navbar from './Ipdpages/Navbar';
import Sidepanel from './Ipdpages/Sidepanel';
import { Route, Routes } from "react-router-dom";
import Footer from './Ipdpages/Footer';
import Ipdform from './Ipdpages/Ipdform';
import Ipddetails from './Ipdpages/Ipddetails';
import IpdAnaesthesia from './Ipdpages/IpdAnaesthesia';
import IpdAdmission from './Ipdpages/IpdAdmission';
import IpdBilling from './Ipdpages/IpdBilling';



function Opd() {
  return (
      <div id="wrapper"><Sidepanel />
          <div id="content-wrapper" className="d-flex flex-column">
              <div id="content">
                  <Navbar />

                  <Routes>
                      <Route path="/" element={<Ipddetails/>} />
                      <Route path="/form" element={<Ipdform />} />
                      <Route path="/anaesthesia" element={<IpdAnaesthesia />} />
                      <Route path="/admission" element={<IpdAdmission />} />
                      <Route path="/billing" element={<IpdBilling />} />

                  </Routes>
                  <Footer />
              </div>
          </div>
      </div>
  )
}

export default Opd
