import React from 'react';
import Navbar from './Opdpages/Navbar';
import Sidepanel from './Opdpages/Sidepanel';
import { Route, Routes } from "react-router-dom";
import Footer from './Opdpages/Footer';
import Opdform from './Opdpages/Opdform';
import OpdAdd from './Opdpages/OpdAdd';
import OpdRegister from './Opdpages/OpdRegister';
import BulkUploadOPD from './Opdpages/BulkUploadOPD';

function Opd() {
  return (
      <div id="wrapper"><Sidepanel />
          <div id="content-wrapper" className="d-flex flex-column">
              <div id="content">
                  <Navbar />

                  <Routes>
                      <Route path="/" element={<Opdform/>} />
                      <Route path="/add" element={<OpdAdd />} />
                      <Route path="/register/:id" element={<OpdRegister />} />
                      <Route path="/bulkcvs" element={<BulkUploadOPD />} />
                     


                  </Routes>
                  <Footer />
              </div>
          </div>
      </div>
  )
}

export default Opd
