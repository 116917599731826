import React, { useState, useEffect } from 'react';
import { customFetch } from '../../Helper/api-helper';
import { useNavigate } from "react-router-dom";
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import { Route, Link, Routes, useParams } from 'react-router-dom';
import { apiBaseUrl } from '../../../global/credentials';

function Opddetails() {
    const params = useParams();
    const [detail, setDetail] = useState([]);
    useEffect(() => {
        handle();
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);
    const handle = async () => {
        var res = await customFetch(`${apiBaseUrl}opd/patient/` + params.id);
        setDetail(res);
        console.log(res);
        setTimeout(function () {
            $('#donertable').DataTable(
            );
        }, 100);

    }

    return (
        <div><div className="container-fluid">
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/doctor/">Home</Link></li>
                    <li className="breadcrumb-item " aria-current="page"><Link to="/doctor/opdregistered">Opd Data</Link></li>
                    <li className="breadcrumb-item active" aria-current="page"><Link to="/doctor/opddetails">Patient Detail</Link></li>
                </ol>
            </nav>
        </div>
            <div className="container">
                <div className="card shadow mb-4">
                    <div className="card-body p-4">
                        <div className="row">

                            <div className='col-6'>
                                <div className="row ms-5 me-5">
                                    <label className="form-group col-md-6" >Date and Time of Billing</label>
                                    <h3 className="form-control form-group col-md-6" >{detail.dateAndTimeOfBilling}</h3>
                                </div>
                                <div className="row ms-5 me-5">
                                    <label className="form-group col-md-6" >Patient name</label>
                                    <h3 className="form-control form-group col-md-6">{detail.patientName}</h3>
                                </div>

                                <div >
                                    <div className="row ms-5 me-5">
                                        <label className="form-group col-md-6" >Age</label>
                                        <h3 className="form-control form-group col-md-6">{detail.age}</h3>
                                    </div>
                                    <div className="row ms-5 me-5">
                                        <label className="form-group col-md-6" >Gender</label>
                                        <h3 className="form-control form-group col-md-6">{detail.gender}</h3>
                                    </div>
                                    <div className="row ms-5 me-5" >
                                        <label className="form-group col-md-6" >UHID/ INVOICE NO </label>
                                        <h3 className="form-control form-group col-md-6">{detail.uhidInvoiceNo}</h3>
                                    </div>
                                </div>


                                <div className="row ms-5 me-5" >
                                    <label className="form-group col-md-6" >CONSULTANT NAME </label>
                                    <h3 className="form-control form-group col-md-6">{detail.consultantName}</h3>
                                </div>
                                <div className="row ms-5 me-5">
                                    <label className="form-group col-md-6" >Slot Time </label>
                                    <h3 className="form-control form-group col-md-6">{detail.slotTime}</h3>
                                </div>
                                <div className="row ms-5 me-5">
                                    <label className="form-group col-md-6" >Diagnose Details </label>
                                    <h3 className="form-control form-group col-md-6">{detail.diagnoseInvestigationDetails}</h3>
                                </div>
                                <div className="row ms-5 me-5">
                                    <label className="form-group col-md-6" >time of billing </label>
                                    <h3 className="form-control form-group col-md-6">{detail.time_of_investigation_of_billing}</h3>
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className="row ms-5 me-5" >
                                    <label className="form-group col-md-6" >Blood Pressure </label>
                                    <h3 className="form-control form-group col-md-6">{detail.bloodPressure}</h3>
                                </div>
                                <div className="row ms-5 me-5">
                                    <label className="form-group col-md-6" >Pulse Rate </label>
                                    <h3 className="form-control form-group col-md-6">{detail.pulseRate}</h3>
                                </div>
                                <div className="row ms-5 me-5">
                                    <label className="form-group col-md-6" >SPO 2</label>
                                    <h3 className="form-control form-group col-md-6" >{detail.spo2}</h3>
                                </div>
                                <div className="row ms-5 me-5">
                                    <label className="form-group col-md-6" >BMI</label>
                                    <h3 className="form-control form-group col-md-6">{detail.bmi}</h3>
                                </div>

                                <div >
                                    <div className="row ms-5 me-5">
                                        <label className="form-group col-md-6" >Pain</label>
                                        <h3 className="form-control form-group col-md-6">{detail.pain}</h3>
                                    </div>
                                    <div className="row ms-5 me-5">
                                        <label className="form-group col-md-6" >In Time</label>
                                        <h3 className="form-control form-group col-md-6">{detail.inTime}</h3>
                                    </div>
                                    <div className="row ms-5 me-5" >
                                        <label className="form-group col-md-6" >Assessment Time </label>
                                        <h3 className="form-control form-group col-md-6">{detail.assessmentTime}</h3>
                                    </div>
                                    <div className="row ms-5 me-5" >
                                        <label className="form-group col-md-6" >Out Time </label>
                                        <h3 className="form-control form-group col-md-6">{detail.outTime}</h3>
                                    </div>
                                    <div className="row ms-5 me-5" >
                                        <label className="form-group " >Staff Signadute</label>
                                        <h3 className="form-control form-group"> <img style={{ height: "100px", width: "400px" }} src={apiBaseUrl + detail.staffSignadute} /></h3>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Opddetails
