import React, { useState, useEffect } from 'react';
import { customFetch } from '../../Helper/api-helper';
import { Link } from "react-router-dom";
import { apiBaseUrl } from '../../../global/credentials'


function Stockrecord() {
  const [detail, setDetail] = useState([]);
  useEffect(() => {
    handle()
  }, []);
  const handle = async () => {

    var res = await customFetch(`${apiBaseUrl}blood-bank/receivers`);

    setDetail(res);


  }

  return (
    <div>
      <div className="container-fluid">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to="/blood-bank/">Home</Link></li>
            <li className="breadcrumb-item active" aria-current="page"><Link to="/blood-bank/stockrecord">Stock Recored</Link></li>
          </ol>
        </nav>
      </div>
      <div className="container-fluid">
        {/* Page Heading */}
        <h1 className="h3 mb-2 text-gray-800">Tables</h1>
        <p className="mb-4">DataTables is a third party plugin that is used to generate the demo table below.
          For more information about DataTables, please visit the </p>
        {/* DataTales Example */}
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 style={{ float: "left" }} className="m-0 font-weight-bold text-primary">DataTables</h6>
            <div style={{ float: "right" }} className='mb-4'>
              <Link className="btn btn-primary btn-sm" to="/frontpage">Add New</Link>
            </div>
          </div>

          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-bordered" id="receivertable" width="100%" cellSpacing={0}>
                <thead>
                  <tr>
                    <td>Blood Group</td>
                    <td>Unit No.</td>
                    <td>Actions</td>
                  </tr>
                </thead>


                
                  <tbody>

                    <tr>
                      <th>A+</th>
                      <td>2</td>
                      <td>
                        <button className="btn btn-success btn-sm mx-1" data-toggle="tooltip" title="Blood collected"><i className="fa fa-check-circle" aria-hidden="true"></i> </button>
                        <span asp-route="bloodbank-donersTimeline"  className="btn btn-default btn-sm" data-toggle="tooltip" title="Doner timeline"><i className="fa fa-clock" aria-hidden="true"></i> </span>
                      </td>
                    </tr>
                    <tr>
                      <th>A-</th>
                      <td>3</td>
                      <td>
                        <button className="btn btn-success btn-sm mx-1" data-toggle="tooltip" title="Blood collected"><i className="fa fa-check-circle" aria-hidden="true"></i> </button>
                        <span asp-route="bloodbank-donersTimeline"  className="btn btn-default btn-sm" data-toggle="tooltip" title="Doner timeline"><i className="fa fa-clock" aria-hidden="true"></i> </span>
                      </td>
                    </tr>
                    <tr>
                      <th>B+</th>
                      <td>6</td>
                      <td>
                        <button className="btn btn-success btn-sm mx-1" data-toggle="tooltip" title="Blood collected"><i className="fa fa-check-circle" aria-hidden="true"></i> </button>
                        <span asp-route="bloodbank-donersTimeline"  className="btn btn-default btn-sm" data-toggle="tooltip" title="Doner timeline"><i className="fa fa-clock" aria-hidden="true"></i> </span>
                      </td>
                    </tr>
                    <tr>
                      <th>B-</th>
                      <td> 7</td>
                      <td>
                        <button className="btn btn-success btn-sm mx-1" data-toggle="tooltip" title="Blood collected"><i className="fa fa-check-circle" aria-hidden="true"></i> </button>
                        <span asp-route="bloodbank-donersTimeline" className="btn btn-default btn-sm" data-toggle="tooltip" title="Doner timeline"><i className="fa fa-clock" aria-hidden="true"></i> </span>
                      </td>
                    </tr>
                    <tr>
                      <th>O+</th>
                      <td>2</td>
                      <td>
                        <button className="btn btn-success btn-sm mx-1" data-toggle="tooltip" title="Blood collected"><i className="fa fa-check-circle" aria-hidden="true"></i> </button>
                        <span asp-route="bloodbank-donersTimeline"  className="btn btn-default btn-sm" data-toggle="tooltip" title="Doner timeline"><i className="fa fa-clock" aria-hidden="true"></i> </span>
                      </td>
                    </tr>
                    <tr>
                      <th>O-</th>
                      <td>9</td>
                      <td>
                        <button className="btn btn-success btn-sm mx-1" data-toggle="tooltip" title="Blood collected"><i className="fa fa-check-circle" aria-hidden="true"></i> </button>
                        <span asp-route="bloodbank-donersTimeline"  className="btn btn-default btn-sm" data-toggle="tooltip" title="Doner timeline"><i className="fa fa-clock" aria-hidden="true"></i> </span>
                      </td>
                    </tr>
                    <tr>
                      <th>AB+</th>
                      <td>4</td>
                      <td>
                        <button className="btn btn-success btn-sm mx-1" data-toggle="tooltip" title="Blood collected"><i className="fa fa-check-circle" aria-hidden="true"></i> </button>
                        <span asp-route="bloodbank-donersTimeline" className="btn btn-default btn-sm" data-toggle="tooltip" title="Doner timeline"><i className="fa fa-clock" aria-hidden="true"></i> </span>
                      </td>
                    </tr>
                    <tr>
                      <th>AB-</th>
                      <td>5</td>
                      <td>
                        <button className="btn btn-success btn-sm mx-1" data-toggle="tooltip" title="Blood collected"><i className="fa fa-check-circle" aria-hidden="true"></i> </button>
                        <span asp-route="bloodbank-donersTimeline" className="btn btn-default btn-sm" data-toggle="tooltip" title="Doner timeline"><i className="fa fa-clock" aria-hidden="true"></i> </span>
                      </td>
                    </tr>


                  </tbody>
                

              </table>

            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Stockrecord
