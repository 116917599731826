import React, { useState, useEffect } from 'react';
import { customFetch } from '../../Helper/api-helper';
import { useNavigate } from "react-router-dom";
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import { Route, Link, Routes, useParams } from 'react-router-dom';
import { apiBaseUrl } from '../../../global/credentials';

function Surveydetails() {
    const params = useParams();
    const [detail, setDetail] = useState({ surveyMembers: [], surveyImmunisationSchedules: [], surveyDiseases: [] });
    useEffect(() => {
        handle();
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);
    const handle = async () => {

        var res = await customFetch(`${apiBaseUrl}admin/surveys/details/` + params.id);

        setDetail(res.data);

        console.log(res.data.surveyMembers)

        setTimeout(function () {
            $('#donertable').DataTable(
            );
        }, 100);

    }

    console.log(params);
    return (
        <div>
            <div>
                <div className="container-fluid">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/director/">Home</Link></li>
                            <li className="breadcrumb-item"><Link to="/director/surveydata/">Survey Data</Link></li>
                            <li className="breadcrumb-item active" aria-current="page"><Link to="/director/survey/details/">Survey Detail</Link></li>
                        </ol>
                    </nav>
                </div>

                <div className="container-fluid">

                    <div className="card shadow mb-4">


                        <div className="card-body">
                            <form >
                                <h2 className='text-center p-2'>SURVEY FORM</h2>

                                <h4>1.Family Identification Data</h4>
                                <div className="row my-4">
                                    <div className="form-group col-md-4">
                                        <label >Head of Family</label>
                                        <h5 className="form-control">{detail.headOfFamily}</h5>
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label >Occupation OF Head</label>
                                        <h5 className="form-control">{detail.occupation}</h5>
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label >Type of Family</label>
                                        <h5 className="form-control">{detail.typeOfFamily}</h5>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="form-group col-md-2">
                                        <label >Size Of Family</label>
                                        <h5 className="form-control">{detail.sizeOfFamily}</h5>
                                    </div>
                                    <div className="form-group col-md-2">
                                        <label>Area</label>
                                        <h5 className="form-control">{detail.area}</h5>
                                    </div>
                                    <div className="form-group col-md-8">
                                        <label >Address</label>
                                        <h5 className="form-control">{detail.address}</h5>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-md-12 my-4">
                                        <table id="PatientFamilyMembers" className="table table-bordered">
                                            <tbody>
                                                <tr >
                                                    <th >Member</th>
                                                    <th >Relation to Head</th>
                                                    <th >Occupation</th>
                                                    <th >Marital Status</th>
                                                </tr>

                                                {detail.surveyMembers.map((survey, index) => (
                                                    <tr  >
                                                        <td >{survey.member}</td>
                                                        <td >{survey.relationToHead}</td>
                                                        <td >{survey.occupation}</td>
                                                        <td >{survey.maritalStatus}</td>


                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>


                                <div className="row my-4">
                                    <h4>2.Pediatric Data Of Family</h4>

                                    <div className="col-md-12">
                                        <table className="table table-bordered">
                                            <tbody><tr>
                                                <th>Children</th>
                                                <th>Number</th>
                                                <th>Fully Vaccinated</th>
                                            </tr>
                                                <tr className="p-0">
                                                    <td className='text-left'> 1. Neonate</td>
                                                    <td>{detail.numOfNeonate}</td>
                                                    <td className="text-center">
                                                        {(() => {
                                                            if (detail.neonateVaccine === 1) {
                                                                return (
                                                                    <div> <i className="fa fa-check" aria-hidden="true"></i></div>
                                                                )
                                                            } else {
                                                                return (
                                                                    <div><i className="fa fa-times" aria-hidden="true"></i></div>
                                                                )
                                                            }
                                                        })()}</td>
                                                </tr>

                                                <tr className="p-0">
                                                    <td className='text-left'> 2. Infant</td>
                                                    <td >{detail.numOfInfant}</td>
                                                    <td className="text-center">
                                                        {(() => {
                                                            if (detail.infantVaccine === 1) {
                                                                return (
                                                                    <div> <i className="fa fa-check" aria-hidden="true"></i></div>
                                                                )
                                                            } else {
                                                                return (
                                                                    <div><i className="fa fa-times" aria-hidden="true"></i></div>
                                                                )
                                                            }
                                                        })()}</td>
                                                </tr>

                                                <tr className="p-0">
                                                    <td className='text-left'>3. Upto 5 year</td>
                                                    <td >{detail.numUptoFiveYearChildren}</td>
                                                    <td className="text-center">
                                                        {(() => {
                                                            if (detail.upto5YearVaccine === 1) {
                                                                return (
                                                                    <div> <i className="fa fa-check" aria-hidden="true"></i></div>
                                                                )
                                                            } else {
                                                                return (
                                                                    <div><i className="fa fa-times" aria-hidden="true"></i></div>
                                                                )
                                                            }
                                                        })()}</td>
                                                </tr>
                                                <tr className="p-0">
                                                    <td className='text-left'>4. 5 year and above </td>
                                                    <td >{detail.numOfAbove5YearChildren}</td>
                                                    <td className="text-center">
                                                        {(() => {
                                                            if (detail.above5YearVaccine === 1) {
                                                                return (
                                                                    <div> <i className="fa fa-check" aria-hidden="true"></i></div>
                                                                )
                                                            } else {
                                                                return (
                                                                    <div><i className="fa fa-times" aria-hidden="true"></i></div>
                                                                )
                                                            }
                                                        })()}</td>
                                                </tr>

                                            </tbody></table>
                                    </div>
                                </div>

                                {detail.surveyImmunisationSchedules.length > 0 ? <h4>3.Immunisation Schedule</h4> : ""}
                                <div >
                                    {detail.surveyImmunisationSchedules.map((survey, index) => (
                                        <div className='border p-4' key={index}>
                                            <div className='row '>
                                                <h3>{index + 1}. Data</h3>
                                                <div className="form-group col-md-6">
                                                    <label>Name</label>
                                                    <h5 className="form-control">{survey.name}</h5>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label>Age</label>
                                                    <h5 className="form-control">{survey.age}</h5>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <table id="patientBloodGroup" className="table table-bordered">
                                                        <tbody><tr>
                                                            <th >Targeted Age Group</th>
                                                            <th >Name of Vaccine</th>
                                                            <th >Dosage</th>
                                                            <th >Remark</th>
                                                        </tr>
                                                            <tr className="p-0">
                                                                <td className='pl-4'>At Birth</td>
                                                                <td className='pl-4'>
                                                                    <tr>BCG Sabin</tr>
                                                                    <tr>HBV</tr>
                                                                    <tr>OPV 0</tr>
                                                                    <tr>Penta 1</tr>
                                                                </td>
                                                                <td >
                                                                    <p className="text-center">
                                                                        {(() => {
                                                                            if (survey.bcgSabin === 1) {
                                                                                return (
                                                                                    <div> <i className="fa fa-check" aria-hidden="true"></i></div>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <div><i className="fa fa-times" aria-hidden="true"></i></div>
                                                                                )
                                                                            }
                                                                        })()}
                                                                    </p>
                                                                    <p className="text-center">
                                                                        {(() => {
                                                                            if (survey.hbv === 1) {
                                                                                return (
                                                                                    <div> <i className="fa fa-check" aria-hidden="true"></i></div>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <div><i className="fa fa-times" aria-hidden="true"></i></div>
                                                                                )
                                                                            }
                                                                        })()}
                                                                    </p>
                                                                    <p className="text-center">
                                                                        {(() => {
                                                                            if (survey.opv0 === 1) {
                                                                                return (
                                                                                    <div> <i className="fa fa-check" aria-hidden="true"></i></div>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <div><i className="fa fa-times" aria-hidden="true"></i></div>
                                                                                )
                                                                            }
                                                                        })()}
                                                                    </p>
                                                                    <p className="text-center">
                                                                        {(() => {
                                                                            if (survey.penta1Atbirth === 1) {
                                                                                return (
                                                                                    <div> <i className="fa fa-check" aria-hidden="true"></i></div>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <div><i className="fa fa-times" aria-hidden="true"></i></div>
                                                                                )
                                                                            }
                                                                        })()}
                                                                    </p>

                                                                </td>
                                                                <td ><h5 className="form-control">{survey.atbirthRemark}</h5></td>

                                                            </tr>
                                                            <tr className="p-0">
                                                                <td className='pl-4'>6 Weeks</td>
                                                                <td className='pl-4'>
                                                                    <tr>PCV1</tr>
                                                                    <tr>OPV 1</tr>
                                                                    <tr>Penta 1</tr>
                                                                </td>
                                                                <td className='pl-4'>
                                                                    <p className="text-center">
                                                                        {(() => {
                                                                            if (survey.pcv1 === 1) {
                                                                                return (
                                                                                    <div> <i className="fa fa-check" aria-hidden="true"></i></div>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <div><i className="fa fa-times" aria-hidden="true"></i></div>
                                                                                )
                                                                            }
                                                                        })()}</p>
                                                                    <p className="text-center">
                                                                        {(() => {
                                                                            if (survey.opv1 === 1) {
                                                                                return (
                                                                                    <div> <i className="fa fa-check" aria-hidden="true"></i></div>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <div><i className="fa fa-times" aria-hidden="true"></i></div>
                                                                                )
                                                                            }
                                                                        })()}</p>
                                                                    <p className="text-center">
                                                                        {(() => {
                                                                            if (survey.penta1 === 1) {
                                                                                return (
                                                                                    <div> <i className="fa fa-check" aria-hidden="true"></i></div>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <div><i className="fa fa-times" aria-hidden="true"></i></div>
                                                                                )
                                                                            }
                                                                        })()}</p>


                                                                </td>
                                                                <td ><h5 className="form-control">{survey._6weekRemark}</h5></td>

                                                            </tr>

                                                            <tr className="p-0">
                                                                <td className='pl-4' >10 Weeks</td>
                                                                <td className='pl-4'>
                                                                    <tr>Penta 3</tr>
                                                                    <tr>PC V 2</tr>
                                                                    <tr>OPV 2</tr>
                                                                </td>
                                                                <td className='pl-4'>
                                                                    <p className="text-center">
                                                                        {(() => {
                                                                            if (survey.penta3 === 1) {
                                                                                return (
                                                                                    <div> <i className="fa fa-check" aria-hidden="true"></i></div>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <div><i className="fa fa-times" aria-hidden="true"></i></div>
                                                                                )
                                                                            }
                                                                        })()}</p>
                                                                    <p className="text-center">
                                                                        {(() => {
                                                                            if (survey.pcv2 === 1) {
                                                                                return (
                                                                                    <div> <i className="fa fa-check" aria-hidden="true"></i></div>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <div><i className="fa fa-times" aria-hidden="true"></i></div>
                                                                                )
                                                                            }
                                                                        })()}</p>
                                                                    <p className="text-center">
                                                                        {(() => {
                                                                            if (survey.opv2 === 1) {
                                                                                return (
                                                                                    <div> <i className="fa fa-check" aria-hidden="true"></i></div>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <div><i className="fa fa-times" aria-hidden="true"></i></div>
                                                                                )
                                                                            }
                                                                        })()}</p>


                                                                </td>
                                                                <td ><h5 className="form-control">{survey._10weekRemark}</h5></td>


                                                            </tr>

                                                            <tr className="p-0">
                                                                <td className='pl-4'>14 Weeks</td>
                                                                <td className='pl-4'>
                                                                    <tr>DPT 3</tr>
                                                                    <tr>OPV 3</tr>
                                                                </td>
                                                                <td className='pl-4'>
                                                                    <p className="text-center">
                                                                        {(() => {
                                                                            if (survey.dpt3 === 1) {
                                                                                return (
                                                                                    <div> <i className="fa fa-check" aria-hidden="true"></i></div>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <div><i className="fa fa-times" aria-hidden="true"></i></div>
                                                                                )
                                                                            }
                                                                        })()}</p>
                                                                    <p className="text-center">
                                                                        {(() => {
                                                                            if (survey.opv3 === 1) {
                                                                                return (
                                                                                    <div> <i className="fa fa-check" aria-hidden="true"></i></div>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <div><i className="fa fa-times" aria-hidden="true"></i></div>
                                                                                )
                                                                            }
                                                                        })()}</p>
                                                                </td>
                                                                <td ><h5 className="form-control">{survey._14weekRemark}</h5></td>

                                                            </tr>

                                                            <tr className="p-0">
                                                                <td className='pl-4' >6 Months</td>
                                                                <td className='pl-4'>
                                                                    <tr>Vitamin A</tr>
                                                                </td>
                                                                <td className='pl-4'>
                                                                    <p className="text-center">
                                                                        {(() => {
                                                                            if (survey.vitaminA === 1) {
                                                                                return (
                                                                                    <div> <i className="fa fa-check" aria-hidden="true"></i></div>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <div><i className="fa fa-times" aria-hidden="true"></i></div>
                                                                                )
                                                                            }
                                                                        })()}</p>
                                                                </td>
                                                                <td ><h5 className="form-control">{survey._6monthRemark}</h5></td>

                                                            </tr>

                                                            <tr className="p-0">
                                                                <td className='pl-4' >9 Months</td>
                                                                <td className='pl-4'>
                                                                    <tr>Measles 1</tr>
                                                                </td>
                                                                <td className='pl-4'>
                                                                    <p className="text-center">
                                                                        {(() => {
                                                                            if (survey.measles1 === 1) {
                                                                                return (
                                                                                    <div> <i className="fa fa-check" aria-hidden="true"></i></div>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <div><i className="fa fa-times" aria-hidden="true"></i></div>
                                                                                )
                                                                            }
                                                                        })()}</p>
                                                                </td>
                                                                <td ><h5 className="form-control">{survey._9monthRemark}</h5></td>

                                                            </tr>

                                                            <tr className="p-0">
                                                                <td className='pl-4' >9 Months</td>
                                                                <td className='pl-4'>
                                                                    <tr >Yellow Fever</tr>
                                                                </td>
                                                                <td className='pl-4'>
                                                                    <p className="text-center">
                                                                        {(() => {
                                                                            if (survey.yellowFever === 1) {
                                                                                return (
                                                                                    <div> <i className="fa fa-check" aria-hidden="true"></i></div>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <div><i className="fa fa-times" aria-hidden="true"></i></div>
                                                                                )
                                                                            }
                                                                        })()}</p>
                                                                </td>
                                                                <td ><h5 className="form-control">{survey._9month2ndRemark}</h5></td>

                                                            </tr>
                                                            <tr className="p-0">
                                                                <td className='pl-4' >12 Months</td>
                                                                <td className='pl-4'>
                                                                    <tr>Vitamin A</tr>
                                                                </td>
                                                                <td className='pl-4'>
                                                                    <p className="text-center">
                                                                        {(() => {
                                                                            if (survey.vitaminA12month === 1) {
                                                                                return (
                                                                                    <div> <i className="fa fa-check" aria-hidden="true"></i></div>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <div><i className="fa fa-times" aria-hidden="true"></i></div>
                                                                                )
                                                                            }
                                                                        })()}</p>
                                                                </td>
                                                                <td ><h5 className="form-control">{survey._12monthRemark}</h5></td>

                                                            </tr>
                                                            <tr className="p-0">
                                                                <td className='pl-4'>18 Months</td>
                                                                <td className='pl-4'>
                                                                    <tr>PCV1</tr>
                                                                </td>
                                                                <td className='pl-4'>
                                                                    <p className="text-center">
                                                                        {(() => {
                                                                            if (survey.pcv118Month === 1) {
                                                                                return (
                                                                                    <div> <i className="fa fa-check" aria-hidden="true"></i></div>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <div><i className="fa fa-times" aria-hidden="true"></i></div>
                                                                                )
                                                                            }
                                                                        })()}</p>
                                                                </td>
                                                                <td ><h5 className="form-control">{survey._18monthRemark}</h5></td>

                                                            </tr>

                                                            <tr className="p-0">
                                                                <td className='pl-4'>18 Months</td>
                                                                <td className='pl-4'>
                                                                    <tr>Measles 2</tr>
                                                                </td>
                                                                <td className='pl-4'>
                                                                    <p className="text-center">
                                                                        {(() => {
                                                                            if (survey.measles2 === 1) {
                                                                                return (
                                                                                    <div> <i className="fa fa-check" aria-hidden="true"></i></div>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <div><i className="fa fa-times" aria-hidden="true"></i></div>
                                                                                )
                                                                            }
                                                                        })()}</p>
                                                                </td>
                                                                <td ><h5 className="form-control">{survey._18month2ndRemark}</h5></td>

                                                            </tr>

                                                        </tbody></table>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="row my-4 ">
                                    {detail.surveyDiseases.length > 0 ? <h4>4.Diseases Prevalant In Community</h4> : ""}
                                    <div className='row table-bordered' >
                                        {detail.surveyDiseases.map((member, index) => (
                                            <div key={index} className='row borderbt p-2 patientDiseases ' data-diseasestype="diabetes">
                                                <div className='col-4 mt-2'>
                                                    <div className="form-group col-md-12">
                                                        <label >Type of Disease</label>
                                                        <h5 className="form-control">{member.typeOfDiseases}</h5>
                                                    </div>
                                                    <div className="form-group col-md-12">
                                                        <label >Name</label>
                                                        <h5 className="form-control">{member.name}</h5>
                                                    </div>
                                                    <div className="form-group col-md-12">
                                                        <label >Age</label>
                                                        <h5 className="form-control">{member.age}</h5>
                                                    </div>
                                                    <div className="form-group col-md-12">
                                                        <label >History</label>
                                                        <h5 className="form-control">{member.history}</h5>
                                                    </div>
                                                </div>
                                                <div className='col-8 mt-3'>
                                                    <table className="table table-bordered">
                                                        <tbody><tr className="p-0">
                                                            <th>S.no</th>
                                                            <th>Medicine</th>
                                                            <th>Generic</th>
                                                        </tr>
                                                            <tr className="p-0">
                                                                <td className='text-center'> 1.</td>
                                                                <td ><p>{member.med1}</p></td>
                                                                <td ><p>{member.gen1}</p></td>
                                                            </tr>

                                                            <tr className="p-0">
                                                                <td className='text-center p-0'> 2.</td>
                                                                <td ><p>{member.med2}</p></td>
                                                                <td ><p>{member.gen2}</p></td>

                                                            </tr>

                                                            <tr className="p-0">
                                                                <td className='text-center p-0'>3.</td>
                                                                <td ><p>{member.med3}</p></td>
                                                                <td ><p>{member.gen3}</p></td>
                                                            </tr>
                                                            <tr className="p-0">
                                                                <td className='text-center p-0'>4.</td>
                                                                <td ><p>{member.med4}</p></td>
                                                                <td ><p>{member.gen4}</p></td>
                                                            </tr>
                                                            <tr className="p-0">
                                                                <td className='text-center p-0'>5.</td>
                                                                <td ><p>{member.med5}</p></td>
                                                                <td ><p>{member.gen5}</p></td>
                                                            </tr>

                                                        </tbody></table>
                                                </div>
                                            </div>))}
                                    </div>
                                </div>




                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    )
}

export default Surveydetails
