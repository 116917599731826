import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { customFormDataPost } from '../../Helper/api-helper';
import { useNavigate } from "react-router-dom";
import Donerpg1 from "./Donerpg1";
import Donerpg2 from "./Donerpg2";
import Donerpg3 from "./Donerpg3";
import { Link } from "react-router-dom";
import swal from 'sweetalert';
import $ from "jquery";
import { apiBaseUrl } from "../../../global/credentials";

function Adddonerform() {
  let navigate = useNavigate();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [])

  const { register, formState: { errors }, handleSubmit } = useForm();
  const [page, setPage] = useState(0);
  const [submitForm, setSubmitForm] = useState(false);
  const [formData, setFormData] = useState({
    unitno: "",
    date: new Date(),
    donername: "",
    fathersname: "",
    age: "",
    gender: "",
    wellbeing: "",
    phone: "",
    occandaddress: "",
    hbgm: "",
    weightkg: "",
    pulse: "",
    temp: "",
    phlebotomy: "",
    bloodgrouprhfactor: "",
    dateoflastdonation: "",
    previousdonorreaction: "",
    emergencypanel: "",
    previousrejection: "",
    abd: "",
    cvs: "",
    chest: "",
    skin: "",
    liver: "",
    signature: "",
    signatureofmo: "",
    reasonforrejection: "",
  });
  const donerSubmit = async () => {


    if (page < FormTitles.length - 1) {
      setPage((currPage) => currPage + 1);
      return;
    }

    var data = new FormData();
    data.append('Name', formData.donername);
    data.append('UnitNo', formData.unitno);
    data.append('DonationDate', formData.date);
    data.append('FathersName', formData.fathersname);
    data.append('Age', formData.age);
    data.append('Gender', formData.gender);
    data.append('WellBeing', formData.wellbeing);
    data.append('Phone', formData.phone);
    data.append('OccupationAndAddress', formData.occandaddress);
    data.append('Hb', formData.hbgm);
    data.append('Weight', formData.weightkg);
    data.append('Pulse', formData.pulse);
    data.append('Temp', formData.temp);
    data.append('PhlebotomySite', formData.phlebotomy);
    data.append('BloodGroupRhFactorIfKnown', formData.bloodgrouprhfactor);
    data.append('DateOfLastDonation', formData.dateoflastdonation);
    data.append('PreviousDonorReactionIfAny"', formData.previousdonorreaction);
    data.append('SignatureOfTheDonor', formData.signature);
    data.append('SignatureOfMo', formData.signatureofmo);
    data.append('EmergencyPanel', formData.emergencypanel);
    data.append('PreviousRejectionFromDonation"', formData.previousrejection);
    data.append('Abd', formData.abd);
    data.append('CVS', formData.cvs);
    data.append('Chest', formData.chest);
    data.append('Skin', formData.skin);
    data.append('Liver', formData.liver);
    data.append('ReasonsForRejection', formData.reasonforrejection);


    var donerQuestionnaire = '';
    $('.donor-questionnaire input:checked').each(function () {
      if (this.checked) {
        donerQuestionnaire += ',' + this.value;
      }
    })
    var donerQuestionnaire1 = '';
    $('.donor-questionnaire1 input:checked').each(function () {
      if (this.checked) {
        donerQuestionnaire1 += ',' + this.value;
      }
    })
    data.append("DonerQuestionnaire", donerQuestionnaire.substring(1))
    data.append("DonerQuestionnaireIllness", donerQuestionnaire1.substring(1))

    var res = await customFormDataPost(`${apiBaseUrl}blood-bank/doners/create/0`, data);
    if (res.status) {

      swal("Saved!", "Your Doner form data has been Saved!", "success");
      navigate("/blood-bank/donerlist");
      setSubmitForm(false);
    }
    else {
      alert(res.messsage)
    }


  }

  const FormTitles = ["Sign Up", "Personal Info", "Other"];

  const PageDisplay = () => {
    if (page === 0) {
      return <Donerpg1 register={register} errors={errors} formData={formData} setFormData={setFormData} />;
    } else if (page === 1) {
      return <Donerpg2 formData={formData} setFormData={setFormData} />;
    } else {
      return <Donerpg3 formData={formData} setFormData={setFormData} />;
    }
  };

  return (

    <>
      <div className="container-fluid">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to="/blood-bank/">Home</Link></li>
            <li className="breadcrumb-item"><Link to="/blood-bank/donerlist">Doner</Link></li>
            <li className="breadcrumb-item active" ><Link to="/blood-bank/donerform">AddDoner</Link></li>
          </ol>
        </nav>
      </div>

      <div className="bg-light">

        <div className="container">
          <div className="card o-hidden border-0 shadow-lg my-5">
            <div className="card-body p-0">
              <div className="user">

                <form id="donerForm" onSubmit={handleSubmit(donerSubmit)}>
                  <div className="form-container">

                    <div className="header my-4">


                      <ul className="progressbar ">
                        <div className="progress" style={{ width: page === 0 ? "33.3%" : page === 1 ? "66.6%" : "100%" }}> </div>
                        <li className="donerDetails"><strong>Donor’s detail</strong></li>
                        <li className="donerExamination" ><strong>Medical examination of donor </strong></li>
                        <li className="donorQuestionnaire"><strong>Donor Questionnaire</strong></li>

                      </ul>



                      <h1 className="text-center">{FormTitles[page]}</h1>
                    </div>
                    <div className="body">{PageDisplay()}</div>
                    <div className="footer d-flex justify-content-center mx-2 my-2">
                      <button
                        type="button"
                        className="next btn btn-primary mx-1 "
                        disabled={page === 0}
                        onClick={() => {
                          setPage((currPage) => currPage - 1);
                        }}
                      >
                        Prev
                      </button>

                      <button
                        className="next btn btn-primary mx-1"
                        type="submit"
                      //onClick={() => {
                      // if (page === FormTitles.length - 1) {
                      //   setSubmitForm(true);
                      //   alert("FORM SUBMITTED");

                      //   console.log(formData);
                      // } else {
                      //   setSubmitForm(false);
                      //   // handleSubmit((data)=>{
                      //   //   debugger
                      //   // })
                      //   debugger
                      //   if(errors){}
                      //   //setPage((currPage) => currPage + 1);
                      // }

                      // }}
                      >
                        {page === FormTitles.length - 1 ? "Submit" : "Next"}
                      </button>

                    </div>

                  </div>
                </form>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Adddonerform;