
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { customFormDataPost } from '../../Helper/api-helper';
import DatePicker from "react-datepicker";

import swal from 'sweetalert';
import moment from 'moment';
import $ from 'jquery';
import { apiBaseUrl } from '../../../global/credentials';

function Receiverform() {
  let navigate = useNavigate();
  const { handleSubmit } = useForm();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const [formData, setFormData] = useState({
    unitno: "",
    patientname: "",
    fathersname: "",
    age: "",
    gender: "",
    bedandward: "",
    address: "",
    nameofhospital: "",
    clinicdiagnosis: "",
    quantityreq: "",
    bloodtransfusiontype: "",
    previoustransfusiondate: "",
    dateandtimewhenreq: "",
    samplecollectedby: "",
    bloodgroup: "",
    date: "",
    drsig: "",
    relative: "",
    admitted: "",
    guardiansig: "",
    receiveinbloodbank: "",
    antiA: "",
    antiB: "",
    antiD: "",
    Acells: "",
    Bcells: "",
    Ocells: "",
    abo: "",
    rh: "",
    signatureofissuexmatch: "",
    signatureofmo: "",

  });
  const formatDateTime = (date) => {
    return moment(date).format('MM/DD/yyyy hh:mm a')
  }

  const donerSubmit = async () => {

    var data = new FormData();
    debugger;
    data.append('PatientName', formData.patientname);
    data.append('FathersName', formData.fathersname);
    data.append('Age', formData.age);
    data.append('Gender', formData.gender);
    data.append('Address', formData.address);
    data.append('WardAndBedNo', formData.bedandward);
    data.append('NameOfHospital', formData.nameofhospital);
    data.append('ClinicDiagnosis', formData.clinicdiagnosis);
    data.append('QuantityRequired', formData.quantityreq);
    data.append('BloodTransfusionType', formData.bloodtransfusiontype);
    data.append('PreviousTransfusionDate', formatDateTime(formData.previoustransfusiondate));
    data.append('DateTimeWhenRequire', formatDateTime(formData.dateandtimewhenreq));
    data.append('SampleCollectedBy', formData.samplecollectedby);
    data.append('BloodGroupOfPatients', formData.bloodgroup);
    data.append('Date', formatDateTime(formData.date));
    data.append('DrSignatureWithStamp', formData.drsig);
    data.append('RelativeName', formData.relative);
    data.append('Admitted', formData.admitted);
    data.append('GuardiansSignature', formData.guardiansig);
    data.append('SignatureOfIssueTechnician', formData.signatureofissuexmatch);
    data.append('SignatureOfMO', formData.signatureofmo);
    data.append('ReceivedInBloodBankDateTime', formData.receiveinbloodbank);
    data.append('CellGroupingAntiA', formData.antiA);
    data.append('CellGroupingAntiB', formData.antiB);
    data.append('CellGroupingAntiD', formData.antiD);
    data.append('SerumGroupingACells', formData.Acells);
    data.append('SerumGroupingBCells', formData.Bcells);
    data.append('SerumGroupingOCells', formData.Ocells);
    data.append('SerumGroupingABO', formData.abo);
    data.append('SerumGroupingRh_D', formData.rh);

    $('#patientCrossMatchReport tbody tr').each(function (i) {
      var fMember = $(this);
      data.append(`RoutineCrossMatchReport[${i}].Unitno`, fMember.find("td input#unitno").val());
      data.append(`RoutineCrossMatchReport[${i}].BloodComponent`, fMember.find("td input#BloodComponent").val());
      data.append(`RoutineCrossMatchReport[${i}].BloodGroup`, fMember.find("td input#BloodGroup").val());
      data.append(`RoutineCrossMatchReport[${i}].Compatible`, fMember.find("td input#compatible").is(":checked") ? 1 : 0);
      data.append(`RoutineCrossMatchReport[${i}].IssueNo`, fMember.find("td input#IssueNo").val());
      data.append(`RoutineCrossMatchReport[${i}].DateTime`, fMember.find("td input#dateAndTime").val());
    })

    var res = await customFormDataPost(`${apiBaseUrl}blood-bank/receivers/create/0`, data);
    if (res.status) {
      swal("Saved!", "Your Receiver form data has been Saved!", "success");
      navigate("/blood-bank/receiverform");

    }
    else {
      alert(res.messsage)
    }


  }
  return (
    <div>
      <div className="container-fluid">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to="/blood-bank/">Home</Link></li>
            <li className="breadcrumb-item"><Link to="/blood-bank/receiver">Receiver</Link></li>
            <li className="breadcrumb-item active" ><Link to="/blood-bank/receiverform">Receiver Form</Link></li>
          </ol>
        </nav>
      </div>
      <div className="container">
        <div className="card shadow mb-4">

          <div className="card-body p-0">

            <div className="row">

              <form id="receiverForm"
                asp-route="bloodbank-receiversCreate" method="post"
                onSubmit={handleSubmit(donerSubmit)}>
                <h2 className='text-center'>BLOOD REQUISITION FORM</h2>

                <input type="hidden" asp-for="Id" />
                <div className="row">
                  <div className="form-group col-md-6">
                    <label htmlFor="inputEmail4">Patient name</label>
                    <input asp-for="PatientName" type="text"
                      className="form-control" placeholder="Patient name"
                      value={formData.patientname}
                      onChange={(event) =>
                        setFormData({ ...formData, patientname: event.target.value })
                      } />
                    <span className="text-danger field-validation-valid" asp-validation-for="PatientName" data-valmsg-replace="true" />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="inputPassword4">Fathers name</label>
                    <input asp-for="FathersName" type="text"
                      className="form-control" placeholder="Fathers name"
                      value={formData.fathersname}
                      onChange={(event) =>
                        setFormData({ ...formData, fathersname: event.target.value })
                      } />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-4">
                    <label htmlFor="inputEmail4">Age</label>
                    <input asp-for="Age" type="number"
                      className="form-control" placeholder="Age"
                      value={formData.age}
                      onChange={(event) =>
                        setFormData({ ...formData, age: event.target.value })
                      } />
                    <span className="text-danger field-validation-valid" asp-validation-for="Age" data-valmsg-replace="true" />
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="inputPassword4">Gender</label>
                    <input asp-for="Gender" type="text" className="form-control"
                      placeholder="Gender"
                      value={formData.gender}
                      onChange={(event) =>
                        setFormData({ ...formData, gender: event.target.value })
                      } />
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="inputPassword4">Ward&amp;Bed No</label>
                    <input asp-for="WardAndBedNo" type="text" className="form-control"
                      placeholder="Ward&Bed No"
                      value={formData.bedandward}
                      onChange={(event) =>
                        setFormData({ ...formData, bedandward: event.target.value })
                      } />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-12">
                    <label htmlFor="inputPassword4">Address</label>
                    <textarea asp-for="Address" className="form-control"
                      placeholder="Address"
                      value={formData.address}
                      onChange={(event) =>
                        setFormData({ ...formData, address: event.target.value })
                      } />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label htmlFor="inputEmail4">Name of Hospital </label>
                    <input asp-for="NameOfHospital" type="text"
                      className="form-control" placeholder="Name of Hospital"
                      value={formData.nameofhospital}
                      onChange={(event) =>
                        setFormData({ ...formData, nameofhospital: event.target.value })
                      } />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="inputEmail4">Clinic Diagnosis </label>
                    <input asp-for="ClinicDiagnosis" type="text"
                      className="form-control" placeholder="Clinic Diagnosis"
                      value={formData.clinicdiagnosis}
                      onChange={(event) =>
                        setFormData({ ...formData, clinicdiagnosis: event.target.value })
                      } />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label htmlFor="inputEmail4">Quantity Required </label>
                    <input asp-for="QuantityRequired" type="number"
                      className="form-control" placeholder="Quantity Required "
                      value={formData.quantityreq}
                      onChange={(event) =>
                        setFormData({ ...formData, quantityreq: event.target.value })
                      } />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="inputEmail4">Blood transfusion type </label>
                    <select asp-for="BloodTransfusionType" className="form-control"
                      value={formData.bloodtransfusiontype}
                      onChange={(event) =>
                        setFormData({ ...formData, bloodtransfusiontype: event.target.value })
                      } >
                      <option value>Select type</option>
                      <option value="WholeBlood">whole-blood</option>
                      <option value="PackedRedBlood">packed-red-blood</option>
                      <option value="PlateletApheresis">platelet-apheresis</option>
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label htmlFor="inputEmail4">Previous transfusion with date</label>
                    <DatePicker 
                      className="form-control datepicker"
                      placeholder="dd/mm/yyyy"
                      selected={formData.previoustransfusiondate}
                      onChange={(value) =>
                        {setFormData({ ...formData, previoustransfusiondate: value }) } }
                      timeInputLabel="Time:"
                      dateFormat="dd-MM-yyyy h:mm aa"
                      showTimeInput/>
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="inputEmail4">Date &amp; time when required</label>
                    <DatePicker  
                      className="form-control datetime-picker"
                      placeholder="dd/mm/yyyy"
                      selected={formData.dateandtimewhenreq}
                      onChange={(value) =>
                        {setFormData({ ...formData, dateandtimewhenreq: value }) }}
                      timeInputLabel="Time:"
                      dateFormat="dd-MM-yyyy h:mm aa"
                      showTimeInput />
                      
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label htmlFor="inputEmail4">Sample collected by</label>
                    <input asp-for="SampleCollectedBy" type="text"
                      className="form-control" placeholder="Sample collected by"
                      value={formData.samplecollectedby}
                      onChange={(event) =>
                        setFormData({ ...formData, samplecollectedby: event.target.value })
                      } />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="inputEmail4">Blood group of patients </label>
                    <input asp-for="BloodGroupOfPatients" type="text"
                      className="form-control" placeholder="Blood group of patients "
                      value={formData.bloodgroup}
                      onChange={(event) =>
                        setFormData({ ...formData, bloodgroup: event.target.value })
                      } />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label htmlFor="inputEmail4">Date</label>
                    <DatePicker
                      className="form-control datepicker"
                      selected={formData.date}
                      onChange={(value) =>
                        setFormData({ ...formData, date:value })
                      } 
                      timeInputLabel="Time:"
                      dateFormat="dd-MM-yyyy h:mm aa"
                      showTimeInput/>
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="inputEmail4">Dr. signature with stamp</label>
                    <input asp-for="DrSignatureWithStamp" type="file"
                      className="form-control" placeholder="Dr. signature with stamp"
                      
                      onChange={(event) =>
                        setFormData({ ...formData, drsig: event.target.files[0] })
                      } />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 pt-5">
                    <h5 className="text-center">CONSENT/ UNDERTAKING</h5>
                    <p>From Treating Doctor: I am fully aware of the limitations of Rapid Screening Test but because of life Saving in Emergency. I request for blood unit, screened by Rapid tests or elisa Method. I am willing to undertake blood Transfusion on my own risk.</p>
                  </div>
                  <div className="col-md-12 pt-4  ">
                    <h5 className="text-center">CERTIFICATED BY THE PATIENT</h5>
                    <h6 className="text-center">(I am willing to have blood transfusion at my own risk)</h6>
                    <p>I here by solemnly declare that any official of Blood Bank Transfusion Department or authorities of PHILADELPHIA HOSPITAL BLOOD BANK will not be held responsible for any consequences what so ever on account of transfusion of Blood give to me/my.</p>
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="inputEmail4">Relative</label>
                    <input asp-for="RelativeName" type="text"
                      className="form-control datepicker" placeholder="Relative"
                      value={formData.relative}
                      onChange={(event) =>
                        setFormData({ ...formData, relative: event.target.value })
                      } />
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="inputEmail4">Admitted</label>
                    <input asp-for="Admitted" type="text"
                      className="form-control datetime-picker" placeholder="Admitted"
                      value={formData.admitted}
                      onChange={(event) =>
                        setFormData({ ...formData, admitted: event.target.value })
                      } />
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="inputEmail4">Guardian’s Signature</label>
                    <input asp-for="GuardiansSignature" type="text"
                      className="form-control datetime-picker"
                      placeholder="Guardian’s Signature"
                      value={formData.guardiansig}
                      onChange={(event) =>
                        setFormData({ ...formData, guardiansig: event.target.value })
                      } />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="p-1 mt-5 text-center border">(FOR BLOOD BANK USE ONLY)</div>
                  </div>
                  <div className="form-group col-md-6 p-4">
                    <label htmlFor="ReceivedInBloodBankDateTime">Received in blood bank date &amp; time</label>
                    <input asp-for="ReceivedInBloodBankDateTime" type="text"
                      className="form-control datetime-picker"
                      placeholder="Received in blood bank date & time"
                      value={formData.receiveinbloodbank}
                      onChange={(event) =>
                        setFormData({ ...formData, receiveinbloodbank: event.target.value })
                      } />
                  </div>
                  <div className="col-md-12">
                    <div className="p-1 text-center"><strong>Patient Blood Group</strong></div>
                  </div>
                  <div className="col-md-12 p-4">
                    <table id="patientBloodGroup" className="table table-bordered">
                      <tbody><tr>
                        <th colSpan={3}>Cell Grouping</th>
                        <th colSpan={5}>
                          Serum Grouping
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          Blood Group
                        </th>
                      </tr>
                        <tr>
                          <td>Anti A</td>
                          <td>Anti B</td>
                          <td>Anti D</td>
                          <td>A Cells</td>
                          <td>B Cells</td>
                          <td>O Cells</td>
                          <td>ABO</td>
                          <td>Rh (D)</td>
                        </tr>
                        <tr className="p-0">
                          <td><input type="text" className="form-control"
                            value={formData.antiA}
                            onChange={(event) =>
                              setFormData({ ...formData, antiA: event.target.value })
                            } /></td>
                          <td><input type="text" className="form-control"
                            value={formData.antiB}
                            onChange={(event) =>
                              setFormData({ ...formData, antiB: event.target.value })
                            } /></td>
                          <td><input type="text" className="form-control"
                            value={formData.antiD}
                            onChange={(event) =>
                              setFormData({ ...formData, antiD: event.target.value })
                            } /></td>
                          <td><input type="text" className="form-control"
                            value={formData.Acells}
                            onChange={(event) =>
                              setFormData({ ...formData, Acells: event.target.value })
                            } /></td>
                          <td><input type="text" className="form-control"
                            value={formData.Bcells}
                            onChange={(event) =>
                              setFormData({ ...formData, Bcells: event.target.value })
                            } /></td>
                          <td><input type="text" className="form-control"
                            value={formData.Ocells}
                            onChange={(event) =>
                              setFormData({ ...formData, Ocells: event.target.value })
                            } /></td>
                          <td><input type="text" className="form-control"
                            value={formData.abo}
                            onChange={(event) =>
                              setFormData({ ...formData, abo: event.target.value })
                            } /></td>
                          <td><input type="text" className="form-control"
                            value={formData.rh}
                            onChange={(event) =>
                              setFormData({ ...formData, rh: event.target.value })
                            } /></td>
                        </tr>
                      </tbody></table>
                  </div>
                  <div className="col-md-12">
                    <div className="p-1 text-center"><strong>ROUTINE/IMMEDATE CROSS MATCH REPORT</strong></div>
                  </div>
                  <div className="col-md-12 p-4">
                    <table id="patientCrossMatchReport" className="table table-bordered">
                      <thead><tr>
                        <th>Unit no.</th>
                        <th>Blood Component</th>
                        <th>Blood Group</th>
                        <th>Compatible (Y/N)</th>
                        <th>Issue no.</th>
                        <th>Date &amp; Time</th>
                      </tr>
                      </thead>
                      <tbody>
                        <tr className="p-0">
                          <td><input type="text" className="form-control" id="unitno" /></td>
                          <td><input type="text" className="form-control" id="BloodComponent" /></td>
                          <td><input type="text" className="form-control" id="BloodGroup" /></td>
                          <td className="text-center"><input type="checkbox" id="compatible" /></td>
                          <td><input type="text" className="form-control" id="IssueNo" /></td>
                          <td><input type="text" className="form-control" id="dateAndTime" /></td>
                        </tr>
                        <tr className="p-0">
                          <td><input type="text" className="form-control" id="unitno" /></td>
                          <td><input type="text" className="form-control" id="BloodComponent" /></td>
                          <td><input type="text" className="form-control" id="BloodGroup" /></td>
                          <td className="text-center"><input type="checkbox" id="compatible" /></td>
                          <td><input type="text" className="form-control" id="IssueNo" /></td>
                          <td><input type="text" className="form-control" id="dateAndTime" /></td>
                        </tr>
                        <tr className="p-0">
                          <td><input type="text" className="form-control" id="unitno" /></td>
                          <td><input type="text" className="form-control" id="BloodComponent" /></td>
                          <td><input type="text" className="form-control" id="BloodGroup" /></td>
                          <td className="text-center"><input type="checkbox" id="compatible" /></td>
                          <td><input type="text" className="form-control" id="IssueNo" /></td>
                          <td><input type="text" className="form-control" id="dateAndTime" /></td>
                        </tr>
                      </tbody></table>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label htmlFor="ReceivedInBloodBankDateTime">Signature of issue &amp; X-Match Technician</label>
                    <input asp-for="SignatureOfIssueTechnician" type="file"
                      className="form-control datetime-picker"
                      placeholder="Signature of issue & X-Match Technician"
                    
                      onChange={(event) =>
                        setFormData({ ...formData, signatureofissuexmatch: event.target.files[0] })
                      } />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="ReceivedInBloodBankDateTime">Signature of M.O.</label>
                    <input asp-for="SignatureOfMO" type="file"
                      className="form-control datetime-picker"
                      placeholder="Signature of M.O."
                      
                      onChange={(event) =>
                        setFormData({ ...formData, signatureofmo: event.target.files[0] })
                      } />
                  </div>
                </div>
                <button type="submit" id="saveReceiver" className="btn btn-primary">Save</button>
              </form>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Receiverform
