import React from 'react';
import Navbar from './bloodbankpages/Navbar';
import Sidepanel from './bloodbankpages/Sidepanel';
import Panel from './bloodbankpages/Panel';
import Footer from './bloodbankpages/Footer';
import DonerList from './bloodbankpages/DonerList';
import Adddonerform from './bloodbankpages/Adddonerform';
import Receiver from './bloodbankpages/Receiver';
import Receiverform from './bloodbankpages/Receiverform';
import Stockrecord from './bloodbankpages/Stockrecord';
import {Route,Routes} from "react-router-dom";


function Bloodbank() {
  return (
    
    <div id="wrapper"><Sidepanel/>
 <div id="content-wrapper" className="d-flex flex-column">
 <div id="content">
  <Navbar/>
  
  <Routes>
  <Route path="/"  element={<Panel/>} />
  <Route  path="/donerlist"  element={<DonerList/>} />
  <Route  path="/donerform"  element={<Adddonerform/>} />
  <Route  path="/receiver"  element={<Receiver/>} />
  <Route  path="/receiverform"  element={<Receiverform/>} />
  <Route  path="/stockrecord"  element={<Stockrecord/>} />
  
  
  </Routes>
  <Footer/>
 </div>
 </div>
 </div>
 
  )
}

export default Bloodbank
