import React from 'react';
import { Link } from "react-router-dom";

export default function Sidepanel() {
  return (
    <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
      {/* Sidebar - Brand */}
      <img className="sidebar-card-illustration mb-2" src="/Imgs/admin.png" alt="..." />
      <span className="sidebar-brand d-flex align-items-center justify-content-center" >
        <div className="sidebar-brand-icon rotate-n-15">
          <i className="fas fa-laugh-wink" />
        </div>
        <div className="sidebar-brand-text mx-3">OPD Panel</div>
      </span>
      {/* Divider */}
      <hr className="sidebar-divider my-0" />
      {/* Nav Item - Dashboard */}
      <li className="nav-item active">
        <span className="nav-link" >
          <i className="fas fa-fw fa-tachometer-alt" />
          <span>Dashboard</span></span>
      </li>
      <hr className="sidebar-divider" />
      <div className="sidebar-heading">
        Interface
      </div>
      <li className="nav-item">
        <span className="nav-link collapsed"  aria-expanded="true" aria-controls="collapseTwo">
          <i className="fas fa-fw fa-cog" />
          <span><Link className="text-white" to="/opd">OPD Registration Form</Link></span>
        </span>
        
      </li>
      {/* Nav Item - Utilities Collapse Menu */}
      <li className="nav-item">
        <span className="nav-link collapsed" data-toggle="collapse" data-target="#collapseUtilities" aria-expanded="true" aria-controls="collapseUtilities">
          <i className="fas fa-fw fa-wrench" />
          <span><Link className="text-white" to="/opd/add">OPD 2nd Step</Link></span>
        </span>
      </li>
      <li className="nav-item">
        <span className="nav-link collapsed" data-toggle="collapse" data-target="#collapseUtilities" aria-expanded="true" aria-controls="collapseUtilities">
          <i className="fas fa-user" />
          <span><Link className="text-white" to="/opd">Patients</Link></span>
        </span>
      </li>




    </ul>


  )
}
