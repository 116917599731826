import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { customFormDataPost } from '../../Helper/api-helper';
import swal from 'sweetalert';
import { apiBaseUrl } from "../../../global/credentials";


function IpdBilling() {
    const { handleSubmit } = useForm();
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    const [formData, setFormData] = useState({
         FinalBill :"",
         PeriodFrom :"",
         PeriodTo :"",
         TotalDays :"",
         Dressings :"",
         Injections :"",
         Operations :"",
         Delivery :"",
         Consultation :"",
         Laboratory :"",
         CRSSupplies :"",
         LocalHeat :"",
         FansHeater :"",
         Electricity :"",
         XRays :"",
         MedicalCare :"",
         GeneralNursing :"",
         DoctorVisit :"",
         ICUCharges :"",
         Incubators :"",
         ElectricBlanketPad :"",
         Oxygen :"",
         CardiacMassage :"",
         ArtificialRespiration :"",
         IVSets :"",
         ECG :"",
         BloodTransfusion :"",
         Miscllaneous :"",
         SignatureOfBillingClerk :"",
         BillNumber :"",
         BillDate :"",
         BillAmount :"",
         PreviousBillNumber :"",
         PreviousBillDate :"",
         PreviousBillAmount :"",
         ReceiptNumber :"",
         AdditionalInformation :"",
         OutPatientRecord :"",
         NameOfOperation :"",
         PreOperativeDiagnosis :"",
         IndicationForOperation :"",
         FinalOperativeDiagnosis :"",
         OperativeFindings :"",
         OperativeProcedure :"",
         BloodLoss :"",
         DrainInSitu :"",
         PackInSitu :"",
         IVInprogress :"",
         SurgeonsSignature :"",
         AssistantSignature :"",
         GivingConcentForOperation :"",
         ConcentForPatientName :"",
         ConcentFormWitness :"",
         ConcentFormWitnessSignature :"",
         ChargesForTheBill :"",
         SignatureOfPersonGivingCharges :"",
         CertifyThatChartConatainPageToWrite :"",
         SignatureToCertify:"",

    });
    const [patientHistory, setPatientHistory] = useState([{}]);
    const addHistory = (e) => {
        setPatientHistory([...patientHistory, [{}]])
    }
   
    const IpdSubmit = async () => {

        var data = new FormData();
        data.append('FinalBill', formData.FinalBill);
        data.append('PeriodFrom', formData.PeriodFrom);
        data.append('PeriodTo', formData.PeriodTo);
        data.append('TotalDays', formData.TotalDays);
        data.append('Dressings', formData.Dressings);
        data.append('Injections', formData.Injections);
        data.append('Operations', formData.Operations);
        data.append('Delivery', formData.Delivery);
        data.append('Consultation', formData.Consultation);
        data.append('Laboratory', formData.Laboratory);
        data.append('CRSSupplies', formData.CRSSupplies);
        data.append('LocalHeat', formData.LocalHeat);
        data.append('FansHeater', formData.FansHeater);
        data.append('Electricity', formData.Electricity);
        data.append('XRays', formData.XRays);
        data.append('MedicalCare', formData.MedicalCare);
        data.append('GeneralNursing', formData.GeneralNursing);
        data.append('DoctorVisit', formData.DoctorVisit);
        data.append('FathersName', formData.fathersname);
        data.append('FathersName', formData.fathersname);
        data.append('FathersName', formData.fathersname);
        data.append('FathersName', formData.fathersname);
        data.append('FathersName', formData.fathersname);
        data.append('FathersName', formData.fathersname);
        data.append('FathersName', formData.fathersname);
        data.append('FathersName', formData.fathersname);
        data.append('FathersName', formData.fathersname);
        data.append('FathersName', formData.fathersname);
        data.append('FathersName', formData.fathersname);
        data.append('FathersName', formData.fathersname);
        data.append('FathersName', formData.fathersname);
        data.append('FathersName', formData.fathersname);
        data.append('FathersName', formData.fathersname);
        data.append('FathersName', formData.fathersname);
        data.append('FathersName', formData.fathersname);
        data.append('FathersName', formData.fathersname);
        data.append('FathersName', formData.fathersname);
        data.append('FathersName', formData.fathersname);
        data.append('FathersName', formData.fathersname);
        data.append('FathersName', formData.fathersname);
        data.append('FathersName', formData.fathersname);
        data.append('FathersName', formData.fathersname);
        data.append('FathersName', formData.fathersname);
        data.append('FathersName', formData.fathersname);
        data.append('FathersName', formData.fathersname);
        data.append('FathersName', formData.fathersname);
        data.append('FathersName', formData.fathersname);
        data.append('FathersName', formData.fathersname);
        data.append('FathersName', formData.fathersname);
        data.append('FathersName', formData.fathersname);
        data.append('FathersName', formData.fathersname);
        data.append('FathersName', formData.fathersname);
        data.append('FathersName', formData.fathersname);
        data.append('FathersName', formData.fathersname);
        data.append('FathersName', formData.fathersname);
        data.append('FathersName', formData.fathersname);
        data.append('FathersName', formData.fathersname);
        data.append('FathersName', formData.fathersname);
        data.append('FathersName', formData.fathersname);
        data.append('FathersName', formData.fathersname);
        data.append('FathersName', formData.fathersname);
        data.append('FathersName', formData.fathersname);
        data.append('FathersName', formData.fathersname);
        data.append('FathersName', formData.fathersname);
        data.append('FathersName', formData.fathersname);
        data.append('FathersName', formData.fathersname);
        data.append('FathersName', formData.fathersname);
        data.append('FathersName', formData.fathersname);
        data.append('FathersName', formData.fathersname);
        data.append('FathersName', formData.fathersname);
        data.append('FathersName', formData.fathersname);
        data.append('FathersName', formData.fathersname);
        data.append('FathersName', formData.fathersname);
        data.append('FathersName', formData.fathersname);
        data.append('FathersName', formData.fathersname);



        var res = await customFormDataPost(`${apiBaseUrl}blood-bank/receivers/create/0`, data);
        if (res.status) {
            swal("Saved!", "Your Receiver form data has been Saved!", "success");

        }
        else {
            alert(res.messsage)
        }


    }
    return (

        <div>
            <div className="container">
                <div className="card shadow mb-4">
                    <div className="card-body p-4">
                        <h2 className='text-center'>INFORMATION FOR PATIENT BILLING</h2>
                          <div className="row mx-4 my-4">
                            <form id="receiverForm p-8" onSubmit={handleSubmit(IpdSubmit)}>
                                <div className="form-row">
                                    <div className="form-group col-md-3">
                                        <label htmlFor="inputPassword4">Period/Final Bill</label>
                                        <input asp-for="WellBeing" type="text" className="form-control"
                                            placeholder="Well being"
                                            value={formData.FinalBill}
                                            onChange={(event) =>
                                                setFormData({ ...formData, FinalBill: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label htmlFor="inputEmail4">Period From</label>
                                        <input asp-for="Phone" type="text" className="form-control"
                                            placeholder="Phone"
                                            value={formData.PeriodFrom}
                                            onChange={(event) =>
                                                setFormData({ ...formData, PeriodFrom: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label htmlFor="inputEmail4">Period To</label>
                                        <input asp-for="Phone" type="text" className="form-control"
                                            placeholder="Phone"
                                            value={formData.PeriodTo}
                                            onChange={(event) =>
                                                setFormData({ ...formData, PeriodTo: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label htmlFor="inputEmail4">Total Days</label>
                                        <input asp-for="Phone" type="text" className="form-control"
                                            placeholder="Phone"
                                            value={formData.TotalDays}
                                            onChange={(event) =>
                                                setFormData({ ...formData, TotalDays: event.target.value })
                                            } />
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="form-group col-md-3">
                                        <label htmlFor="inputPassword4">Dressings</label>
                                        <input asp-for="WellBeing" type="text" className="form-control"
                                            placeholder="Well being"
                                            value={formData.Dressings}
                                            onChange={(event) =>
                                                setFormData({ ...formData, Dressings: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label htmlFor="inputEmail4">Injections </label>
                                        <input asp-for="Phone" type="text" className="form-control"
                                            placeholder="Phone"
                                            value={formData.Injections}
                                            onChange={(event) =>
                                                setFormData({ ...formData, Injections: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label htmlFor="inputEmail4">Operations</label>
                                        <input asp-for="Phone" type="text" className="form-control"
                                            placeholder="Phone"
                                            value={formData.Operations}
                                            onChange={(event) =>
                                                setFormData({ ...formData, Operations: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label htmlFor="inputEmail4">Delivery</label>
                                        <input asp-for="Phone" type="text" className="form-control"
                                            placeholder="Phone"
                                            value={formData.Delivery}
                                            onChange={(event) =>
                                                setFormData({ ...formData, Delivery: event.target.value })
                                            } />
                                    </div>
                                    </div>

                                <div className="form-row">
                                    <div className="form-group col-md-2">
                                        <label htmlFor="inputPassword4">Consultation</label>
                                        <input asp-for="WellBeing" type="text" className="form-control"
                                            placeholder="Well being"
                                            value={formData.Consultation}
                                            onChange={(event) =>
                                                setFormData({ ...formData, Consultation: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-2">
                                        <label htmlFor="inputEmail4">Laboratory</label>
                                        <input asp-for="Phone" type="text" className="form-control"
                                            placeholder="Phone"
                                            value={formData.Laboratory}
                                            onChange={(event) =>
                                                setFormData({ ...formData, Laboratory: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-2">
                                        <label htmlFor="inputEmail4">C.R.S Supplies</label>
                                        <input asp-for="Phone" type="text" className="form-control"
                                            placeholder="Phone"
                                            value={formData.CRSSupplies}
                                            onChange={(event) =>
                                                setFormData({ ...formData, CRSSupplies: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-2">
                                        <label htmlFor="inputEmail4">Local heat</label>
                                        <input asp-for="Phone" type="text" className="form-control"
                                            placeholder="Phone"
                                            value={formData.LocalHeat}
                                            onChange={(event) =>
                                                setFormData({ ...formData, LocalHeat: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-2">
                                        <label htmlFor="inputEmail4">Fans/Heater</label>
                                        <input asp-for="Phone" type="text" className="form-control"
                                            placeholder="Phone"
                                            value={formData.FansHeater}
                                            onChange={(event) =>
                                                setFormData({ ...formData, FansHeater: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-2">
                                        <label htmlFor="inputEmail4">Electricity</label>
                                        <input asp-for="Phone" type="text" className="form-control"
                                            placeholder="Phone"
                                            value={formData.Electricity}
                                            onChange={(event) =>
                                                setFormData({ ...formData, Electricity: event.target.value })
                                            } />
                                    </div>
                                </div>

                                

                                <div className="form-row">
                                    <div className="form-group col-md-3">
                                        <label htmlFor="inputEmail4">X-Ray</label>
                                        <input asp-for="Phone" type="text" className="form-control"
                                            placeholder="Phone"
                                            value={formData.XRays}
                                            onChange={(event) =>
                                                setFormData({ ...formData, XRays: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label htmlFor="inputPassword4">Medical care</label>
                                        <input asp-for="WellBeing" type="text" className="form-control"
                                            placeholder="Well being"
                                            value={formData.MedicalCare}
                                            onChange={(event) =>
                                                setFormData({ ...formData, MedicalCare: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label htmlFor="inputEmail4">General Nursing</label>
                                        <input asp-for="Phone" type="text" className="form-control"
                                            placeholder="Phone"
                                            value={formData.GeneralNursing}
                                            onChange={(event) =>
                                                setFormData({ ...formData, GeneralNursing: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label htmlFor="inputEmail4">Doctor Visit</label>
                                        <input asp-for="Phone" type="text" className="form-control"
                                            placeholder="Phone"
                                            value={formData.DoctorVisit}
                                            onChange={(event) =>
                                                setFormData({ ...formData, DoctorVisit: event.target.value })
                                            } />
                                    </div>
                                    </div>

                                <div className="form-row">
                                    <div className="form-group col-md-3">
                                        <label htmlFor="inputEmail4">I.C.U Charges</label>
                                        <input asp-for="Phone" type="text" className="form-control"
                                            placeholder="Phone"
                                            value={formData.ICUCharges}
                                            onChange={(event) =>
                                                setFormData({ ...formData, ICUCharges: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label htmlFor="inputPassword4">Incubators</label>
                                        <input asp-for="WellBeing" type="text" className="form-control"
                                            placeholder="Well being"
                                            value={formData.Incubators}
                                            onChange={(event) =>
                                                setFormData({ ...formData, Incubators: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label htmlFor="inputEmail4">Electric Blanket Pad</label>
                                        <input asp-for="Phone" type="text" className="form-control"
                                            placeholder="Phone"
                                            value={formData.ElectricBlanketPad}
                                            onChange={(event) =>
                                                setFormData({ ...formData, ElectricBlanketPad: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label htmlFor="inputEmail4">Oxygen</label>
                                        <input asp-for="Phone" type="text" className="form-control"
                                            placeholder="Phone"
                                            value={formData.Oxygen}
                                            onChange={(event) =>
                                                setFormData({ ...formData, Oxygen: event.target.value })
                                            } />
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="form-group col-md-3">
                                        <label htmlFor="inputEmail4">Cardiac Massage</label>
                                        <input asp-for="Phone" type="text" className="form-control"
                                            placeholder="Phone"
                                            value={formData.CardiacMassage}
                                            onChange={(event) =>
                                                setFormData({ ...formData, CardiacMassage: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label htmlFor="inputPassword4">Artificial Respiration</label>
                                        <input asp-for="WellBeing" type="text" className="form-control"
                                            placeholder="Well being"
                                            value={formData.ArtificialRespiration}
                                            onChange={(event) =>
                                                setFormData({ ...formData, ArtificialRespiration: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label htmlFor="inputEmail4">I.V. Sets</label>
                                        <input asp-for="Phone" type="text" className="form-control"
                                            placeholder="Phone"
                                            value={formData.IVSets}
                                            onChange={(event) =>
                                                setFormData({ ...formData, IVSets: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label htmlFor="inputEmail4">E.C.G</label>
                                        <input asp-for="Phone" type="text" className="form-control"
                                            placeholder="Phone"
                                            value={formData.ECG}
                                            onChange={(event) =>
                                                setFormData({ ...formData, ECG: event.target.value })
                                            } />
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="form-group col-md-4">
                                        <label htmlFor="inputPassword4">Blood Transfusion</label>
                                        <input asp-for="WellBeing" type="text" className="form-control"
                                            placeholder="Well being"
                                            value={formData.BloodTransfusion}
                                            onChange={(event) =>
                                                setFormData({ ...formData, BloodTransfusion: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label htmlFor="inputEmail4">Misc.</label>
                                        <input asp-for="Phone" type="text" className="form-control"
                                            placeholder="Phone"
                                            value={formData.Miscllaneous}
                                            onChange={(event) =>
                                                setFormData({ ...formData, Miscllaneous: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label htmlFor="inputEmail4">Signature of Billing Clerk</label>
                                        <input asp-for="Phone" type="text" className="form-control"
                                            placeholder="Phone"
                                            value={formData.SignatureOfBillingClerk}
                                            onChange={(event) =>
                                                setFormData({ ...formData, SignatureOfBillingClerk: event.target.value })
                                            } />
                                    </div>

                                </div>

                                <h3 className='text-center my-2'>FOR OFFICE USE ONLY</h3>

                                <div className="form-row">
                                    <div className="form-group col-md-4">
                                        <label htmlFor="inputPassword4">Bill No.</label>
                                        <input asp-for="WellBeing" type="text" className="form-control"
                                            placeholder="Well being"
                                            value={formData.BillNumber}
                                            onChange={(event) =>
                                                setFormData({ ...formData, BillNumber: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label htmlFor="inputEmail4">Date</label>
                                        <input asp-for="Phone" type="text" className="form-control"
                                            placeholder="Phone"
                                            value={formData.BillDate}
                                            onChange={(event) =>
                                                setFormData({ ...formData, BillDate: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label htmlFor="inputEmail4">Amount</label>
                                        <input asp-for="Phone" type="text" className="form-control"
                                            placeholder="Phone"
                                            value={formData.BillAmount}
                                            onChange={(event) =>
                                                setFormData({ ...formData, BillAmount: event.target.value })
                                            } />
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="form-group col-md-4">
                                        <label htmlFor="inputPassword4">Previous Bill No.</label>
                                        <input asp-for="WellBeing" type="text" className="form-control"
                                            placeholder="Well being"
                                            value={formData.PreviousBillNumber}
                                            onChange={(event) =>
                                                setFormData({ ...formData, PreviousBillNumber: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label htmlFor="inputEmail4">Date</label>
                                        <input asp-for="Phone" type="text" className="form-control"
                                            placeholder="Phone"
                                            value={formData.PreviousBillDate}
                                            onChange={(event) =>
                                                setFormData({ ...formData, PreviousBillDate: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label htmlFor="inputEmail4">Amount</label>
                                        <input asp-for="Phone" type="text" className="form-control"
                                            placeholder="Phone"
                                            value={formData.PreviousBillAmount}
                                            onChange={(event) =>
                                                setFormData({ ...formData, PreviousBillAmount: event.target.value })
                                            } />
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="form-group col-md-4">
                                        <label htmlFor="inputPassword4">Receipt No.</label>
                                        <input asp-for="WellBeing" type="text" className="form-control"
                                            placeholder="Well being"
                                            value={formData.ReceiptNumber}
                                            onChange={(event) =>
                                                setFormData({ ...formData, ReceiptNumber: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label htmlFor="inputEmail4">Date</label>
                                        <input asp-for="Phone" type="text" className="form-control"
                                            placeholder="Phone"
                                            value={formData.date}
                                            onChange={(event) =>
                                                setFormData({ ...formData, date: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label htmlFor="inputEmail4">Sig. of Billing Clerk</label>
                                        <input asp-for="Phone" type="text" className="form-control"
                                            placeholder="Phone"
                                            value={formData.SignatureOfBillingClerk}
                                            onChange={(event) =>
                                                setFormData({ ...formData, SignatureOfBillingClerk: event.target.value })
                                            } />
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-md-12 my-4">
                                        <table id="PatientFamilyMembers" className="table table-bordered">
                                            <thead>
                                                <tr >
                                                    <th >MEDICINE GIVEN </th>
                                                    <th >INJECTION GIVEN</th>

                                                </tr>
                                            </thead>
                                            <tbody>

                                                {patientHistory.map((member, index) => (
                                                    <tr key={index} className="p-0">
                                                        <td className="p-0"><input id="MemberName" type="text" style={{ border: "none" }} className="form-control" /></td>
                                                        <td className="p-0"><input id="MemberRelationToHead" style={{ border: "none" }} type="text" className="form-control" /></td>
                                                    </tr>))}
                                            </tbody>

                                        </table>
                                        <div className='d-flex justify-content-center m-2' >
                                            <button type="button" onClick={addHistory} className="btn btn-primary ">Add</button>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-row my-2">
                                    <div className="form-group col-md-12">
                                        <label htmlFor="inputPassword4">Use this space for 
                                        any additional information in regard to Patient's Bill</label>
                                        <textarea aria-rowcount={4} type="text" className="form-control"
                                            placeholder="Well being"
                                            value={formData.AdditionalInformation}
                                            onChange={(event) =>
                                                setFormData({ ...formData, AdditionalInformation: event.target.value })
                                            } />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-12">
                                        <label htmlFor="inputPassword4">Out patient Record</label>
                                        <textarea aria-rowcount={4} type="text" className="form-control"
                                            placeholder="Well being"
                                            value={formData.OutPatientRecord}
                                            onChange={(event) =>
                                                setFormData({ ...formData, OutPatientRecord: event.target.value })
                                            } />
                                    </div>
                                </div>

                                <h3 className='text-center my-2'>OPERATION RECORD</h3>
                                <div className="form-row">
                                    <div className="form-group col-md-3">
                                        <label htmlFor="inputPassword4">Name of Operation</label>
                                        <input asp-for="WellBeing" type="text" className="form-control"
                                            placeholder="Well being"
                                            value={formData.NameOfOperation}
                                            onChange={(event) =>
                                                setFormData({ ...formData, NameOfOperation: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label htmlFor="inputPassword4">Pre-operative Diagnosis</label>
                                        <input asp-for="WellBeing" type="text" className="form-control"
                                            placeholder="Well being"
                                            value={formData.PreOperativeDiagnosis}
                                            onChange={(event) =>
                                                setFormData({ ...formData, PreOperativeDiagnosis: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label htmlFor="inputPassword4">Indication for operation</label>
                                        <input asp-for="WellBeing" type="text" className="form-control"
                                            placeholder="Well being"
                                            value={formData.IndicationForOperation}
                                            onChange={(event) =>
                                                setFormData({ ...formData, IndicationForOperation: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label htmlFor="inputPassword4">Final operative Diagnosis</label>
                                        <input asp-for="WellBeing" type="text" className="form-control"
                                            placeholder="Well being"
                                            value={formData.FinalOperativeDiagnosis}
                                            onChange={(event) =>
                                                setFormData({ ...formData, FinalOperativeDiagnosis: event.target.value })
                                            } />
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="form-group col-md-12">
                                        <label htmlFor="inputPassword4">Operative Findings</label>
                                        <textarea aria-rowcount={4} type="text" className="form-control"
                                            placeholder="Well being"
                                            value={formData.OperativeFindings}
                                            onChange={(event) =>
                                                setFormData({ ...formData, OperativeFindings: event.target.value })
                                            } />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-12">
                                        <label htmlFor="inputPassword4">Operative Procedure</label>
                                        <textarea aria-rowcount={4} type="text" className="form-control"
                                            placeholder="Well being"
                                            value={formData.OperativeProcedure}
                                            onChange={(event) =>
                                                setFormData({ ...formData, OperativeProcedure: event.target.value })
                                            } />
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="form-group col-md-3">
                                        <label >Blood Loss</label>
                                        <input  type="text" className="form-control"
                                            placeholder="Well being"
                                            value={formData.BloodLoss}
                                            onChange={(event) =>
                                                setFormData({ ...formData,BloodLoss: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label >Drain in Situ</label>
                                        <input type="text" className="form-control"
                                            placeholder="Well being"
                                            value={formData.DrainInSitu}
                                            onChange={(event) =>
                                                setFormData({ ...formData, DrainInSitu: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label >Pack in Situ</label>
                                        <input  type="text" className="form-control"
                                            placeholder="Well being"
                                            value={formData.PackInSitu}
                                            onChange={(event) =>
                                                setFormData({ ...formData, PackInSitu: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label >I.V in Progress</label>
                                        <input type="text" className="form-control"
                                            placeholder="Well being"
                                            value={formData.IVInprogress}
                                            onChange={(event) =>
                                                setFormData({ ...formData, IVInprogress: event.target.value })
                                            } />
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label >Surgeon's full Signature</label>
                                        <input  type="text" className="form-control"
                                            placeholder="Well being"
                                            value={formData.SurgeonsSignature}
                                            onChange={(event) =>
                                                setFormData({ ...formData, SurgeonsSignature: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label >Assistants Signature</label>
                                        <input  type="text" className="form-control"
                                            placeholder="Well being"
                                            value={formData.AssistantSignature}
                                            onChange={(event) =>
                                                setFormData({ ...formData, AssistantSignature: event.target.value })
                                            } />
                                    </div>
                                </div>

                                <h3 className='text-center my-4'>CONSENT FOR OPERATION</h3>

                                
                                <div className="form-row">
                                    <div className="form-group col-md-3">
                                        <label>I</label>
                                        <input type="text" className="form-control"
                                            placeholder="Well being"
                                            value={formData.ConcentForPatientName}
                                            onChange={(event) =>
                                                setFormData({ ...formData, ConcentForPatientName: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label >give consent for my</label>
                                        <input  type="text" className="form-control"
                                            placeholder="Well being"
                                            value={formData.GivingConcentForOperation}
                                            onChange={(event) =>
                                                setFormData({ ...formData, GivingConcentForOperation: event.target.value })
                                            } />
                                    </div>
                                    <p className="col-md-6">  to have an operation in PHILADELPHIA MISSION HOSPITAL
                                        , we hold hospital and staff in no way responsible. All the complications including
                                        Fatal out come have been explained.  </p>
                                </div>
                               
                               
                                <div className="form-row my-4">
                                    <div className="form-group col-md-6">
                                        <label>Witness</label>
                                        <input type="file" className="form-control"
                                            placeholder="choose"
                                            value={formData.ConcentFormWitness}
                                            onChange={(event) =>
                                                setFormData({ ...formData, ConcentFormWitness: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label >Signature</label>
                                        <input type="file" className="form-control"
                                            placeholder="chose"
                                            value={formData.ConcentFormWitnessSignature}
                                            onChange={(event) =>
                                                setFormData({ ...formData, ConcentFormWitnessSignature: event.target.value })
                                            } />
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label >Charges</label>
                                        <input type="text" className="form-control"
                                            placeholder="choose"
                                            value={formData.ChargesForTheBill}
                                            onChange={(event) =>
                                                setFormData({ ...formData, ChargesForTheBill: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label >Signature</label>
                                        <input type="file" className="form-control"
                                            placeholder="chose"
                                            value={formData.SignatureOfPersonGivingCharges}
                                            onChange={(event) =>
                                                setFormData({ ...formData, SignatureOfPersonGivingCharges: event.target.value })
                                            } />
                                    </div>
                                </div>


                                <h3 className='text-center my-4'>THIS IS TO CERTIFY THAT THIS CHART CONTAIN PAGES AS ON </h3>

                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label> Write</label>
                                        <input type="text" className="form-control"
                                            placeholder="choose"
                                            value={formData.CertifyThatChartConatainPageToWrite}
                                            onChange={(event) =>
                                                setFormData({ ...formData, CertifyThatChartConatainPageToWrite: event.target.value })
                                            } />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label >Signature</label>
                                        <input type="file" className="form-control"
                                            placeholder="chose"
                                            value={formData.SignatureToCertify}
                                            onChange={(event) =>
                                                setFormData({ ...formData, SignatureToCertify: event.target.value })
                                            } />
                                    </div>
                                </div>





                                <div className="d-flex justify-content-center">
                                    <button type="button" id="saveReceiver" className="btn btn-primary">Save</button>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IpdBilling

