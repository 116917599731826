import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import swal from 'sweetalert';


function Login() {

  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit1 = async (data) => {
    localStorage.setItem("userid", data.user)
    //e.preventDefault();
    if (data.user === "admin12@gmail.com") {
      swal("Correct", "Here is your Dashboard", "success");
      navigate('/blood-bank');


    }
    else if (data.user === "survey12@gmail.com") {
      swal("Correct", "Here is your Dashboard", "success");
      navigate('/survey');
    }
    else if (data.user === "ipd12@gmail.com") {
      swal("Correct", "Here is your Dashboard", "success");
      navigate('/ipd');
    }
    else if (data.user === "opd12@gmail.com") {
      swal("Correct", "Here is your Dashboard", "success");
      navigate('/opd');
    }
    else if (data.user === "doctor12@gmail.com") {
      swal("Correct", "Here is your Dashboard", "success");
      navigate('/doctor');
    }
    else if (data.user === "director12@gmail.com") {
      swal("Correct", "Here is your Dashboard", "success");
      navigate('/director');


    }

    else {
      swal("Wrong!", "Incoreect password or Email", "Danger");
      console.log("wrong");

    }
  };
  return (
    <div>
      <div className=" container-xxl position-relative backbg d-flex p-0 align-items-center">
        <div className='container-fluid align-items-center'>
          <div className="row h-100 align-items-center justify-content-center minheight">
            <div className="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-4 login-panel-border rounded" >
              <div className="bg-light rounded">
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <h3 className="text-primary"><i className="fa fa-user-circle-o me-3"></i>SIGN IN</h3>

                </div>
                <form onSubmit={handleSubmit(handleSubmit1)}>

                  <div className="form-floating mb-3">
                    <input type="text" id="floatingInput" className="form-control"
                      {...register("user", { required: true, maxLength: 50 })}
                      onChange={(e) => setUser(e.target.value)} value={user} />
                    <label for="floatingInput" >User Name </label>
                  </div>

                  <div className="form-floating mb-3">
                    <input type="password" id="floatingInput"
                      {...register("password", { required: true, maxLength: 20 })}
                      className="form-control" onChange={(e) => setPassword(e.target.value)} value={password} />
                    <label for="floatingInput"  >Enter Password </label><br />
                  </div>
                  <button type="submit" className="btn btn-primary">
                    Login
                  </button>
                </form>


              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
