import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { customFetch } from '../../Helper/api-helper';
import { useForm } from "react-hook-form";
import { customFormDataPost } from '../../Helper/api-helper';
import swal from 'sweetalert';
import { Route, Link, Routes, useParams } from 'react-router-dom';
import { apiBaseUrl } from '../../../global/credentials'

function OpdRegister() {
    let navigate = useNavigate();
    const params = useParams();
    const { handleSubmit } = useForm();
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    const [formData, setFormData] = useState({
        BloodPressure: "",
        PulseRate: "",
        Spo: "",
        Bmi: "",
        InTime: "",
        Ipdcheck: false,
        IpdId: "",
        AssessmentTime: "",
        OutTime: "",
        DiagnosInvestigationDetail: "",
        TimeOfBilling: "",
        StaffSignature: "",
        IpdId: "",


    });

    const createIpdId = async (event) => {
        
        if (event.target.checked) {
            var res = await customFetch(`${apiBaseUrl}opd/create-ipdid`);
            if (res)
                setFormData({ ...formData, IpdId: res.data, Ipdcheck: true });
        }
        else {

            setFormData({ ...formData, IpdId: '', Ipdcheck: false })
        }
    }
    const opdSubmit = async () => {

        var data = new FormData();
        data.append('id', params.id);
        data.append('BloodPressure', formData.BloodPressure);
        data.append('PulseRate', formData.PulseRate);
        data.append('Spo2', formData.Spo);
        data.append('Bmi', formData.Bmi);
        data.append('Pain', formData.Pain);
        data.append('InTime', formData.InTime);
        data.append('Ipdid', formData.IpdId);
        data.append('AssessmentTime', formData.AssessmentTime);
        data.append('OutTime', formData.OutTime);
        data.append('DiagnoseInvestigationDetails', formData.DiagnosInvestigationDetail);
        data.append('time_of_investigation_of_billing', formData.TimeOfBilling);
        data.append('StaffSignadute', formData.StaffSignature);
        data.append('status', "completed");


        var res = await customFormDataPost(`${apiBaseUrl}opd/update-patient`, data);
        if (res.status) {
            swal("Saved!", "Your Receiver form data has been Saved!", "success");
            navigate("/opd/add");
        }
        else {
            alert(res.message)
        }


    }

    return (
        <div>
            <div className="container-fluid">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/opd">Opd Form</Link></li>
                        <li className="breadcrumb-item"><Link to="/opd/add">Opd Data</Link></li>
                        <li className="breadcrumb-item active" aria-current="page"><Link to="/opd/register/:id">Opd Register</Link></li>
                    </ol>
                </nav>
            </div>
            <div className="container">
                <div className="card shadow mb-4">

                    <div className="card-body p-4">

                        <div className="row">

                            <form id="receiverForm p-8"

                                onSubmit={handleSubmit(opdSubmit)}>
                                <h2 className='text-center p-2'>OPD Register</h2>



                                <div className="row ms-5 me-5">
                                    <label className="form-group col-md-6" >Blood Pressure</label>
                                    <input type="text"
                                        className=" form-group col-md-4 form-control" placeholder="BP"
                                        value={formData.BloodPressure}
                                        onChange={(event) =>
                                            setFormData({ ...formData, BloodPressure: event.target.value })
                                        } />

                                </div>
                                <div className="row ms-5 me-5">
                                    <label className="form-group col-md-6" >Pulse Rate</label>
                                    <input asp-for="PatientName" type="text"
                                        className="form-control form-group col-md-4" placeholder="Pulse"
                                        value={formData.PulseRate}
                                        onChange={(event) =>
                                            setFormData({ ...formData, PulseRate: event.target.value })
                                        } />
                                    <span className="text-danger field-validation-valid" asp-validation-for="PatientName" data-valmsg-replace="true" />
                                </div>
                                <div className="row ms-5 me-5">
                                    <label className="form-group col-md-6" >SPO 2 </label>
                                    <input type="text"
                                        className="form-control form-group col-md-4" placeholder="SPO 2 "
                                        value={formData.Spo}
                                        onChange={(event) =>
                                            setFormData({ ...formData, Spo: event.target.value })
                                        } />
                                </div>

                                <div >
                                    <div className="row ms-5 me-5">
                                        <label className="form-group col-md-6" >BMI</label>
                                        <input asp-for="Age" type="text"
                                            className="form-control form-group col-md-4" placeholder="BMI"
                                            value={formData.Bmi}
                                            onChange={(event) =>
                                                setFormData({ ...formData, Bmi: event.target.value })
                                            } />
                                    </div>
                                    <div className="row ms-5 me-5">
                                        <label className="form-group col-md-6" >Pain</label>
                                        <input type="text" className="form-control form-group col-md-4"
                                            placeholder="Pain"
                                            value={formData.Pain}
                                            onChange={(event) =>
                                                setFormData({ ...formData, Pain: event.target.value })
                                            } />
                                    </div>
                                    <div className="row ms-5 me-5" >
                                        <label className="form-group col-md-6" >In Time  </label>
                                        <input type="text" className="form-control form-group col-md-4"
                                            placeholder="In Time  "
                                            value={formData.InTime}
                                            onChange={(event) =>
                                                setFormData({ ...formData, InTime: event.target.value })
                                            } />
                                    </div>
                                </div>


                                <div className="row ms-5 me-5" >
                                    <label className="form-group col-md-6" >Assessment Time  </label>
                                    <input asp-for="Age" type="text"
                                        className="form-control form-group col-md-4" placeholder="Assessment Time"
                                        value={formData.AssessmentTime}
                                        onChange={(event) =>
                                            setFormData({ ...formData, AssessmentTime: event.target.value })
                                        } />
                                </div>
                                <div className="row ms-5 me-5">
                                    <label className='form-group col-md-6'>Out Time</label>
                                    <input type="text" className="form-control form-group col-md-4"
                                        placeholder="Out Time "
                                        value={formData.OutTime}
                                        onChange={(event) =>
                                            setFormData({ ...formData, OutTime: event.target.value })
                                        } />
                                </div>
                                <div className="row ms-5 me-5">
                                    <label className='form-group col-md-6'>Diagnose/Investigation Details</label>
                                    <input type="text" className="form-control form-group col-md-4"
                                        placeholder="details "
                                        value={formData.DiagnosInvestigationDetail}
                                        onChange={(event) =>
                                            setFormData({ ...formData, DiagnosInvestigationDetail: event.target.value })
                                        } />
                                </div>
                                <div className="row ms-5 me-5">
                                    <label className='form-group col-md-6'>Time Of Investigation Of Billing</label>
                                    <input type="text" className="form-control form-group col-md-4"
                                        placeholder="Type "
                                        value={formData.TimeOfBilling}
                                        onChange={(event) =>
                                            setFormData({ ...formData, TimeOfBilling: event.target.value })
                                        } />
                                </div>

                                <div className="row ms-5 me-5">
                                    <label className="form-group col-md-6 " >IPD</label>{formData.Ipdcheck}
                                    <input type="checkbox" className='largerCheckbox ' onChange={createIpdId} />
                                </div>
                                <div className='row ms-5 me-5' style={{ display: formData.Ipdcheck ? "" : "none" }}>

                                    <label className="form-group col-md-6" >Ipd Id</label>
                                    <input type="text" className="form-control form-group col-md-6"
                                        value={formData.IpdId}
                                        onChange={(event) => { }

                                        } />

                                </div>
                                <div className="row ms-5 me-5">
                                    <label className='form-group col-md-6'>STAFF SIGNATURE  WITH ID</label>
                                    <input type="file" className="form-control form-group col-md-4"
                                        placeholder="Type "
                                        // value={formData.StaffSignature}
                                        // onChange={(event) =>
                                        //     setFormData({ ...formData, StaffSignature: event.target.value })
                                        // } 
                                        onChange={(e) => {
                                            if (e.target.files.length > 0) {
                                                setFormData({ ...formData, StaffSignature: e.target.files[0] });
                                            } else {
                                                setFormData({ ...formData, StaffSignature: "" });
                                            }
                                        }} />
                                </div>





                                <div className="d-flex justify-content-center">
                                    <button type="submit" id="saveReceiver" className="btn btn-primary">Save</button>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OpdRegister
