import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { customFormDataPost } from '../../Helper/api-helper';
import { customFetch } from '../../Helper/api-helper';
import swal from 'sweetalert';
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import { TimePicker } from 'react-ios-time-picker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { apiBaseUrl } from '../../../global/credentials'

function BulkUploadOPD() {

    const { register, handleSubmit } = useForm();
    useEffect(() => {

        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);


    const [file, setFile] = useState(null);



    const formSubmit = async () => {
        if (file) {
            var data = new FormData();
            data.append('file', file);


            var res = await customFormDataPost(`${apiBaseUrl}opd/bulkupload-patient`, data);
            if (res.status) {
                swal("Saved!", "Saved!", "success");
                setFile(null);


            }
            else {
                alert(res.messsage)
            }
        }


    }
    return (
        <div><div className="container-fluid">
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/opd/">Home</Link></li>
                    <li className="breadcrumb-item active" ><Link to="/opd/">OPD form</Link></li>
                </ol>
            </nav>
        </div>
            <div className="container">
                <div className="card shadow mb-4">

                    <div className="card-body p-4">

                        <div className="row">

                            <form id="receiverForm p-8"

                                onSubmit={handleSubmit(formSubmit)}>
                                <h2 className='text-center'>Bulk File upload csv</h2>


                                <div className="row ms-5 me-5">
                                    <label className="form-group col-md-6" >Upload File</label>
                                    <input type="file"
                                        className="form-control form-group col-md-6" placeholder="Patient name"

                                        onChange={(event) =>
                                            setFile(event.target.files[0])
                                        } />
                                </div>





                                <div className="d-flex justify-content-center">
                                    <button type="submit" id="saveReceiver" className="btn btn-primary">Save</button>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BulkUploadOPD
