import React from 'react'

function Footer() {
  return (
    <div>
        <footer className="sticky-footer bg-white">
        <div className="container my-auto">
          <div className="copyright text-center my-auto">
            <span>Copyright © Your Website 2021</span>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Footer
