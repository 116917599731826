import React, { useState, useEffect } from 'react';
import { customFetch } from '../../Helper/api-helper';
import { Link } from "react-router-dom";
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery';
import { apiBaseUrl, global } from '../../../global/credentials';


function Opdregister() {
    const [detail, setDetail] = useState([]);
    useEffect(() => {
        handle()
    }, []);
    const handle = async () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        var res = await customFetch(`${apiBaseUrl}opd/patients/completed`);
        setDetail(res);
        setTimeout(function () {
            $('#donertable').DataTable(
            );
        }, 100);
    }
    return (
        <div>
            <div className="container-fluid">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/doctor/">Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page"><Link to="/doctor/opdregistered">Opd Data</Link></li>
                    </ol>
                </nav>
            </div>
            <div className="container-fluid">
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 style={{ float: "left" }} className="m-0 font-weight-bold text-primary">OPD Table</h6>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-bordered" id="donertable" width="100%" cellSpacing={0}>
                                <thead>
                                    <tr>
                                        <td>S. No</td>
                                        <td>Date And Timing</td>
                                        <td>Patient Name</td>
                                        <td>Age</td>
                                        <td>Gender</td>
                                        <td>Invoice No</td>
                                        <td>Consultant Name</td>
                                        <td>Action</td>

                                    </tr>
                                </thead>
                                <tbody>
                                    {detail.map((opd, index) => (
                                        <tr key={opd.id}>
                                            <td>{opd.sNo}</td>
                                            <td>{opd.dateAndTimeOfBilling}</td>
                                            <td>{opd.patientName}</td>
                                            <td>{opd.age}</td>
                                            <td>{opd.gender}</td>
                                            <td>{opd.uhidInvoiceNo}</td>
                                            <td>{opd.consultantName}</td>

                                            <td>
                                                <Link className="btn btn-primary btn-sm mx-1" to={"/doctor/opddetails/" + opd.id} >Detail</Link>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Opdregister
