import React from 'react';
import Navbar from './Surveypages/Navbar';
import Sidepanel from './Surveypages/Sidepanel';
import {Route,Routes} from "react-router-dom";
import Surveyform from './Surveypages/Surveyform';
import Footer from './Surveypages/Footer';

function Survey() {
  return (
  <div id="wrapper"><Sidepanel/>
 <div id="content-wrapper" className="d-flex flex-column">
 <div id="content">
  <Navbar/>
  
  <Routes>
  <Route path="/"  element={<Surveyform/>} /> 
  
  </Routes>
  <Footer/>
 </div>
 </div>
 </div>

  
  )
}

export default Survey
