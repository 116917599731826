//import logo from './logo.svg';
import './App.css';
import React from 'react';
import { Route, Routes } from "react-router-dom";
import Login from './Componenets/Login/Login';
import Bloodbank from './Componenets/Bloodbank/Bloodbank';
import Admin from './Componenets/Admin/Admin';
import Survey from './Componenets/Survey/Survey';
import SuperAmin from './Componenets/Superadmin/SuperAdmin';
import Opd from './Componenets/Opd/Opd';
import Ipd from './Componenets/Ipd/Ipd';
import Doctor from './Componenets/Doctors/Doctor';





function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/blood-bank/*" element={<Bloodbank />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/survey/*" element={<Survey />} />
        <Route path='/director/*'element={<SuperAmin/>}/>
        <Route path='/opd/*' element={<Opd />} />
        <Route path='/ipd/*' element={<Ipd />} />
        <Route path='/doctor/*' element={<Doctor/>} />
      

      </Routes>
      {/* <div id="wrapper"><Sidepanel/>
 <div id="content-wrapper" className="d-flex flex-column">
 <div id="content">
  <Navbar/>
  <Routes>
  <Route path="/"  element={<Panel/>} />
  <Route exact path="/donerlist"  element={<DonerList/>} />
  <Route exact path="/donerform"  element={<Adddonerform/>} />
  <Route exact path="/receiver"  element={<Receiver/>} />
  <Route exact path="/receiverform"  element={<Receiverform/>} />
  <Route exact path="/stockrecord"  element={<Stockrecord/>} />
  <Route exact path="/frontpage"  element={<Frontpage/>} />
  
  </Routes>
  <Footer/>
 </div>
 </div>
 </div> */}


    </>
  );
}

export default App;
